<div class="container">
    <div class="row justify-content-center">
        <div class="col-12 title">
            <h4>Categorías</h4>
        </div>
        <div class="col-12">
            <hr>
        </div>
        <mat-dialog-content>
            <div class="row">
                <div class="col-7">
                    <div class="row justify-content-center">
                        <div class="col-11 cat1 mt-1" *ngFor="let cat of categories">
                            {{cat.name_category}}
                            <span (click)="deleteCat(cat.id_category)"><i class="fa-regular fa-circle-xmark"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-5">
                    <h6>Agregar Categoría</h6>
                    <form [formGroup]="catForm" class="mt-3" (ngSubmit)="addCat()">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Español</mat-label>
                            <input matInput autocomplete="off" formControlName="name">
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Inglés</mat-label>
                            <input matInput autocomplete="off" formControlName="namen">
                        </mat-form-field>
                        <button mat-raised-button color="primary" type="submit" class="w-100"><i class="fa-solid fa-plus"></i></button>
                    </form>
                </div>
            </div>
        </mat-dialog-content>
        <div class="col-12">
            <hr>
        </div>
        <div class="col-12">
            <button mat-flat-button color="warn" (click)="close()">Salir</button>
        </div>
    </div>
</div>
