import { Component, OnInit } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-safe',
  templateUrl: './safe.component.html',
  styleUrls: ['./safe.component.css']
})
export class SafeComponent implements OnInit {

  public safes : any[] = [];
  public page = 0;
  public pages = 0;
  public pages2 = 1;
  public errorMessage = '';
  public lang = 'es';

  constructor( private apiSer : ApiService,
               private authSer : AuthService ) {
    apiSer.changeLang.subscribe(data => {
      this.lang = data.data
    })
  }

  ngOnInit(): void {
    this.readSafes();

    if(localStorage.getItem('lang')){
      if(localStorage.getItem('lang') == 'es'){
        this.lang = 'es';
      }else{
        this.lang = 'en';
      }
    }

    this.authSer.pagesSafe.subscribe( data => {
      this.pages = data.data
    })
  }

  readSafes(){
    this.apiSer.readAll('safes').subscribe(resp => {
      if( resp.results != 'Not Found' ){
        this.safes = resp.results;
        resp.results.forEach(resp2 => {
          let image = JSON.parse(resp2.image_safe);
          resp2.image2_safe = image.url
          resp2.links2_safe = JSON.parse(resp2.links_safe);
        })
      }
    })
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  nextPage() {
    this.page += 5
    this.pages2 += 1;
    this.scrollToTop();
  }

  previousPage() {
    if (this.page > 0) {
      this.page -= 5;
      this.pages2 -= 1;
      this.scrollToTop();
    }
  }

  btnPaginate() {
    let pag1 = this.safes.length / 5;
    this.pages = Math.ceil(pag1);
  }

}
