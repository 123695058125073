<div class="container-fluid property">
  <div class="row justify-content-center property2" *ngIf="property">
    <div class="separator pdestacadas"></div>
    <div class="col-11 col-lg-5 me-lg-5 mt-3 mt-lg-5 image align-self-start">
      <img src="{{ property.image2_property }}" class="w-100" alt="" />
    </div>
    <div class="col-11 col-lg-3 ms-lg-5 mt-5 align-self-center">
      <div class="row justify-content-center">
        <div class="col-12">
          <h6
            *ngIf="
              lang == 'es' ||
              lang == '' ||
              property.description_en_property == ''
            "
          >
            {{ property.description_property }}
          </h6>
          <h6 *ngIf="lang == 'en' && property.description_en_property != ' '">
            {{ property.description_en_property }}
          </h6>
          <p class="mt-4">
            {{ "UBI" | translate }}: {{ property.sector_property | titlecase }}
          </p>
          <p>{{ "HAB" | translate }}: {{ property.bedrooms_property }}</p>
          <p>{{ "BANO" | translate }}: {{ property.bathing_property }}</p>
          <p *ngIf="meas == 1">
            {{ "SUPUTIL" | translate }}: {{ property.built_property }}
            {{ "MT2" | translate }}
          </p>
          <p *ngIf="meas == 2">
            {{ "SUPUTIL" | translate }}: {{ property.built2_property }}
            {{ "FEET" | translate }}
          </p>
          <p *ngIf="meas == 1">
            {{ "SUPTOTAL" | translate }}: {{ property.ground_property }}
            {{ "MT2" | translate }}
          </p>
          <p *ngIf="meas == 2">
            {{ "SUPTOTAL" | translate }}: {{ property.ground2_property }}
            {{ "FEET" | translate }}
          </p>
          <p *ngIf="property.value3_property != 'CLP '">
            {{ "DSDE" | translate }}:
            {{ property.value2_property | currency : property.value3_property }}
          </p>
          <p *ngIf="property.value3_property == 'CLP '">
            {{ "DSDE" | translate }}:
            {{
              property.value2_property
                | currency : property.value3_property : "code" : "4.0"
            }}
          </p>
        </div>
        <div class="col-12">
          <p
            class="feet cpointer"
            *ngIf="meas == 1"
            (click)="changeMeas(2); $event.stopPropagation()"
          >
            <span>{{ "FEET1" | translate }}</span>
          </p>
          <p
            class="feet cpointer"
            *ngIf="meas == 2"
            (click)="changeMeas(1); $event.stopPropagation()"
          >
            <span>{{ "FEET2" | translate }}</span>
          </p>
        </div>
        <button
          class="col-12 btn33 mt-4"
          (click)="interested(property.id_property)"
        >
          {{ "VISITA1" | translate }}
        </button>
        <a
          class="p-0"
          target="_blank"
          href="https://wa.me/{{ whatsapp }}?text={{ 'WTS1' | translate }}%20{{
            property.description_property
          }}."
        >
          <button class="col-12 btn3 mt-2">
            {{ "WHATSAPP1" | translate }}
          </button>
        </a>
        <!--
                <div class="col-12 bloce mt-4" [innerHTML]="property.description_short_property" *ngIf="lang == 'es' || lang == '' || property.description_en_property == ''"></div>
                <div class="col-12 bloce mt-4" [innerHTML]="property.description_en_short_property" *ngIf="lang == 'en' && property.description_en_property != ''"></div>
                -->
      </div>
    </div>
    <div
      class="col-11 col-lg-9 bloce mt-4"
      [innerHTML]="property.description_short_property"
      *ngIf="
        lang == 'es' || lang == '' || property.description_en_property == ''
      "
    ></div>
    <div
      class="col-11 col-lg-9 bloce mt-4"
      [innerHTML]="property.description_en_short_property"
      *ngIf="lang == 'en' && property.description_en_property != ''"
    ></div>
  </div>

  <div class="row justify-content-center ameni">
    <div class="col-12 text-center">
      <h3>{{ "AMNN" | translate }}:</h3>
    </div>
    <div class="col-12 col-lg-6 text-center ico-ameni mt-4">
      <div class="row justify-content-center mt-3">
        <div class="cajaX text-center" *ngFor="let ico of amenities">
          <i class="{{ ico.ico }} fa-2x"></i>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center slider">
    <div class="col-11 col-lg-9 mt-2 p-0">
      <div
        id="carouselExampleControls"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div
            class="carousel-item"
            [ngClass]="{ active: i == 0 }"
            *ngFor="let image of images; let i = index"
          >
            <img src="{{ image.url }}" class="d-block w-100" alt="..." />
          </div>
          <!--
                    <div class="carousel-item">
                        <img src="../../assets/img/property-slider.jpg" class="d-block w-100" alt="...">
                    </div>
                        
                    <div class="carousel-item">
                        <img src="../../assets/img/property-slider.jpg" class="d-block w-100" alt="...">
                    </div>
                    -->
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls,#carouselExampleControls2"
          aria-controls="#carouselExampleControls,#carouselExampleControls2"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    <!--
        <div class="col-12 col-lg-9 mt-4 mb-4">
            <div class="row justify-content-center">
                <div class="col-4">
                    <img src="../../assets/img/property1.jpg" class="w-100" alt="">
                </div>
                <div class="col-4">
                    <img src="../../assets/img/property2.jpg" class="w-100" alt="">
                </div>
                <div class="col-4">
                    <img src="../../assets/img/property3.jpg" class="w-100" alt="">
                </div>
            </div>
        </div>
        -->
    <div class="col-11 col-lg-9 mt-3 p-0">
      <div class="row justify-content-between" *ngIf="property">
        <div
          class="col-12 textCompleto"
          [innerHTML]="property.description_prin_property"
          *ngIf="
            lang == 'es' || lang == '' || property.description_en_property == ''
          "
        ></div>
        <div
          class="col-12 textCompleto"
          [innerHTML]="property.description_en_prin_property"
          *ngIf="lang == 'en' && property.description_en_property != ''"
        ></div>
        <!--
                <div class="col-12 col-lg-6">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe perferendis, quidem mollitia minima dignissimos voluptatibus. Dolore dolorum voluptate aperiam consectetur rem repudiandae vero esse? Quam adipisci doloribus atque exercitationem consequatur.</p>
                </div>
                -->
        <div class="col-12 mt-3" *ngIf="mapp == 1">
          <!-- this creates a google map on the page with the given lat/lng from -->
          <!-- the component as the initial center of the map: -->
          <agm-map
            (mapClick)="addMarker($event)"
            [latitude]="lat"
            [longitude]="lng"
            [zoom]="13"
          >
            <agm-marker
              *ngFor="let marker of markers; let i = index"
              [latitude]="marker.lat"
              [longitude]="marker.lng"
            >
              <agm-info-window>
                <strong>{{ marker.title }}</strong>
                <p>{{ marker.desc }}</p>
              </agm-info-window>
            </agm-marker>
          </agm-map>
        </div>

        <button routerLink="/home" class="col-12 col-lg-4 btn33 mt-5">
          {{ "PROP6" | translate }}
        </button>
        <div class="col-12 col-lg-4 text-end mt-5 rrss">
          <span
            ><a target="_blank" href="{{ facebook }}"
              ><i class="fa-brands fa-facebook-f me-4"></i></a
          ></span>
          <span
            ><a target="_blank" href="{{ instagram }}"
              ><i class="fa-brands fa-instagram"></i></a
          ></span>
        </div>
      </div>
    </div>
  </div>

  <div class="row b-prop" *ngIf="property">
    <!--
        <div class="col-12 text-center tit-b">
            <h3>{{'PROP1'|translate}}</h3>
        </div>
        <div class="col-12 col-lg-9">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-4 crock">
                    <div>
                        <img src="../../assets/img/property4.jpg" class="w-100" alt="">
                    </div> 
                </div>
                <div class="col-12 col-lg-4 crock">
                    <div class="b-prop-cas">
                        <p>{{'PROP2'|translate}}</p>
                    </div>
                </div>
                <div class="col-12 col-lg-4 crock">
                    <div>
                        <img src="../../assets/img/property5.jpg" class="w-100" alt="">
                    </div>
                </div>
                <div class="col-12 col-lg-4 crock">
                    <div class="b-prop-cas">
                        <p>{{'PROP3'|translate}}</p>
                    </div>
                </div>
                <div class="col-12 col-lg-4 crock">
                    <div>
                        <img src="../../assets/img/property6.jpg" class="w-100" alt="">
                    </div>
                </div>
                <div class="col-12 col-lg-4 crock">
                    <div class="b-prop-cas">
                        <p>{{'PROP4'|translate}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-9 mt-4 text-bt">
            <p>{{'PROP5'|translate}}</p>
        </div>
        <button class="col-11 col-lg-3 btn3 mt-5 me-lg-5">{{'PROP6'|translate}}</button>
        -->
  </div>
</div>
