<div class="container-fluid home">
  <div class="row">
    <div class="col-12 hero">
      <img src="../../assets/img/hero.png" class="w-100" alt="">
    </div>
    <div class="separator"></div>
  </div>

  <div class="row justify-content-center botonera1">
    <div class="col-11 col-lg-4 text-center btn1 cpointer" routerLink="/properties/cl">
      {{'PROPIEDADES-CL' | translate}}
    </div>
    <div class="col-11 col-lg-4 mt-4 mt-lg-0 ms-lg-5 text-center btn1 cpointer" routerLink="/properties/rd">
      {{'PROPIEDADES-PR' | translate}}
    </div>
  </div>

  <div class="row justify-content-center pdestacadas">
    <div class="col-9 text-center">
      <h3>{{'PDES' | translate}}</h3>
    </div>
    <div class="col-12 col-lg-4 pdes" *ngFor="let prope of properties | homeDest:divisa:meas">

      <div class="row">
        <a routerLink="/property/{{prope.url_property}}" (click)="verif(false)">
          <div class="col-12 image">
            <div class="image2">
              <img src="{{prope.image2_property}}" class="w-100" alt="">
            </div>
          </div>
          <div class="col-12 mt-3">
            <h6>{{prope.description_property}}</h6>
            <p>{{'UBI'|translate}}: {{prope.sector_property | titlecase}}</p>
            <p>{{'HAB'|translate}}: {{prope.bedrooms_property}}</p>
            <p>{{'BANO'|translate}}: {{prope.bathing_property}}</p>
            <p *ngIf="meas == 1">{{'SUPUTIL'|translate}}: {{prope.built_property}} {{'MT2'|translate}}</p>
            <p *ngIf="meas == 2">{{'SUPUTIL'|translate}}: {{prope.built2_property}} {{'FEET'|translate}}</p>
            <p *ngIf="meas == 1">{{'SUPTOTAL'|translate}}: {{prope.ground_property}} {{'MT2'|translate}}</p>
            <p *ngIf="meas == 2">{{'SUPTOTAL'|translate}}: {{prope.ground2_property}} {{'FEET'|translate}}</p>
            <p *ngIf="prope.value3_property != 'CLP '">{{'DSDE'|translate}}:
              {{prope.value2_property | currency:prope.value3_property}}</p>
            <p *ngIf="prope.value3_property == 'CLP '">{{'DSDE'|translate}}:
              {{prope.value2_property | currency:prope.value3_property:'code':'4.0'}}</p>
          </div>
        </a>
        <div class="col-12">
          <p class="feet" *ngIf="meas == 1" (click)="changeMeas(2);$event.stopPropagation()">
            <span>{{'FEET1'|translate}}</span> </p>
          <p class="feet" *ngIf="meas == 2" (click)="changeMeas(1);$event.stopPropagation()">
            <span>{{'FEET2'|translate}}</span> </p>
        </div>
        <div class="col-12 ico-des mt-5">
          <span *ngFor="let ico of prope.amenities2_property"><i class="{{ico.ico}}"></i></span>
        </div>
      </div>

    </div>
    <div class="col-12 col-lg-10 no-existe mt-5 mb-5" *ngIf="properties.length == 0">
      <p>{{'ERROR5'|translate}}</p>
    </div>
  </div>

  <div class="row justify-content-center unete">
    <div class="col-12 col-lg-10 text-center mt-5 mt-lg-0">
      <h3>{{'UNETEQUIPO'|translate}}</h3>
      <!--<h4>{{'UNETEQUIPO2'|translate}}</h4>-->
    </div>
    <div class="col-12 secc-ventas text-center">
      <img src="../../assets/img/team.png" alt="">
    </div>
  </div>

  <form [formGroup]="aseForm" (ngSubmit)="sendAsesora()">
    <!--
    <div class="row justify-content-center forma" id="join">
        -->
    <div class="row justify-content-center forma">
      <div class="col-12 col-lg-10">
        <h3>{{'QUIEROASESORA'|translate}}</h3>
        <!--<p>{{'QUIEROASESORA2'|translate}}</p>-->
      </div>
      <div class="col-12 col-lg-10 mt-4">
        <label for="name">{{'NOMBRECOMPLETO'|translate}}</label>
        <span *ngIf="namePattern"> No escriba caracteres especiales.</span>
        <span *ngIf="nameRequired"> Campo requerido.</span>
        <input type="text" class="w-100" formControlName="name" [class.form-inv]="namePattern ||nameRequired">
      </div>
      <div class="col-12 col-lg-10 mt-4">
        <label for="info">{{'MGRECIBIRINFO'|translate}}</label>
        <span *ngIf="infoPattern"> No escriba caracteres especiales.</span>
        <span *ngIf="infoRequired"> Campo requerido.</span>
        <textarea name="textarea" formControlName="info" [class.form-inv]="infoPattern ||infoRequired"></textarea>
      </div>
      <div class="col-12 col-lg-5 mt-4">
        <label for="email">E-MAIL</label>
        <span *ngIf="emailPattern"> Escriba un email correcto.</span>
        <span *ngIf="emailRequired"> Campo requerido.</span>
        <input type="text" class="w-100" formControlName="email" [class.form-inv]="emailPattern || emailRequired">
      </div>
      <div class="col-12 col-lg-5 mt-4">
        <label for="phone">{{'CCONTACTO'|translate}}</label>
        <span *ngIf="phonePattern"> Solo números y signos (+).</span>
        <span *ngIf="phoneRequired"> Campo requerido.</span>
        <input type="text" class="w-100" formControlName="phone" [class.form-inv]="phonePattern || phoneRequired">
      </div>
      <div class="col-12 col-lg-5 mt-4 iconos align-selft-center order-2 order-lg-1">
        <a target="_blank" href="{{facebook}}"><i class="fa-brands fa-facebook-f me-4"></i></a>
        <a target="_blank" href="{{instagram}}"><i class="fa-brands fa-instagram"></i></a>


      </div>
      <div class="col-12 col-lg-5 text-end mt-4 order-1 order-lg-2">
        <button type="submit">{{'ENVIARBTN'|translate}}</button>
      </div>
    </div>
  </form>
</div>
