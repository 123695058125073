export class PostModel {

    id_post?: number;
    title_post: string;
    titlen_post: string;
    content_post: string;
    contenten_post: string;
    id_category_post: number;
    id_user_post: number;
    url_post: string;
    image_post: string;
    status_post: number;
    date_created_post: string;


    constructor(){
        this.title_post = '',
        this.titlen_post = '',
        this.content_post = '',
        this.contenten_post = '',
        this.id_category_post = 0,
        this.id_user_post = 0,
        this.url_post = '',
        this.image_post = '',
        this.status_post = 0,
        this.date_created_post = ''
    }
}