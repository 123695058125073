import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserModel } from '../shared/models/user';
import { AuthService } from '../shared/services/auth.service';
import Swal from 'sweetalert2';
import { ApiService } from '../shared/services/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm!: FormGroup;
  user: UserModel = new UserModel();
  public errorMessage = null;
  public token: any;
  public logean: boolean = false;
  public data: any[] = [];
  public identity = '';
  public patt = false;
  public requi = false;
  public pattPass = false;
  public requiPass = false;

  constructor( private authService: AuthService,
               private apiService: ApiService, 
               private fb: FormBuilder,
               public router: Router ) { }

  ngOnInit(): void {

    this.crearFormulario();

  }

  get emailNoValido(){
    return this.loginForm.controls.email.invalid && this.loginForm.controls.email.touched;
  }

  get emailPattern(){
    if(this.loginForm.controls.email.errors && this.loginForm.controls.email.touched){
      if(this.loginForm.controls.email.errors.pattern){
        this.patt = true;
      }else{
        this.patt = false;
      }
    }else{
      this.patt = false;
    }
    return (this.patt == false) ? false : true;
  }

  get emailRequired(){
    if(this.loginForm.controls.email.errors && this.loginForm.controls.email.touched){
      if(this.loginForm.controls.email.errors.required){
        this.requi = true;
      }else{
        this.requi = false;
      }
    }else{
      this.requi = false;
    }
    return (this.requi === false) ? false : true;
  }

  get passwordPattern(){
    if(this.loginForm.controls.password.errors && this.loginForm.controls.password.touched){
      if(this.loginForm.controls.password.errors.pattern){
        this.pattPass = true;
      }else{
        this.pattPass = false;
      }
    }else{
      this.pattPass = false;
    }
    return (this.pattPass === false) ? false : true;
  }

  get passwordRequired(){
    if(this.loginForm.controls.password.errors && this.loginForm.controls.password.touched){
      if(this.loginForm.controls.password.errors.required){
        this.requiPass = true;
      }else{
        this.requiPass = false;
      }
    }else{
      this.requiPass = false;
    }
    return (this.requiPass === false) ? false : true;
  }

  crearFormulario(){
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      password: ['',[Validators.required, Validators.pattern(/^[a-zA-Z0-9_@.!]*$/)]]
    })
  }

  loginUser(){
    
    if( this.loginForm.invalid ){
      return Object.values( this.loginForm.controls ).forEach( control => {
        control.markAsTouched();
      })
    }

    this.user.email_user = this.loginForm.controls['email'].value;
    this.user.password_user = this.loginForm.controls['password'].value;

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Ingresando',
    });
    Swal.showLoading();

    this.authService.loginUser( this.user )
      .subscribe( resp => {
      
        this.token = resp.results[0].token_user;

        this.data = Object.values(this.authService.getDecodedAccessToken(this.token));
        
        this.authService.saveToken(this.token);

        this.apiService.readUser(this.data[2].id).subscribe(resp2 => {

          this.authService.userLogin.emit({
            data: resp2.results[0]
          })
          Swal.close();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Bienvenido '+ resp2.results[0].name_user.toUpperCase(),
            showConfirmButton: false,
            timer:1500
          })
  
          this.logean = true;
  
          this.router.navigate(['admin/home']);
        })
      },
      error => {
        this.errorMessage = error;

        /*
        let err = '';
        switch (error.error.results) {
          case 'Wrong password':
            err = 'Contraseña incorrecta';
            break;
          case 'Wrong email':
            err = 'Email no existe';
            break;
          
        }
        */

        if( this.errorMessage != null ){
          Swal.fire({
            title: 'No fue posible ingresar!',
            icon: 'error',
            text: 'Correo o contraseña incorrecta'
          });
        }
      });
  }
}
