import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminServicesComponent } from './admin-services.component';
import { AddServiceComponent } from './add-service/add-service.component';
import { ListServicesComponent } from './list-services/list-services.component';
import { FilterServicesPipe } from './shared/filters/filter-services.pipe';
import { MaterialModule } from '../material.module';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [AdminServicesComponent, AddServiceComponent, ListServicesComponent, FilterServicesPipe],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule
  ],
  exports: [
    AdminServicesComponent, AddServiceComponent, ListServicesComponent, FilterServicesPipe
  ]
})
export class AdminServicesModule { }
