import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { UserModel } from '../shared/models/user';
import { ApiService } from '../shared/services/api.service';
import { AuthService } from '../shared/services/auth.service';
import { EditUserComponent } from './edit-user/edit-user.component';
import { InviteUserComponent } from './invite-user/invite-user.component';
import { ProfileUserComponent } from './profile-user/profile-user.component';
import { UsersService } from './shared/services/users.service';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css'],
})
export class AdminUsersComponent implements OnInit {
  public users: any[] = [];
  public errorMessage = '';
  public searchForm!: FormGroup;
  public search = '';
  public role = 0;
  public sadmin: boolean = false;
  public admin: boolean = false;
  public agente: boolean = false;
  public vendedor: boolean = false;
  public usuario: boolean = false;

  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private userService: UsersService,
    private dialog: MatDialog,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.readUsers();
    this.createForm();
    this.listen();
  }

  createForm(){
    this.searchForm = this.fb.group({
      search: ['']
    })
  }

  listen(){
    this.searchForm.controls.search.valueChanges.subscribe(resp => {
      this.search = resp;
    })
  }

  roleSearch(role: string){
    switch (role) {
      case '1':
        this.sadmin = true;
        this.admin = false;
        this.agente = false;
        this.vendedor = false;
        this.usuario = false;
        this.role = 1;
        break;

      case '2':
        this.sadmin = false;
        this.admin = true;
        this.agente = false;
        this.vendedor = false;
        this.usuario = false;
        this.role = 2;
        break;
      
      case '3':
        this.sadmin = false;
        this.admin = false;
        this.agente = true;
        this.vendedor = false;
        this.usuario = false;
        this.role = 3;
        break;
      
      case '4':
        this.sadmin = false;
        this.admin = false;
        this.agente = false;
        this.vendedor = true;
        this.usuario = false;
        this.role = 4;
        break;

      case '5':
        this.sadmin = false;
        this.admin = false;
        this.agente = false;
        this.vendedor = false;
        this.usuario = true;
        this.role = 5;
        break;
    
      default:
        this.sadmin = false;
        this.admin = false;
        this.agente = false;
        this.vendedor = false;
        this.usuario = false;
        this.role = 0;
        break;
    }
  }

  readUsers() {
    this.apiService.readUsersRelations().subscribe((resp) => {
      resp.results.forEach((resp2) => {
        if (resp2.image_user == '') {
          resp2.image_user = 'https://api.dosiscl.com/uploads/no-image.png';
        }
      });
      this.users = resp.results;
    });
  }

  invite() {
    const dialogRef = this.dialog.open(InviteUserComponent, {
      width: '400px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.readUsers();
    });
  }

  profileUser(user: any) {
    const dialogRef = this.dialog.open(ProfileUserComponent, {
      width: '800px',
      data: {
        user,
      },
    });
  }

  delUser(id: any) {
    Swal.fire({
      title: 'Estas seguro que deseas eliminar?',
      text: 'Si eliminas no recuperarás este usuario!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          allowOutsideClick: false,
          icon: 'info',
          text: 'Eliminando',
        });
        Swal.showLoading();

        this.userService.deleteUser(id).subscribe((resp) => {
          
          Swal.close();

          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'El propietario ha sido eliminado',
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.readUsers();
          });
        },
        (error) => {
          this.errorMessage = error;

          if (this.errorMessage != null) {
            Swal.fire({
              title: 'error',
              icon: 'error',
              text: error.error.message,
            });
          }
        });
      }
    });
  }

  editUser(user: UserModel) {
    const dialogRef = this.dialog.open(EditUserComponent, {
      width: '800px',
      data: {
        id: user.id_user,
        name: user.name_user,
        lastname: user.lastname_user,
        email: user.email_user,
        dni: user.dni_user,
        role: user.id_role_user,
        position: user.position_user,
        phone: user.phone_user,
        commune: user.commune_user,
        country: user.id_country_user,
        brokerCode: user.brokerCode_user,
        userCode: user.userCode_user,
        image: user.image_user,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.readUsers();
    });
  }
}
