<div class="container-fluid safe">
    <div class="row justify-content-center cab separator">
        <div class="col-12 col-lg-10 title">
            <h3>{{'SAFE1' | translate}} <br>{{'SAFE11' | translate}}</h3>
            <p class="mt-4">{{'SAFE2' | translate}}</p>
        </div>
    </div>
    <div class="row justify-content-center mt-5 cab1" *ngFor="let safe of safes | safef:page">
        <div class="col-12 col-lg-5">
            <img src="{{safe.image2_safe}}" class="w-100" alt="">
            <div class="col-12 text-center">
                <h3 class="mt-5 mb-5" *ngIf="lang == 'es' || lang == '' || safe.titlen_safe == ''">{{safe.title_safe}}</h3>
                <h3 class="mt-5 mb-5" *ngIf="lang == 'en' && safe.titlen_category != ''">{{safe.titlen_safe}}</h3>
            </div>
            <p [innerHTML]="safe.content_safe" *ngIf="lang == 'es' || lang == '' || safe.contenten_safe == ''"></p>
            <p [innerHTML]="safe.contenten_safe" *ngIf="lang == 'en' && safe.contenten_safe != ''"></p>
            <div class="link1">
                <p *ngFor="let link of safe.links2_safe"><a href="{{link.url}}" target="_blank">{{link.url}}</a></p>
            </div>
            <div class="col-12 mt-5">
                <hr>
            </div>
        </div>
    </div>
    <div class="row justify-content-center pagins">
        <div class="col-12 col-lg-6 text-center" *ngIf="safes.length != 0">
            <button class="nmberPag me-2" (click)="previousPage()" [disabled]="pages2 == 1"><i class="fa-solid fa-angle-left"></i></button>

            <span class="nmberPag2 me-2">Página {{pages2}} de {{pages}}</span>

            <button class="nmberPag" (click)="nextPage()" [disabled]="pages == pages2"><i class="fa-solid fa-angle-right"></i></button>
        </div>
    </div>
</div>