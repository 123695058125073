import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ApiService } from '../shared/services/api.service';
import { ViewMessageComponent } from './view-message/view-message.component';

@Component({
  selector: 'app-admin-messages',
  templateUrl: './admin-messages.component.html',
  styleUrls: ['./admin-messages.component.css'],
})
export class AdminMessagesComponent implements OnInit {
  public messages: any[] = [];
  public page = 0;
  public pages = 0;
  public pages2 = 1;
  public errorMessage = '';

  constructor(private apiService: ApiService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.readMessages();
  }

  readMessages() {
    this.apiService.readMessagesRelations().subscribe((resp) => {
      if(resp.results != 'Not Found'){
        this.messages = resp.results;
        this.btnPaginate();
      }
    });
  }

  nextPage() {
    this.page += 5;
    this.pages2 += 1;
  }

  previousPage() {
    if (this.page > 0) {
      this.page -= 5;
      this.pages2 -= 1;
    }
  }

  btnPaginate() {
    let pag1 = this.messages.length / 5;
    this.pages = Math.ceil(pag1);
  }

  viewMessage(id: number) {
    const dialogRef = this.dialog.open(ViewMessageComponent, {
      width: '700px',
      data: {
        id,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.readMessages();
      this.apiService.changeMessage2.emit({
        data: true,
      });
    });
  }

  delMessage(id: number) {
    Swal.fire({
      title: 'Estas seguro que deseas eliminar?',
      text: 'Si eliminas no recuperarás este mensaje!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.apiService.deleteReqMes('advisers','adviser',id).subscribe(
          (resp) => {
            Swal.close();

            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'El mensaje ha sido eliminado',
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              this.readMessages()
            });
          },
          (error) => {
            this.errorMessage = error;

            if (this.errorMessage != null) {
              Swal.fire({
                title: 'error',
                icon: 'error',
                text: error.error.message,
              });
            }
          }
        );
      }
    });
  }
}
