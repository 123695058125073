import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { AsesoraModel } from '../shared/models/asesora';
import { ApiService } from '../shared/services/api.service';
import { MailService } from '../shared/services/mail.service';

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.css']
})
export class JoinComponent implements OnInit {

  public aseForm!: FormGroup;
  public asesora: AsesoraModel = new AsesoraModel();
  public errorMessage = '';

  constructor( private apiService: ApiService,
               private mailService: MailService,
               private fb: FormBuilder ) { }

  ngOnInit(): void {
    this.createForm();
  }

  get namePattern() {
    if (
      this.aseForm.controls.name.errors &&
      this.aseForm.controls.name.touched
    ) {
      if (this.aseForm.controls.name.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get nameRequired() {
    if (
      this.aseForm.controls.name.errors &&
      this.aseForm.controls.name.touched
    ) {
      if (this.aseForm.controls.name.errors.required) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get infoPattern() {
    if (
      this.aseForm.controls.info.errors &&
      this.aseForm.controls.info.touched
    ) {
      if (this.aseForm.controls.info.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get infoRequired() {
    if (
      this.aseForm.controls.info.errors &&
      this.aseForm.controls.info.touched
    ) {
      if (this.aseForm.controls.info.errors.required) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get emailPattern() {
    if (
      this.aseForm.controls.email.errors &&
      this.aseForm.controls.email.touched
    ) {
      if (this.aseForm.controls.email.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get emailRequired() {
    if (
      this.aseForm.controls.email.errors &&
      this.aseForm.controls.email.touched
    ) {
      if (this.aseForm.controls.email.errors.required) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get phonePattern() {
    if (
      this.aseForm.controls.phone.errors &&
      this.aseForm.controls.phone.touched
    ) {
      if (this.aseForm.controls.phone.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get phoneRequired() {
    if (
      this.aseForm.controls.phone.errors &&
      this.aseForm.controls.phone.touched
    ) {
      if (this.aseForm.controls.phone.errors.required) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  createForm(){
    this.aseForm = this.fb.group({
      name: ['',[Validators.required,Validators.pattern(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/)]],
      info: ['',[Validators.required,Validators.pattern(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/)]],
      email: ['',[Validators.required,Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      phone: ['', [Validators.required,Validators.pattern(/^[0-9()+-]*$/)]]
    })
  }

  sendAsesora(){
    if (this.aseForm.invalid) {
      return Object.values(this.aseForm.controls).forEach((control) => {
        control.markAsTouched();
      });
    }

    const dat = moment();

    this.asesora.name_adviser = this.aseForm.controls.name.value;
    this.asesora.info_adviser = this.aseForm.controls.info.value;
    this.asesora.email_adviser = this.aseForm.controls.email.value;
    this.asesora.phone_adviser = this.aseForm.controls.phone.value;
    this.asesora.ip_adviser = '';
    this.asesora.type_adviser = 1;
    this.asesora.status_adviser = 1;
    this.asesora.id_user_adviser = 113;
    this.asesora.date_created_adviser = dat.format('YYYY-MM-DD');

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Enviando solicitud',
    });
    Swal.showLoading();

    this.apiService.sendAsesora(this.asesora).subscribe(resp => {

      let dataMail = [
        {
          email: this.aseForm.controls.email.value,
          name: this.aseForm.controls.name.value,
          code: 7
        }
      ]

      this.mailService.sendMail(dataMail).subscribe((resp2:any) => {
        Swal.close();
        this.aseForm.reset({
          name: [''],
          info: [''],
          email: [''],
          phone: ['']
        })
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: '¡Enviado!',
          text: 'Te responderemos a la brevedad posible',
          showConfirmButton: false,
          timer: 2000,
        })
      })
    },
    (error) => {
      this.errorMessage = error;

      if (this.errorMessage != null) {
        Swal.fire({
          title: 'error',
          icon: 'error',
          text: error.error.message,
        });
      }
    })
  }

}
