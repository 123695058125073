import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSliderModule } from '@angular/material/slider';
import { MatBadgeModule } from '@angular/material/badge';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
    imports: [MatCardModule, MatButtonModule, MatFormFieldModule, MatInputModule, MatDividerModule, MatStepperModule, MatSelectModule, MatSlideToggleModule, MatTooltipModule, MatSnackBarModule, MatDialogModule, MatMenuModule, MatIconModule, MatPaginatorModule, MatSliderModule, MatBadgeModule, MatExpansionModule],
    exports: [MatCardModule, MatButtonModule, MatFormFieldModule,MatInputModule, MatDividerModule, MatStepperModule, MatSelectModule, MatSlideToggleModule, MatTooltipModule, MatSnackBarModule, MatDialogModule, MatMenuModule, MatIconModule, MatPaginatorModule,MatSliderModule, MatBadgeModule, MatExpansionModule]
})
export class MaterialModule{}