<div class="container-fluid info">
    <div class="row justify-content-center">
        <div class="col-12 col-lg-10">
            <h1>Solicitudes</h1>
        </div>
        <div class="col-12 col-lg-10">
            <div class="row">
                <div class="col-12">
                    <p><span class="pending"></span> Solicitudes Pendientes <span class="ready"></span> Solicitudes Revisadas</p>
                </div>
            </div>
            <div class="row mt-2 mb-5">
                <div class="col-12 mb-2" *ngFor="let int of interested | requestHome:page">
                    <mat-card class="example-card" [ngClass]="{'pend': int.status_interest == 1}">
                        <div class="row">
                          <div class="col-1 align-self-center d-none d-lg-block order-1">
                            <img src="{{int.image2_property}}" class="w-100" alt="">
                          </div>
                          <div class="col-12 col-lg-4 align-self-center order-2 mt-3 mb-3 mt-lg-0 mb-lg-0">
                            <p><strong>{{int.name_interest | titlecase}} {{int.lastname_interest | titlecase}}</strong> ha solicitado información de: </p>
                            <p><strong>{{int.description_property | titlecase}}</strong></p>
                          </div>
                          <div class="col-4 col-lg-2 align-self-center order-4 order-lg-3">
                            <p>{{int.date_created_interest | date}}</p>
                          </div>
                          <div class="col-12 col-lg-3 text-start text-lg-center align-self-center p-0 order-3 order-lg-4">
                            <img src="{{int.image_user}}" class="circle-profile" alt="" *ngIf="int.status_interest != 1">
                            <span *ngIf="int.status_interest > 1"><small><strong>Leído: </strong>  {{int.name_user}} {{int.lastname_user}}</small></span>
                          </div>
                          <div class="col-4 col-lg-1 text-center align-self-center p-0 cpointer order-5" (click)="viewRequest(int.id_interest)">
                            <i class="fa-solid fa-eye"></i>
                          </div>
                          <div class="col-4 col-lg-1 text-center align-self-center p-0 cpointer order-5" (click)="delRequest(int.id_interest)">
                            <i class="fa-solid fa-trash"></i>
                          </div>
                        </div>
                    </mat-card>
                </div>
                <div class="col-12 mb-2 no-existe" *ngIf="interested.length == 0">
                  <p>No hay solicitudes.</p>
                </div>
            </div>
            <div class="row justify-content-center mb-5">
                <div class="col-12 col-lg-6 text-center" *ngIf="interested.length != 0">
                    <button class="nmberPag me-2" (click)="previousPage()" [disabled]="pages2 == 1"><i class="fa-solid fa-angle-left"></i></button>
        
                    <span class="nmberPag2 me-2">Página {{pages2}} de {{pages}}</span>
        
                    <button class="nmberPag" (click)="nextPage()" [disabled]="pages == pages2"><i class="fa-solid fa-angle-right"></i></button>
                </div>
            </div>
        </div>

    </div>
</div>