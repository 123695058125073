<div class="container-fluid info">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-8">
      <mat-card class="example-card">
        <mat-card-title><span *ngIf="!parametro">Agregar</span><span *ngIf="parametro">Editar</span> Invierte Seguro
        </mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
          <mat-horizontal-stepper linear #stepper>

            <mat-step [stepControl]="safesForm">
              <form [formGroup]="safesForm">
                <ng-template matStepLabel>Español</ng-template>
                <div class="row mt-4">
                  <div class="col-12">
                    <div class="row justify-content-center">
                      <div class="col-12">
                        <mat-form-field appearance="fill" class="w-100">
                          <mat-label>Titulo</mat-label>
                          <input matInput formControlName="title">
                        </mat-form-field>
                      </div>
                      <div class="col-12">
                        <ckeditor [editor]="Editor" data="" formControlName="content"></ckeditor>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-3">
                    <hr>
                  </div>
                  <div class="col-12 mt-2">
                    <button mat-raised-button color="primary" class="btnss" matStepperNext>Siguiente</button>
                  </div>
                </div>
              </form>
            </mat-step>
            <mat-step [stepControl]="safenForm">
              <form [formGroup]="safenForm">
                <ng-template matStepLabel>Inglés</ng-template>
                <div class="row mt-4">
                  <div class="col-12">
                    <div class="row justify-content-center">
                      <div class="col-12">
                        <mat-form-field appearance="fill" class="w-100">
                          <mat-label>Título</mat-label>
                          <input matInput formControlName="titlen">
                          <mat-hint align="start"></mat-hint>
                        </mat-form-field>
                      </div>
                      <div class="col-12">
                        <ckeditor [editor]="Editor" data="" formControlName="contenten"></ckeditor>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-3">
                    <hr>
                  </div>
                  <div class="col-12 mt-2">
                    <button mat-button matStepperPrevious mat-raised-button color="basic"
                      class="me-2 btnss">Anterior</button>
                    <button mat-button matStepperNext mat-raised-button color="primary" class="btnss">Siguiente</button>
                  </div>
                </div>
              </form>
            </mat-step>
            <mat-step [stepControl]="safemForm">
              <form [formGroup]="safemForm">
                <ng-template matStepLabel>Datos</ng-template>
                <div class="row mt-4 justify-content">
                  <div class="col-7">
                    <div class="row justify-content-center">
                      <div class="col-12">
                        
                      </div>
                      <div class="col-12" *ngIf="!parametro">
                        <mat-form-field appearance="fill" class="w-100">
                          <mat-label>Estado</mat-label>
                          <mat-select formControlName="status">
                            <mat-option value="0">En Borrador</mat-option>
                            <mat-option value="1">Publicar</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-12" *ngIf="parametro">
                        <mat-form-field appearance="fill" class="w-100">
                          <mat-label>Estado</mat-label>
                          <mat-select formControlName="status" [compareWith]="compareFunction2">
                            <mat-option value="0">En Borrador</mat-option>
                            <mat-option value="1">Publicar</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-12 mt-3">
                        <p *ngIf="links.length == 0">No hay enlaces</p>
                        <p *ngFor="let link of links; let i = index">{{link.url}} <span class="ms-3 cpointer" (click)="delLink(i)"><i class="fa-regular fa-circle-xmark"></i></span></p>
                      </div>
                      <div class="col-12 mt-3">
                        <div class="row" *ngIf="!maximus">
                            <div class="col-9">
                                <mat-form-field appearance="fill" class="w-100">
                                    <mat-label>Agregar Enlace</mat-label>
                                    <input matInput formControlName="links">
                                </mat-form-field>
                            </div>
                            <div class="col-3 align-self-center">
                                <button type="button" class="w-100" mat-raised-button color="primary" (click)="addLink()">Agregar</button>
                            </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                  <div class="col-5">

                    <div class="row justify-content-center">
                      <div class="col-7 mb-2">
                        <button type="button" mat-raised-button color="primary" (click)="fileInput.click()"
                          class="w-100">
                          <span><i class="fas fa-cloud-upload-alt mr-3"></i> Subir Imagen</span>
                          <input #fileInput multiple type="file" (change)="onSelectFile($event)" style="display:none;"
                            formControlName="image" />
                        </button>
                      </div>
                      <div class="col-7">
                        <img [src]="urlImage" alt="no_image" class="w-100">
                      </div>
                    </div>

                  </div>
                  <div class="col-12 mt-3">
                    <hr>
                  </div>
                  <div class="col-12 mt-2">
                    <button mat-button matStepperPrevious mat-raised-button color="basic"
                      class=" btnss">Anterior</button>
                    <button type="submit" mat-raised-button color="primary" class="ms-2 btnss" (click)="publish(1)"
                      *ngIf="!parametro">Publicar</button>
                    <button type="submit" mat-raised-button color="primary" class="ms-2 btnss" (click)="publish(2)"
                      *ngIf="parametro">Editar</button>
                    <button type="button" mat-raised-button color="warn" class="ms-2 btnss" *ngIf="parametro"
                      routerLink="../../../safe">Cancelar</button>
                  </div>
                </div>
              </form>
            </mat-step>
          </mat-horizontal-stepper>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
