<form [formGroup]="dataMarker" (ngSubmit)="saveChanges()">
    <mat-form-field appearance="fill" class="w-100">
        <mat-label>Título</mat-label>
        <input matInput formControlName="title">
    </mat-form-field>
    <mat-form-field appearance="fill" class="w-100">
        <mat-label>Descripción</mat-label>
        <textarea matInput formControlName="desc"></textarea>
    </mat-form-field>
    <button type="submit" mat-raised-button color="primary" class="mt-2">Guardar</button>
    <button type="button" mat-raised-button color="warn" class="ms-2 mt-2" (click)="onNoClick()">Cancelar</button>
</form>
