import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminBlogComponent } from './admin-blog.component';
import { AddPostComponent } from './add-post/add-post.component';
import { CategoriesComponent } from './categories/categories.component';
import { MaterialModule } from '../material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DayPipe } from './shared/filters/day.pipe';
import { PostPipe } from './shared/filters/post.pipe';


@NgModule({
  declarations: [AdminBlogComponent, AddPostComponent, CategoriesComponent, DayPipe, PostPipe],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    AppRoutingModule,
    CKEditorModule
  ],
  exports: [AdminBlogComponent, AddPostComponent, CategoriesComponent]
})
export class AdminBlogModule { }
