<div class="container-fluid">
  <div class="row justify-content-center addUser">
    <div class="col-6">
      <mat-card class="example-card">
        <mat-card-header>
          <mat-card-title>Agregar Servicio</mat-card-title>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          <mat-horizontal-stepper #stepper>
            <mat-step [stepControl]="contentForm">
              <form [formGroup]="contentForm">
                <ng-template matStepLabel>Contenido</ng-template>
                <div class="row justify-content-center mt-3">
                  <div class="col-12">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Título</mat-label>
                      <input matInput
                             formControlName="title">
                    </mat-form-field>
                  </div>
                  <div class="col-12">
                    <small><p><mat-icon class="iconLink">share</mat-icon> https://www.batista-properties.com/services/{{link}} <span class="ms-4 modUrl" (click)="modUrl()"> Modificar URL </span></p></small> 
                    
                  </div>
                  <div class="col-12" *ngIf="modLink">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>
                        <mat-icon>share</mat-icon> <b> https://www.batista-properties.com/services/</b>
                      </mat-label>
                      <input matInput
                             formControlName="link">
                    </mat-form-field>
                  </div>
                  <div class="col-12">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Contenido</mat-label>
                      <textarea matInput
                                formControlName="content"></textarea>
                    </mat-form-field>
                  </div>
                  <div>
                    <button type="button" mat-raised-button matStepperNext>Siguiente</button>
                  </div>
                </div>
              </form>
            </mat-step>
            <mat-step [stepControl]="imageForm">
              <form [formGroup]="imageForm">
                <ng-template matStepLabel>Imagen</ng-template>
                
                <div class="row justify-content-center mt-3">
                  <div class="col-4">
                    <img src="../../../../assets/img/no-image.webp" class="w-100" alt="" *ngIf="urlImage == undefined">
                    <img [src]="urlImage" class="w-100" *ngIf="urlImage">
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-4">
                    <button type="button" mat-raised-button color="primary" (click)="fileInput.click()" class="w-100">
                      <span><i class="fas fa-cloud-upload-alt mr-3"></i> Subir Imagen</span>
                      <input #fileInput multiple type="file" (change)="onSelectFile($event)" style="display:none;"
                        formControlName="image" />
                    </button>
                  </div>
                </div>
                
                <div>
                  <button type="button" mat-raised-button matStepperPrevious>Atras</button>
                  <button mat-raised-button class="ms-2" color="primary" (click)="addService()">Enviar</button>
                </div>
              </form>
            </mat-step>
          </mat-horizontal-stepper>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
