import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { UserModel } from 'src/app/shared/models/user';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MailService } from 'src/app/shared/services/mail.service';
import { ValidatorsService } from 'src/app/shared/services/validators.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-pass-user',
  templateUrl: './reset-pass-user.component.html',
  styleUrls: ['./reset-pass-user.component.css']
})
export class ResetPassUserComponent implements OnInit {

  public sendCode! : FormGroup;
  public sendReset! : FormGroup;
  public infoReset! : FormGroup;
  public userModel: UserModel = new UserModel();
  public user: any;
  public reset: boolean = false;
  public parameter1 = '';
  public parameter2 = '';
  public code = '';
  public password1 = '';
  public password2 = '';
  public errorMessage = '';

  constructor(
    private apiService: ApiService,
    private mailService: MailService,
    private authService: AuthService,
    private fb: FormBuilder,
    private _activatedRoute: ActivatedRoute,
    private router : Router,
    private validService: ValidatorsService
  ) { }

  ngOnInit(): void {
    this.viewParameter();
    this.createForm();
    
    if(this.parameter2){
      this.resetSend(this.parameter2);
    }
  }

  get passwordPattern(){
    if( this.infoReset.controls.pass1.errors && this.infoReset.controls.pass1.touched ){
      if( this.infoReset.controls.pass1.errors.pattern ){
        return true;
      }else{
        return false;
      }
    }
    return false;
  }

  get password2Pattern(){
    if( this.infoReset.controls.pass2.errors && this.infoReset.controls.pass2.touched ){
      if( this.infoReset.controls.pass2.errors.pattern ){
        return true;
      }else{
        return false;
      }
    }
    return false;
  }

  get password2NoValido(){
    if( this.infoReset.controls.pass2.touched ){
      this.password1 = this.infoReset.get('pass1')?.value;
      this.password2 = this.infoReset.get('pass2')?.value;
    }
    return this.password1 === this.password2 ? false : true;
  }

  get codePattern(){
    if( this.sendReset.controls.code.errors && this.sendReset.controls.code.touched ){
      if( this.sendReset.controls.code.errors.pattern ){
        return true;
      }else{
        return false;
      }
    }
    return false;
  }

  get emailPattern(){
    if( this.sendCode.controls.email.errors && this.sendCode.controls.email.touched ){
      if( this.sendCode.controls.email.errors.pattern ){
        return true;
      }else{
        return false;
      }
    }
    return false;
  }


  viewParameter(){
    this._activatedRoute.params.subscribe((params: Params) => {
      this.parameter1 = params['email'];
      this.parameter2 = params['code'];
    })
  }

  createForm() {
    this.sendCode = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]]
    });

    this.sendReset = this.fb.group({
      code: ['', [Validators.required,Validators.pattern(/^[a-zA-Z0-9]*$/)]]
    })

    this.infoReset = this.fb.group({
      pass1: ['',[Validators.required, Validators.pattern(/^[a-zA-Z0-9_@.!#%]*$/)]],
      pass2: ['', Validators.required]
    },
    {
      validators: this.validService.idem('pass1', 'pass2')
    })
  }

  codeSend(){
    if ( this.sendCode.invalid ) {
      return Object.values( this.sendCode.controls ).forEach((control) => { control.markAllAsTouched(); });
    }

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor',
    });
    Swal.showLoading();

    let data = {
      code: this.mailService.makeCode(5),
      action: '2',
      exp: moment().add(1, 'h').format('x')
    }

    this.apiService.readUserEmail( this.sendCode.controls.email.value ).subscribe((resp:any) => {

      if(resp.results != 'Not Found'){

        this.authService.resetPass1(data, resp.results[0].id_user).subscribe((resp1:any) => {
          
          let dataMail = [
            {
              name: resp.results[0].name_user,
              email: resp.results[0].email_user,
              code_api: data.code,
              code: 3
            }
          ];

          this.mailService.sendMail(dataMail).subscribe((resp2: any) => {
            
          });
          Swal.close();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Código Enviado',
            text: 'Revise su correo electrónico',
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.router.navigate(['user/reset/'+ resp.results[0].email_user])
          });

        })

      }else{

        Swal.close();
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Verifique su email',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    })

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor',
    });
    Swal.showLoading();

  }

  resetSend(code?:string){
    
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor',
    });
    Swal.showLoading();

    this.apiService.readUserEmail(this.parameter1).subscribe((resp) => {

      if(resp.results != 'Not Found'){
        this.user = resp.results[0];
      }
      
      if(this.user == undefined){
        Swal.close();
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Verifique su email',
          showConfirmButton: false,
          timer: 1500,
        });
      }else{
        let robb;
        if(code){
          robb = code;
        }else{
          robb = this.sendReset.controls.code.value
        }
        
        if( robb != this.user['code_user'] ){
          Swal.close();
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'El código no es correcto',
            showConfirmButton: false,
            timer: 1500,
          });
        }else{
          Swal.close();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Código correcto',
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.reset = true;
          });
        }

      }
    })

    

  }

  resetInfo(){
    if ( this.infoReset.invalid ) {
      return Object.values( this.infoReset.controls ).forEach((control) => { control.markAllAsTouched(); });
    }

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor',
    });
    Swal.showLoading();

    this.authService.newPass(this.infoReset.controls.pass1.value).subscribe((resp) => {

      let data = {
          password : resp.results,
          code: '',
          action: 0,
          exp: ''
      }

      this.authService.editPass( data, this.user['id_user'] ).subscribe((resp2) => {
        Swal.close();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Nueva contraseña creada con éxito.',
          showConfirmButton: false,
          timer: 1500,
          }).then(() => {
            this.router.navigate(['login']);
          });
      },
      (error) => {
        this.errorMessage = error;

        if (this.errorMessage != null) {
          Swal.fire({
            title: 'error',
            icon: 'error',
            text: error.error.message,
          });
        }
      });
      
    },
    (error) => {
      this.errorMessage = error;

      if (this.errorMessage != null) {
        Swal.fire({
          title: 'error',
          icon: 'error',
          text: error.error.message,
        });
      }
    });
  }

}
