import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { CategoryModel } from 'src/app/shared/models/categories';
import { ApiService } from 'src/app/shared/services/api.service';
import Swal from 'sweetalert2';
import { BlogService } from '../shared/services/blog.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  public categories : any[] = [];
  public catForm!: FormGroup;
  public cat: CategoryModel = new CategoryModel();
  public errorMessage = '';

  constructor( private apiSer: ApiService,
               private blogSer: BlogService,
               private fb : FormBuilder,
               public dialogRef: MatDialogRef<CategoriesComponent> ) { }

  ngOnInit(): void {
    this.createForm();
    this.readCat();
  }

  createForm(){
    this.catForm = this.fb.group({
      name: ['', Validators.required],
      namen: ['', Validators.required]
    })
  }

  readCat(){
    this.apiSer.readAll('categories').subscribe(resp => {
      if(resp.results != 'Not Found'){
        this.categories = resp.results;
      }
    })
  }

  close(){
    this.dialogRef.close();
  }

  deleteCat(id:number){
    Swal.fire({
      title: 'Estas seguro que deseas eliminar?',
      text: 'Si eliminas no recuperarás esta Categoría!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.blogSer.delete(id,'categories','category').subscribe(
          (resp) => {
            Swal.close();

            if(resp.results[0] != "00000"){
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'La categoría ha sido eliminada',
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.readCat();
              });
            }else{
              Swal.fire({
                title: 'error',
                icon: 'error',
                text: 'Existen post con esta categoría. Primero cambie antes de eliminar',
              });
            }
            
          },
          (error) => {
            this.errorMessage = error;

            if (this.errorMessage != null) {
              Swal.fire({
                title: 'error',
                icon: 'error',
                text: error.error.message,
              });
            }
          }
        );
      }
    });
  }

  addCat(){
    if(this.catForm.invalid){
      return Object.values(this.catForm.controls).forEach((control) => {
        control.markAllAsTouched();
      });
    }

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Agregando categoría. Espere por favor',
    });
    Swal.showLoading();
    
    const dat = moment();

    this.cat.name_category = this.catForm.controls.name.value;
    this.cat.namen_category = this.catForm.controls.namen.value;
    this.cat.date_created_category = dat.format('YYYY-MM-DD');

    this.blogSer.addCat(this.cat).subscribe(resp => {
      Swal.close();

      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Categoría creada con éxito',
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        this.readCat();
      });
    })
  }

}
