import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminSafeComponent } from './admin-safe.component';
import { MaterialModule } from '../material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SafesComponent } from './safes/safes.component';
import { SafePipe } from './shared/filters/safe.pipe';
import { DaysafePipe } from './shared/filters/daysafe.pipe';



@NgModule({
  declarations: [AdminSafeComponent, SafesComponent, SafePipe, DaysafePipe],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    AppRoutingModule,
    CKEditorModule
  ],
  exports: [AdminSafeComponent]
})
export class AdminSafeModule { }
