import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import Swal from 'sweetalert2';
import { UserModel } from 'src/app/shared/models/user';
import * as moment from 'moment';
import { ValidatorsService } from 'src/app/shared/services/validators.service';
import { Router } from '@angular/router';
import { MailService } from 'src/app/shared/services/mail.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { ImageService } from 'src/app/shared/services/image.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css'],
})
export class AddUserComponent implements OnInit {
  public userForm!: FormGroup;
  public user: UserModel = new UserModel();
  public token = '';
  public errorMessage = '';
  public urlImage: any;
  //public file!: File;
  public archives: any = [];
  public passQuest = false;
  public pattEmail = false;
  public password1 = '';
  public password2 = '';
  public countries: any[] = [];
  public roles: any[] = [];

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private imgService: ImageService,
    private apiService: ApiService,
    private validService: ValidatorsService,
    private mailService: MailService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.readCountries();
    this.readRoles();
    this.crearFormulario();
    this.changePassQuest();
  }

  get existingEmail() {
    if (
      this.userForm.controls.email.errors &&
      this.userForm.controls.email.touched
    ) {
      if (this.userForm.controls.email.errors.existEmail) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get emailPattern() {
    if (
      this.userForm.controls.email.errors &&
      this.userForm.controls.email.touched
    ) {
      if (this.userForm.controls.email.errors.pattern) {
        this.pattEmail = true;
      } else {
        this.pattEmail = false;
      }
    } else {
      this.pattEmail = false;
    }
    return this.pattEmail === false ? false : true;
  }

  get emailRequired() {
    if (
      this.userForm.controls.email.errors &&
      this.userForm.controls.email.touched
    ) {
      if (this.userForm.controls.email.errors.required) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get passwordPattern() {
    if (
      this.userForm.controls.password.errors &&
      this.userForm.controls.password.touched
    ) {
      if (this.userForm.controls.password.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get password2Pattern() {
    if (
      this.userForm.controls.password2.errors &&
      this.userForm.controls.password2.touched
    ) {
      if (this.userForm.controls.password2.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get password2NoValido() {
    if (this.userForm.controls.password2.touched) {
      this.password1 = this.userForm.get('password')?.value;
      this.password2 = this.userForm.get('password2')?.value;
    }
    return this.password1 === this.password2 ? false : true;
  }

  get namePattern() {
    if (
      this.userForm.controls.name.errors &&
      this.userForm.controls.name.touched
    ) {
      if (this.userForm.controls.name.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get nameRequired() {
    if (
      this.userForm.controls.name.errors &&
      this.userForm.controls.name.touched
    ) {
      if (this.userForm.controls.name.errors.required) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get lastnamePattern() {
    if (
      this.userForm.controls.lastname.errors &&
      this.userForm.controls.lastname.touched
    ) {
      if (this.userForm.controls.lastname.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get lastnameRequired() {
    if (
      this.userForm.controls.lastname.errors &&
      this.userForm.controls.lastname.touched
    ) {
      if (this.userForm.controls.lastname.errors.required) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get dniPattern() {
    if (
      this.userForm.controls.dni.errors &&
      this.userForm.controls.dni.touched
    ) {
      if (this.userForm.controls.dni.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get positionPattern() {
    if (
      this.userForm.controls.position.errors &&
      this.userForm.controls.position.touched
    ) {
      if (this.userForm.controls.position.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get phonePattern() {
    if (
      this.userForm.controls.phone.errors &&
      this.userForm.controls.phone.touched
    ) {
      if (this.userForm.controls.phone.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get communePattern() {
    if (
      this.userForm.controls.commune.errors &&
      this.userForm.controls.commune.touched
    ) {
      if (this.userForm.controls.commune.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  readCountries(){
    this.apiService.readAll('countries').subscribe(resp => {
      this.countries = resp.results;
    })
  }

  readRoles(){
    this.apiService.readAll('roles').subscribe(resp => {
      this.roles = resp.results;
    })
  }

  crearFormulario() {
    this.userForm = this.fb.group(
      {
        email: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ),
          ],
          (control) => this.validService.existEmail(control),
        ],
        passQuest: false,
        password: ['', Validators.pattern(/^[a-zA-Z0-9_@.!#%]*$/)],
        password2: ['', Validators.pattern(/^[a-zA-Z0-9_@.!#%]*$/)],
        name: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/
            ),
          ],
        ],
        lastname: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/
            ),
          ],
        ],
        dni: ['', Validators.pattern(/^(\d{1,3}(?:\.\d{1,3}){2}-[\dkK])$/)],
        role: [0, Validators.pattern(/^[0-9]*$/)],
        position: [
          '',
          Validators.pattern(
            /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/
          ),
        ],
        phone: ['', Validators.pattern(/^[0-9()+-]*$/)],
        commune: [
          '',
          Validators.pattern(
            /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/
          ),
        ],
        country: [
          0,
          Validators.pattern(/^[0-9]*$/),
        ],
        brokerCode: [0, Validators.pattern(/^[0-9]*$/)],
        image: [''],
      },
      {
        validators: this.validService.idem('password', 'password2'),
      }
    );
  }

  changePassQuest() {
    this.userForm.controls.passQuest.valueChanges.subscribe((valor) => {
      this.passQuest = valor;
    });
  }

  onSelectFile(event): any {
    this.archives = event.target.files;
    const reader = new FileReader();

    reader.readAsDataURL(this.archives[0]);
    reader.onloadend = () => {
      this.urlImage = reader.result;
    };
  }

  addUser() {
    if (this.userForm.invalid) {
      return Object.values(this.userForm.controls).forEach((control) => {
        control.markAsTouched();
      });
    }

    const dat = moment();

    if (this.userForm.controls.password.value === '') {
      this.user.password_user = '@Ingr3z!oO33kiLl3r';
    } else {
      this.user.password_user = this.userForm.controls.password.value;
    }

    this.user.email_user = this.userForm.controls.email.value;
    this.user.name_user = this.userForm.controls.name.value;
    this.user.lastname_user = this.userForm.controls.lastname.value;
    this.user.dni_user = this.userForm.controls.dni.value;
    this.user.id_role_user = this.userForm.controls.role.value;
    this.user.position_user = this.userForm.controls.position.value;
    this.user.phone_user = this.userForm.controls.phone.value;
    this.user.commune_user = this.userForm.controls.commune.value;
    this.user.id_country_user = this.userForm.controls.country.value;
    this.user.brokerCode_user = this.userForm.controls.brokerCode.value;
    this.user.code_user = this.mailService.makeCode(5);
    this.user.code_action_user = 2;
    this.user.code_exp_user = moment().add(1,'h').format('x');
    this.user.date_created_user = dat.format('YYYY-MM-DD');
  

    let nameR = this.user.name_user.substr(0, 3);
    let lastN = this.user.lastname_user.substr(0, 2);
    let random = Math.floor(Math.random() * 999) + 1;
    const nameImage = nameR + lastN + random;

    this.user.id_internal_user = nameImage.toLowerCase();

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor',
    });
    Swal.showLoading();

    this.apiService.addUser(this.user).subscribe(
      (resp) => {
        let dataMail = [{
          name: this.user.name_user,
          last_name: this.user.lastname_user,
          email: this.user.email_user,
          code_api: this.user.code_user,
          code: 1
        }]
        this.mailService.sendMail(dataMail).subscribe((resp: any) => {
        })

        let data = resp.results.lastId.toString() + '_' + Date.now();

        if (this.urlImage) {
          this.imgService.uploadImage(data, this.urlImage, 'users').then((urlImage) => {
            this.apiService
              .editUser('image_user', urlImage, resp.results.lastId, null)
              .subscribe((resp3: any) => {
                Swal.close();

                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'El usuario ha sido registrado con exito',
                  showConfirmButton: false,
                  timer: 1500,
                }).then(() => {
                  this.router.navigate(['admin/users']);
                });
              });
          });
        } else {
          const url2 = 'https://api.dosiscl.com/uploads/no-image.png';
          this.apiService
            .editUser('image_user', url2, resp.results.lastId, null)
            .subscribe((resp3: any) => {
              Swal.close();

                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'El usuario ha sido registrado con exito',
                  showConfirmButton: false,
                  timer: 1500,
                }).then(() => {
                  this.router.navigate(['admin/users']);
                });
            });
        }

        

        
      },
      (error) => {
        this.errorMessage = error;

        if (this.errorMessage != null) {
          Swal.fire({
            title: 'error',
            icon: 'error',
            text: error.error.message,
          });
        }
      }
    );
  }
}
