<form [formGroup]="userForm" (ngSubmit)="editUser()">
  <mat-dialog-content>
    <div class="row">
      <div class="col-12 col-lg-6">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Correo</mat-label>
          <input matInput formControlName="email" [readonly]="true">
        </mat-form-field>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Nombre</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Apellidos</mat-label>
          <input matInput formControlName="lastname">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>RUT</mat-label>
          <input matInput formControlName="dni">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Privilegio</mat-label>
          <mat-select formControlName="role">
            <mat-option value="{{role.id_role}}" *ngFor="let role of roles">
              {{role.name_role | titlecase}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Cargo</mat-label>
          <input matInput formControlName="position">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Teléfono</mat-label>
          <input matInput formControlName="phone">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>País</mat-label>
          <mat-select formControlName="country">
            <mat-option value="{{country.id_country}}" *ngFor="let country of countries">
              {{country.namees_country | titlecase}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Comuna</mat-label>
          <input matInput formControlName="commune">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Código Corredora</mat-label>
          <input matInput formControlName="brokerCode">
        </mat-form-field>
      </div>

    </div>
    <div class="row mt-3">
      <div class="col-12 col-lg-4">
        <button type="submit" mat-raised-button color="primary" class="w-100">Editar</button>
      </div>
      <div class="col-12 col-lg-4">
        <button type="button" mat-raised-button color="warn" class="w-100 mt-3 mt-lg-0" (click)="onNoClick()">Cancelar</button>
      </div>
    </div>
  </mat-dialog-content>
</form>
