import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { OwnerModel } from 'src/app/shared/models/owner';
import { ApiService } from 'src/app/shared/services/api.service';
import Swal from 'sweetalert2';
import { OwnersService } from '../shared/services/owners.service';

@Component({
  selector: 'app-add-owner',
  templateUrl: './add-owner.component.html',
  styleUrls: ['./add-owner.component.css']
})
export class AddOwnerComponent implements OnInit {

  public ownerForm!: FormGroup;
  public owner: OwnerModel = new OwnerModel();
  public countries: any[] = [];

  constructor( public dialogRef: MatDialogRef<AddOwnerComponent>,
               private fb: FormBuilder,
               private ownerService: OwnersService,
               private apiService: ApiService ) { }

  ngOnInit(): void {
    this.readCountries();
    this.createForm();
  }

  readCountries(){
    this.apiService.readAll('countries').subscribe(resp => {
      this.countries = resp.results;
    })
  }

  createForm(){
    this.ownerForm = this.fb.group({
      name: [''],
      lastname: [''],
      dni: [''],
      email: [''],
      country: [''],
      phone: [''],
      address: [''],
      obs: ['']
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addOwner(){
    if (this.ownerForm.invalid) {
      return Object.values(this.ownerForm.controls).forEach((control) => {
        control.markAllAsTouched();
      });
    }

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor',
    });
    Swal.showLoading();

    const dat = moment();

    this.owner.name_owner = this.ownerForm.controls.name.value;
    this.owner.lastname_owner = this.ownerForm.controls.lastname.value;
    this.owner.dni_owner = this.ownerForm.controls.dni.value;
    this.owner.email_owner = this.ownerForm.controls.email.value;
    this.owner.phone_owner = this.ownerForm.controls.phone.value;
    this.owner.address_owner = this.ownerForm.controls.address.value;
    this.owner.obs_owner = this.ownerForm.controls.obs.value;
    this.owner.id_country_owner = this.ownerForm.controls.country.value;
    this.owner.date_created_owner = dat.format('YYYY-MM-DD');

    this.ownerService.addOwner(this.owner).subscribe(resp => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'El propietario ha sido creado',
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        this.dialogRef.close();
      });
    })
  }

}
