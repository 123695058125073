import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/shared/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-request',
  templateUrl: './view-request.component.html',
  styleUrls: ['./view-request.component.css']
})
export class ViewRequestComponent implements OnInit {

  public errorMessage = '';
  public interest: any;
  public property: any;

  constructor( private apiService: ApiService,
               public dialogRef: MatDialogRef<ViewRequestComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any ) { }

  ngOnInit(): void {
    this.readInterest();
  }

  readInterest(){
    this.apiService.read('interested', 'id_interest', this.data.id).subscribe(resp => {
      this.interest = resp.results[0];
      
      this.apiService.read('properties2','id_property',this.interest.id_property_interest).subscribe(resp2 => {
        this.property = resp2.results[0];
      
        let img = JSON.parse(this.property.image_property);
        this.property['image2_property'] = img[0].url;
      })
    })
  }

  markedMessage(){
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor',
    });
    Swal.showLoading();

    let token = this.apiService.readTokenDecoded();
    
    let data = {
      status : '2',
      read: token[2].id
    }

    this.apiService.editMsg('interested','interest',data,this.data.id).subscribe(resp => {
      this.apiService.changeRequest.emit({
        data: true
      })

      Swal.close();
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Leído',
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        this.dialogRef.close();
      });
    })
  }

}
