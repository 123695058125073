<div class="container-fluid info">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-8">
      <mat-card class="example-card">
        <mat-card-title>Agregar Propiedad</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
          <form [formGroup]="propertyForm" (ngSubmit)="addProperty(1)">
            <mat-horizontal-stepper #stepper>
              <mat-step>
                <ng-template matStepLabel>Información Básica</ng-template>
                <div class="row mt-3">
                  <div class="col-12 col-lg-6">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Selecciona la operación</mat-label>
                      <mat-select formControlName="operation">
                        <mat-option value="{{operation.id_operation}}" *ngFor="let operation of operations">{{operation.namees_operation}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-6">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Selecciona el tipo de propiedad</mat-label>
                      <mat-select formControlName="estate">
                        <mat-option value="{{estate.id_estate}}" *ngFor="let estate of estates">{{estate.namees_estate}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-8">
                    <span *ngIf="descPattern" class="text-danger"> Evite escribir caracteres especiales.</span>
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Título de propiedad</mat-label>
                      <input matInput 
                      formControlName="description" 
                      [class.form-inv]="descPattern">
                      <mat-hint align="start">(máximo 60 caracteres) - <b>URL: https://www.batista-properties.com/property/{{link}}</b></mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-4 mt-4 mt-lg-0">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Rol</mat-label>
                      <input matInput formControlName="rol">
                    </mat-form-field>
                  </div>
                  <div class="col-12" *ngIf="existingUrl">
                    <div class="alert alert-warning mb-0 mt-2" role="alert">
                        <b>Existe una propiedad con este título. Puede que provoque conflictos en las url. Se recomienda cambiar.</b>
                    </div>
                  </div>
                  <div class="col-12 col-lg-3 mt-lg-3">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Valor</mat-label>
                      <input matInput type="number" 
                                      formControlName="value">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-3 mt-lg-3">
                    <mat-form-field appearance="fill" class="w-100" *ngIf="!errorValores">
                      <mat-label>Tipo de moneda</mat-label>
                      <mat-select formControlName="payment">
                        <mat-option value="{{payment.id_payment}}" *ngFor="let payment of payments">{{payment.namees_payment}}</mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="w-100" *ngIf="errorValores">
                      <mat-label>Tipo de moneda</mat-label>
                      <mat-select formControlName="payment">
                        <mat-option value="1">pesos</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-2 mt-lg-3">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Destacar</mat-label>
                      <mat-select formControlName="highlight">
                        <mat-option value="0">No</mat-option>
                        <mat-option value="1">Si</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-2 mt-lg-3">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Subsidio</mat-label>
                      <mat-select formControlName="subsidy">
                        <mat-option value="0">No</mat-option>
                        <mat-option value="1">Si</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-2 mt-lg-3">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Canje</mat-label>
                      <mat-select formControlName="exchange">
                        <mat-option value="0">No</mat-option>
                        <mat-option value="1">Si</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-3 valuer me-lg-2 mt-2" *ngIf="dolar">
                    <p>{{rDolar | currency:'USD ':'code'}}</p>
                  </div>
                  <div class="col-12 col-lg-3 valuer me-lg-2 mt-2" *ngIf="pesos">
                    <p>{{rPesos | currency:'CLP ':'code':'4.0'}}</p>
                  </div>
                  <div class="col-12 col-lg-3 valuer me-lg-2 mt-2" *ngIf="uf">
                    <p>{{rUf | currency:'UF ':'code'}}</p>
                  </div>
                  <div class="col-12" *ngIf="errorValores">
                    <div class="alert alert-warning mb-0 mt-2" role="alert">
                      <b>No se puede conectar con valores economicos. <strong>Agregar valores manualmente</strong></b>
                      <div class="row mt-3">
                        <div class="col-3">
                          <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Dolar</mat-label>
                            <input matInput type="number" 
                                              formControlName="dolarX">
                          </mat-form-field>
                        </div>
                        <div class="col-3 ms-2">
                          <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Uf</mat-label>
                            <input matInput type="number" 
                                              formControlName="ufX">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <button type="button" mat-raised-button matStepperNext>Siguiente</button>
                </div>
              </mat-step>
              <mat-step>
                <ng-template matStepLabel>Descripción</ng-template>
                <div class="row mt-3">
                  <div class="col-12">
                    <label for="">Descripción corta</label>
                    <ckeditor [editor]="Editor" data="" formControlName="desc_short"></ckeditor>
                  </div>
                  <div class="col-12">
                    <label for="">Descripción Principal</label>
                    <ckeditor [editor]="Editor" data="" formControlName="desc_prin"></ckeditor>
                  </div>
                </div>
                <div class="mt-3">
                  <button type="button" mat-raised-button matStepperNext>Siguiente</button>
                </div>
              </mat-step>
              <mat-step>
                <ng-template matStepLabel>Ubicación</ng-template>
                <div class="row mt-3">
                  <div class="col-12 col-lg-4">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>País</mat-label>
                      <mat-select formControlName="country">
                        <mat-option value="{{country.id_country}}" *ngFor="let country of countries">{{country.namees_country}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-4">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Región</mat-label>
                      <mat-select formControlName="region">
                        <mat-option value="{{region.id_region}}" *ngFor="let region of regions">{{region.name_region}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-4">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Comuna</mat-label>
                      <mat-select formControlName="commune">
                        <mat-option value="{{commune.id_commune}}" *ngFor="let commune of communes">{{commune.name_commune}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-8 mt-lg-3">
                    <span *ngIf="sectorPattern" class="text-danger"> Evite escribir caracteres especiales.</span>
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Sector</mat-label>
                      <input matInput 
                             formControlName="sector"
                             [class.form-inv]="sectorPattern" >
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-2 mt-lg-3">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Geografía</mat-label>
                      <mat-select formControlName="geography">
                        <mat-option value="{{geography.id_geography}}" *ngFor="let geography of geographies">{{geography.namees_geography}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-2 mt-lg-3">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Mostar Mapa</mat-label>
                      <mat-select formControlName="map">
                        <mat-option value="0">No</mat-option>
                        <mat-option value="1">Si</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-12 mt-3" *ngIf="map">
                    <!-- this creates a google map on the page with the given lat/lng from -->
                    <!-- the component as the initial center of the map: -->
                    
                    <agm-map (mapClick)="addMarker( $event )" [latitude]="lat" [longitude]="lng" [zoom]="13">
                      <agm-marker *ngFor="let marker of markers; let i = index" [latitude]="marker.lat" [longitude]="marker.lng">
                        <agm-info-window>
                          <strong>{{ marker.title }}</strong>
                          <p>{{ marker.desc }}</p>

                          <div>
                            <button mat-raised-button color="primary" (click)="editMarker( marker )">Editar</button>
                            <button mat-raised-button color="warn" class="ms-2" (click)="delMarker(i)">Borrar</button>
                          </div>
                        </agm-info-window>
                      </agm-marker>
                    </agm-map>
                    
                  </div>
                </div>
                <div class="mt-3">
                  <button type="button" mat-raised-button matStepperPrevious>Anterior</button>
                  <button type="button" mat-raised-button matStepperNext class="ms-2">Siguiente</button>
                </div>
              </mat-step>
              <mat-step>
                <ng-template matStepLabel>Programa</ng-template>
                <div class="row mt-3">
                  <div class="col-12 col-lg-4">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Terreno</mat-label>
                      <input matInput type="number" formControlName="ground">
                      <mat-hint align="start">mt2</mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-2">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Construido</mat-label>
                      <input matInput type="number" formControlName="built">
                      <mat-hint align="start">mt2</mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-2">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Habitaciones</mat-label>
                      <input matInput type="number" formControlName="bedrooms">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-2">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Baños</mat-label>
                      <input matInput type="number" formControlName="bathing">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-2">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Estacionamientos</mat-label>
                      <input matInput type="number" formControlName="parking">
                    </mat-form-field>
                  </div>
                  <!--
                  <div class="col-12 mt-3">
                    <span *ngIf="desc2Pattern" class="text-danger"> Evite escribir caracteres especiales.</span>
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Descripción complementaria de la propiedad</mat-label>
                      <textarea matInput 
                                formControlName="description2" 
                                [class.form-inv]="desc2Pattern"></textarea>
                    </mat-form-field>
                  </div>
                  -->
                  <div class="col-12 col-lg-4 mt-lg-3">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Propietario</mat-label>
                        <mat-select formControlName="owner">
                          <mat-option value="{{owner.id_owner}}" *ngFor="let owner of owners">{{owner.name_owner}} {{owner.lastname_owner}}</mat-option>
                        </mat-select>
                        <mat-hint align="end" (click)="addOwner()" class="cpointer great">+ Agregar Propietario</mat-hint>
                      </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-4 mt-lg-3  align-self-center">
                    <div class="row">
                      <div class="col-12">
                        <button type="button" mat-raised-button color="primary" class="w-100" (click)="chooseAmenities()">Amenidades</button>
                      </div>
                      <div class="col-12 mt-2">
                        <small class="m-0">{{qAmenity}}</small> 
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 mt-lg-3 align-self-center">
                    <mat-slide-toggle formControlName="noval"> Agregar mobiliario y gestión hotelera.</mat-slide-toggle>
                  </div>
                </div>
                <div class="mt-3">
                  <button type="button" mat-raised-button matStepperPrevious>Anterior</button>
                  <button type="button" mat-raised-button matStepperNext class="ms-2">Siguiente</button>
                </div>
              </mat-step>
              <mat-step>
                <ng-template matStepLabel>Imagenes</ng-template>
                <div class="row mt-3">
                  <div class="col-12">
                    <p> Tamaño Recomendado 1000 x 560 px </p>
                  </div>
                  <div class="col-12 col-lg-6 mt-3">
                    <button type="button" mat-raised-button color="primary" (click)="fileInput.click()" class="w-100">
                      <span><i class="fas fa-cloud-upload-alt mr-3"></i> Subir Imagen</span>
                      <input #fileInput multiple type="file" (change)="onSelectFile($event)" style="display:none;"
                        formControlName="image" />
                    </button>
                  </div>
                </div>
                <div class="mt-3">
                  <button type="button" mat-raised-button matStepperPrevious>Anterior</button>
                  <button type="button" mat-raised-button color="warn" class="ms-2" (click)="addProperty(0)">En Borrador</button>
                  <button type="submit" mat-raised-button color="primary" class="ms-2">Publicar</button>
                </div>
              </mat-step>
            </mat-horizontal-stepper>
          </form>
          <!--
          <pre>
            Estado del Formulario: {{propertyForm.valid}}
            <br>
            status: {{ propertyForm.status }}
          </pre>
          <pre>
            {{ propertyForm.value | json }}
          </pre>
          -->
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-12 col-lg-3 mt-4 mb-4 mt-lg-0 mb-lg-0">
      <mat-card class="example-card">
        <mat-card-header>
          <mat-card-title>{{propertyTitle}}</mat-card-title>
          <mat-card-subtitle>{{commune}}</mat-card-subtitle>
        </mat-card-header>
        <img mat-card-image [src]="urlImage" alt="no_image">
        
        <mat-card-content>
          <p>
            {{propertyDesc}}
          </p>
        </mat-card-content>
        <mat-card-actions>
          
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
