import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminHomeComponent } from './admin-home.component';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../material.module';
import { InterestPipe } from './shared/filters/interest.pipe';
import { VisitsPipe } from './shared/filters/visits.pipe';
import { TypeMessagePipe } from './shared/filters/type-message.pipe';



@NgModule({
  declarations: [AdminHomeComponent, InterestPipe, VisitsPipe, TypeMessagePipe],
  imports: [
    CommonModule,
    BrowserModule,
    MaterialModule
  ],
  exports: [
    AdminHomeComponent
  ]
})
export class AdminHomeModule { }
