import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SafeComponent } from 'src/app/safe/safe.component';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public logean: boolean = false;
  public data: any[] = [];
  
  public instagram = '';
  public facebook = '';
  public whatsapp = '';

  constructor( private authService: AuthService,
               private apiService : ApiService,
               private dialog : MatDialog ) { }

  ngOnInit(): void {
    this.readSocial();
    this.searchToken();

    this.authService.userLogin.subscribe( data => {
      if(data.data){
        this.logean = true;
      }else{
        this.logean = false;
      }
    })
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  whatsapp2(){
    let url = `https://wa.me/${this.whatsapp}?text=Hola%20Batista%20Properties.`
    window.open(url, "_blank");
  }

  readSocial(){
    this.apiService.readAll('redes').subscribe(resp =>{
      if(resp.results != 'Not Found'){
        resp.results.forEach(resp2 => {
          switch (resp2.name_red) {
            case 'instagram':
              this.instagram = resp2.url_red;  
              break;

            case 'facebook':
              this.facebook = resp2.url_red; 
              break;

            case 'whatsapp':
              this.whatsapp = resp2.url_red; 
              break;
          }
        })
      }
    })
  }

  searchToken(){
    if(localStorage.getItem('token')){
      this.logean = true;
      this.data =Object.values(this.authService.getDecodedAccessToken( localStorage.getItem('token') || '' ));
    }
  }

  /*
  safe(){
    const dialogRef = this.dialog.open(SafeComponent, {
      width:'700px'
    })
  }
  */

}
