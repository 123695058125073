<div class="container-fluid">

  <!-- NAVBAR DESKTOP -->
  <div class="row navbare">
    <div class="col-3 col-lg-5 text-start text-lg-center align-self-center">
      <ul class="d-none d-lg-block">
        <li>
          <a routerLink="/home" routerLinkActive="active" (click)="verif(false)">{{'INICIO' | translate}}</a>
        </li>
        <li>
          <a routerLink="/about" routerLinkActive="active" (click)="verif(false)">{{'NOSOTROS' | translate}}</a>
        </li>
        <li class="dropdown">
          <a class="dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {{'PROPIEDADES' | translate}}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" routerLink="/properties/cl"
                (click)="property('cl')">{{'PROPIEDADES' | translate}} CL</a></li>
            <li><a class="dropdown-item" routerLink="/properties/rd"
                (click)="property('rd')">{{'PROPIEDADES' | translate}} RD</a></li>
          </ul>
        </li>
      </ul>
      <span class="d-lg-none ms-3" (click)="openMenuMov()"><i class="fa-solid fa-bars fa-2x"></i></span>
    </div>
    <div class="col-6 col-lg-2 logo-navbar text-center">
      <img src="../../../assets/img/logo2.png" class="logoT" [ngClass]="{'logoT-fix': navFix}" alt="">
    </div>
    <div class="col-3 col-lg-5 align-self-center text-end text-lg-center">
      <ul class="d-none d-lg-block">
        <li *ngIf="!logean">
          <!--
          <a href="#join">{{'UNETE' | translate}}</a>
          -->
          <a routerLink="/join">{{'UNETE' | translate}}</a>
        </li>
        <li class="dropdown" *ngIf="!logean">
          <a class="dropdown-toggle" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {{'IDIOMA' | translate}}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown2">
            <li><a class="dropdown-item" (click)="changeLang('es')">ESPAÑOL</a></li>
            <li><a class="dropdown-item" (click)="changeLang('en')">ENGLISH</a></li>
          </ul>
        </li>
        <li class="dropdown" *ngIf="!logean">
          <a class="dropdown-toggle" id="navbarDropdown3" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {{'MONEDA' | translate}}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown3">
            <li><a class="dropdown-item" (click)="changeCurrency(2)">UF</a></li>
            <li><a class="dropdown-item" (click)="changeCurrency(1)">PESOS</a></li>
            <li><a class="dropdown-item" (click)="changeCurrency(3)">{{'DOLAR' | translate}}</a></li>
          </ul>
        </li>

        <li class="dropdown" *ngIf="logean">

          <a class="dropdown-toggle" id="navbarDropdown4" role="button" data-bs-toggle="dropdown" aria-expanded="false">

            <i class="fa-solid fa-bell crazy" matBadge="{{(requests | request:'interest').length}}" matBadgeColor="warn"
              matBadgeSize="small" [matBadgeHidden]="(requests | request:'interest').length == 0"
              [ngClass]="{'red': (requests | request:'interest').length > 0}"></i>

          </a>

          <ul class="dropdown-menu" aria-labelledby="navbarDropdown4">
            <li class="dropdown-item" *ngIf="(requests | request:'interest').length == 0">No hay solicitudes</li>
            <li *ngFor="let req of requests | request:'interest'"><a class="dropdown-item cpointer"
                (click)="viewRequest(req.id_interest)">{{req.name_interest}} ha enviado una solicitud</a></li>
            <li *ngIf="(requests).length > 1"><a class="dropdown-item cpointer" routerLink="/admin/requests">VER
                TODAS</a></li>
          </ul>
        </li>

        <li class="dropdown" *ngIf="logean">
          <a class="dropdown-toggle" id="navbarDropdown5" role="button" data-bs-toggle="dropdown" aria-expanded="false">

            <i class="fa-solid fa-message crazy" matBadge="{{(messages | request:'message').length}}"
              matBadgeColor="warn" matBadgeSize="small" [matBadgeHidden]="(messages | request:'message').length == 0"
              [ngClass]="{'red':(messages | request:'message').length > 0}"></i>

          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown5">
            <li class="dropdown-item" *ngIf="(messages | request:'messages').length == 0">No hay mensajes</li>
            <li *ngFor="let msg of messages | request:'messages'"><a class="dropdown-item cpointer"
                (click)="viewMessage(msg.id_adviser)">{{msg.name_adviser}} ha enviado una solicitud</a></li>
            <li *ngIf="(messages).length > 1"><a class="dropdown-item cpointer" routerLink="/admin/messages">VER
                TODOS</a></li>
          </ul>
        </li>


        <li class="dropdown" *ngIf="logean">
          <a class="dropdown-toggle" id="navbarDropdown6" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            ADMINISTRACIÓN
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown6">
            <li><a class="dropdown-item cpointer" routerLink="/admin/home" (click)="verif(true)">PANEL</a></li>
            <li *ngIf="role == '1'"><a class="dropdown-item cpointer" routerLink="/admin/users"
                (click)="verif(true)">USUARIOS</a></li>
            <li><a class="dropdown-item cpointer" routerLink="/admin/properties" (click)="verif(true)">PROPIEDADES</a>
            </li>
            <li><a class="dropdown-item cpointer" routerLink="/admin/owners" (click)="verif(true)">PROPIETARIOS</a></li>
            <li><a class="dropdown-item cpointer" routerLink="/admin/safe" (click)="verif(true)">SEGURO</a></li>
            <li><a class="dropdown-item cpointer" routerLink="/admin/blog" (click)="verif(true)">BLOG</a></li>
            <li><a class="dropdown-item cpointer" routerLink="/admin/faq" (click)="verif(true)">FAQ</a></li>
            <li><a class="dropdown-item cpointer" (click)="salir()">CERRAR SESIÓN</a></li>
          </ul>
        </li>

      </ul>
      <span class="d-lg-none me-3 cpointer" routerLink="/admin/home" (click)="funko(5,null,true)"><i
          class="fa-solid fa-circle-user fa-2x"></i></span>
    </div>
  </div>
</div>

<!-- BOTON ADMINISTRACION -->

<div class="adm d-none d-lg-block" routerLink="/admin/home" *ngIf="!logean">
  <i class="fa-solid fa-circle-user fa-2x" (click)="verif(true)"></i>
</div>
<!--
<div class="menu-der row" *ngIf="!logean">
  <div class="col-2 closed">
    X
  </div>
  <div class="col-5 menu-1">
    {{'QVENDER' | translate}}
  </div>
  <div class="col-5 menu-2">
    {{'QCOMPRAR' | translate}}
  </div>
</div>
-->

<!--FONDO TRANSPARENTE-->
<div class="flashback" [ngClass]="{'flashback-fix':menuDer}"></div>

<!--BARRA ABAJO MOVIL-->
<div class="caja-11 d-lg-none" [ngClass]="{'caja-11-fix':direction}">
  <div class="closed2" (click)="openMenuDer(3)" [ngClass]="{'closded-fix':menuDer}">
    X
  </div>
  <div class="menu-11" (click)="openMenuDer(1)">
    {{'QVENDER' | translate}}
  </div>
  <div class="menu-22" (click)="openMenuDer(2)">
    {{'QCOMPRAR' | translate}}
  </div>
</div>

<!--BARRA LATERAL DESKTOP-->
<div class="row menu-der-global" *ngIf="!logean" [ngClass]="{'menu-der-global-fix':menuDer}">
  <div class="caja1 d-none d-lg-block">
    <div class="closed" (click)="openMenuDer(3)" [ngClass]="{'closded-fix':menuDer}">
      X
    </div>
    <div class="menu-1" (click)="openMenuDer(1)">
      {{'QVENDER' | translate}}
    </div>
    <div class="menu-2" (click)="openMenuDer(2)">
      {{'QCOMPRAR' | translate}}
    </div>
  </div>
  <div class="caja2">
    <form [formGroup]="adForm" (ngSubmit)="sendAdviser(2)" *ngIf="!forma1">
      <div class="row justify-content-center forma">
        <div class="col-12 col-lg-10">
          <h3>{{'QVENDER2'|translate}}</h3>
          <p>{{'QVENDER22'|translate}}</p>
        </div>
        <div class="col-12 col-lg-10 mt-4">
          <label for="name">{{'NOMBRECOMPLETO'|translate}}</label>
          <span *ngIf="namePattern"> {{'ERROR1'|translate}}</span>
          <span *ngIf="nameRequired"> {{'ERROR2'|translate}}</span>
          <input type="text" class="w-100" formControlName="name" [class.form-inv]="namePattern ||nameRequired">
        </div>
        <div class="col-12 col-lg-4 mt-4">
          <label for="info">{{'FORMA1'|translate}}</label>
          <span *ngIf="infoPattern"> {{'ERROR1'|translate}}</span>
          <span *ngIf="infoRequired"> {{'ERROR2'|translate}}</span>
          <select formControlName="info" [class.form-inv]="infoPattern ||infoRequired">
            <option value="casa">{{'FORMA2'|translate}}</option>
            <option value="departamento">{{'FORMA3'|translate}}</option>
            <option value="terreno">{{'FORMA4'|translate}}</option>
            <option value="loteo">{{'FORMA5'|translate}}</option>
            <option value="propiedad de inversión">{{'FORMA6'|translate}}</option>
            <option value="oficina">{{'FORMA7'|translate}}</option>
            <option value="local comercial">{{'FORMA8'|translate}}</option>
            <option value="negocio turístico">{{'FORMA9'|translate}}</option>
            <option value="otro">{{'FORMA10'|translate}}</option>
          </select>
        </div>
        <div class="col-12 col-lg-3 mt-4">
          <label for="country">{{'FORMA11'|translate}}</label>
          <span *ngIf="countryPattern"> {{'ERROR1'|translate}}</span>
          <span *ngIf="countryRequired"> {{'ERROR2'|translate}}</span>
          <input type="text" class="w-100" formControlName="country"
            [class.form-inv]="countryPattern ||countryRequired">
        </div>
        <div class="col-12 col-lg-3 mt-4">
          <label for="city">{{'FORMA12'|translate}}</label>
          <span *ngIf="cityPattern"> {{'ERROR1'|translate}}</span>
          <span *ngIf="cityRequired"> {{'ERROR2'|translate}}</span>
          <input type="text" class="w-100" formControlName="city" [class.form-inv]="cityPattern ||cityRequired">
        </div>
        <div class="col-12 col-lg-5 mt-4">
          <label for="email">E-MAIL</label>
          <span *ngIf="emailPattern"> {{'ERROR3'|translate}}</span>
          <span *ngIf="emailRequired"> {{'ERROR2'|translate}}</span>
          <input type="text" class="w-100" formControlName="email" [class.form-inv]="emailPattern ||emailRequired">
        </div>
        <div class="col-12 col-lg-5 mt-4">
          <label for="phone">{{'CCONTACTO'|translate}}</label>
          <span *ngIf="phonePattern"> {{'ERROR4'|translate}}</span>
          <span *ngIf="phoneRequired"> {{'ERROR2'|translate}}</span>
          <input type="text" class="w-100" formControlName="phone" [class.form-inv]="phonePattern ||phoneRequired">
        </div>

        <div class="col-12 col-lg-10 text-end mt-4 order-1 order-lg-2">
          <button type="submit">{{'ENVIARBTN'|translate}}</button>
        </div>
      </div>
    </form>
    <form [formGroup]="adForm" (ngSubmit)="sendAdviser(3)" *ngIf="forma1">
      <div class="row justify-content-center forma">
        <div class="col-12 col-lg-10">
          <h3>{{'QVENDER3'|translate}}</h3>
          <p>{{'QVENDER33'|translate}}</p>
        </div>
        <div class="col-12 col-lg-10 mt-4">
          <label for="name">{{'NOMBRECOMPLETO'|translate}}</label>
          <span *ngIf="namePattern"> {{'ERROR1'|translate}}</span>
          <span *ngIf="nameRequired"> {{'ERROR2'|translate}}</span>
          <input type="text" class="w-100" formControlName="name" [class.form-inv]="namePattern ||nameRequired">
        </div>
        <div class="col-12 col-lg-4 mt-4">
          <label for="info">{{'FORMA1x'|translate}}</label>
          <span *ngIf="infoPattern"> {{'ERROR1'|translate}}</span>
          <span *ngIf="infoRequired"> {{'ERROR2'|translate}}</span>
          <select formControlName="info" [class.form-inv]="infoPattern ||infoRequired">
            <option value="Me interesa para vivir">{{'FORMA2x'|translate}}</option>
            <option value="Me interessa para invertir">{{'FORMA3x'|translate}}</option>
            <option value="Me interesa para vivir e invertir">{{'FORMA4x'|translate}}</option>
          </select>
        </div>
        <div class="col-12 col-lg-3 mt-4">
          <label for="country">{{'FORMA11'|translate}}</label>
          <span *ngIf="countryPattern"> {{'ERROR1'|translate}}</span>
          <span *ngIf="countryRequired"> {{'ERROR2'|translate}}</span>
          <input type="text" class="w-100" formControlName="country"
            [class.form-inv]="countryPattern ||countryRequired">
        </div>
        <div class="col-12 col-lg-3 mt-4">
          <label for="city">{{'FORMA12'|translate}}</label>
          <span *ngIf="cityPattern"> {{'ERROR1'|translate}}</span>
          <span *ngIf="cityRequired"> {{'ERROR2'|translate}}</span>
          <input type="text" class="w-100" formControlName="city" [class.form-inv]="cityPattern ||cityRequired">
        </div>
        <div class="col-12 col-lg-5 mt-4">
          <label for="email">E-MAIL</label>
          <span *ngIf="emailPattern"> {{'ERROR3'|translate}}</span>
          <span *ngIf="emailRequired"> {{'ERROR2'|translate}}</span>
          <input type="text" class="w-100" formControlName="email" [class.form-inv]="emailPattern ||emailRequired">
        </div>
        <div class="col-12 col-lg-5 mt-4">
          <label for="phone">{{'CCONTACTO'|translate}}</label>
          <span *ngIf="phonePattern"> {{'ERROR4'|translate}}</span>
          <span *ngIf="phoneRequired"> {{'ERROR2'|translate}}</span>
          <input type="text" class="w-100" formControlName="phone" [class.form-inv]="phonePattern ||phoneRequired">
        </div>

        <div class="col-12 col-lg-10 text-end mt-4 order-1 order-lg-2">
          <button type="submit">{{'ENVIARBTN'|translate}}</button>
        </div>
      </div>
    </form>
  </div>
</div>

<!--MENU MOVIL-->
<div class="row d-lg-none">
  <div class="col-12 align-self-center black2" [ngClass]="{'black2-fix':openMenu}">
    <div class="row justify-content-center">
      <div class="col-6 text-center" *ngIf="logean" (click)="openMenuMov()" routerLink="/admin/requests">
        <i class="fa-solid fa-bell crazy" matBadge="{{(requests | request:'interest').length}}" matBadgeColor="warn"
          matBadgeSize="small" [matBadgeHidden]="(requests | request:'interest').length == 0"
          [ngClass]="{'red': (requests | request:'interest').length > 0}"></i>
      </div>
      <div class="col-6 text-center" *ngIf="logean" (click)="openMenuMov()" routerLink="/admin/messages">
        <i class="fa-solid fa-message crazy" matBadge="{{(messages | request:'message').length}}" matBadgeColor="warn"
          matBadgeSize="small" [matBadgeHidden]="(messages | request:'message').length == 0"
          [ngClass]="{'red':(messages | request:'message').length > 0}"></i>
      </div>
      <div class="col-10 mt-4 btn-menu">
        <a routerLink="/home" routerLinkActive="active" (click)="funko(1,null,false)">{{'INICIO' | translate}}</a>
      </div>
      <div class="col-10 btn-menu">
        <a routerLink="/about" routerLinkActive="active" (click)="funko(1,null,false)">{{'NOSOTROS' | translate}}</a>
      </div>
      <div class="col-10 btn-menu" (click)="subes('prop')">
        {{'PROPIEDADES' | translate}}
      </div>
      <div class="col-12 subes" [ngClass]="{'subes-fix':prop}">
        <ul>
          <li><a routerLink="/properties/cl" (click)="funko(2,'cl')">{{'PROPIEDADES' | translate}} CL</a></li>
          <li><a routerLink="/properties/rd" (click)="funko(2,'rd')">{{'PROPIEDADES' | translate}} RD</a></li>
        </ul>
      </div>
      <div class="col-10 btn-menu" *ngIf="!logean">
        <a routerLink="/faq" (click)="funko(1,null,false)">{{'FOOT1' | translate}}</a>
      </div>
      <div class="col-10 btn-menu" *ngIf="!logean">
        <a routerLink="/safe" (click)="funko(1,null,false)">{{'FOOT2' | translate}}</a>
      </div>
      <div class="col-10 btn-menu" *ngIf="!logean">
        <a routerLink="/blog" (click)="funko(1,null,false)">{{'FOOT3' | translate}}</a>
      </div>
      <div class="col-10 btn-menu" *ngIf="!logean">
        <a routerLink="/join" (click)="funko(1,null,false)">{{'UNETE' | translate}}</a>
        <!--{{'VENDE' | translate}}-->
      </div>
      <div class="col-10 btn-menu" *ngIf="!logean" (click)="subes('idio')">
        {{'IDIOMA' | translate}}
      </div>
      <div class="col-12 subes" [ngClass]="{'subes-fix':idio}">
        <ul>
          <li><a (click)="funko(3,'es')">ESPAÑOL</a></li>
          <li><a (click)="funko(3,'en')">ENGLISH</a></li>
        </ul>
      </div>
      <div class="col-10 btn-menu" *ngIf="!logean" (click)="subes('mone')">
        {{'MONEDA' | translate}}
      </div>
      <div class="col-12 subes" [ngClass]="{'subes1-fix':mone}">
        <ul>
          <li><a (click)="funko(4,null,null,2)">UF</a></li>
          <li><a (click)="funko(4,null,null,1)">PESOS</a></li>
          <li><a (click)="funko(4,null,null,3)">{{'DOLAR' | translate}}</a></li>
        </ul>
      </div>
      <div class="col-10 btn-menu" *ngIf="logean" (click)="subes('admi')">
        ADMINISTRACIÓN
      </div>
      <div class="col-12 subes" [ngClass]="{'subes2-fix':admi}">
        <ul aria-labelledby="navbarDropdown6">
          <li><a class="cpointer" routerLink="/admin/home" (click)="funko(1,null,true)">PANEL</a></li>
          <li *ngIf="role == '1'"><a class="cpointer" routerLink="/admin/users"
              (click)="funko(1,null,true)">USUARIOS</a></li>
          <li><a class="cpointer" routerLink="/admin/properties" (click)="funko(1,null,true)">PROPIEDADES</a></li>
          <li><a class="cpointer" routerLink="/admin/owners" (click)="funko(1,null,true)">PROPIETARIOS</a></li>
          <li><a class="cpointer" routerLink="/admin/safe" (click)="funko(1,null,true)">SEGURO</a></li>
          <li><a class="cpointer" routerLink="/admin/blog" (click)="funko(1,null,true)">BLOG</a></li>
          <li><a class="cpointer" routerLink="/admin/faq" (click)="funko(1,null,true)">FAQ</a></li>
          <!--
          <li><a class="cpointer" (click)="funko(1,null,true)">SITIO WEB</a></li>
          -->
          <li><a class="cpointer" (click)="funko(6,null)">CERRAR SESIÓN</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
