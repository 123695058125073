import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OwnerModel } from 'src/app/shared/models/owner';
import { ApiService } from 'src/app/shared/services/api.service';
import Swal from 'sweetalert2';
import { OwnersService } from '../shared/services/owners.service';

@Component({
  selector: 'app-edit-owner',
  templateUrl: './edit-owner.component.html',
  styleUrls: ['./edit-owner.component.css']
})
export class EditOwnerComponent implements OnInit {

  public ownerForm!: FormGroup;
  public countries: any[]=[];
  public owner: OwnerModel = new OwnerModel();

  constructor( public dialogRef: MatDialogRef<EditOwnerComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any,
               public fb: FormBuilder,
               public ownerService: OwnersService,
               public apiService: ApiService ) {

                this.ownerForm = fb.group({
                  name: data.name,
                  lastname: data.lastname,
                  dni: data.dni,
                  email: data.email,
                  phone: data.phone,
                  address: data.address,
                  obs: data.obs,
                  country: data.country
                });
              }

  ngOnInit(): void {

    this.readCountries();

    this.ownerForm.setValue({
      name: this.ownerForm.controls.name.value,
      lastname: this.ownerForm.controls.lastname.value,
      dni: this.ownerForm.controls.dni.value,
      email: this.ownerForm.controls.email.value,
      phone: this.ownerForm.controls.phone.value,
      address: this.ownerForm.controls.address.value,
      obs: this.ownerForm.controls.obs.value,
      country: this.ownerForm.controls.country.value
    })
  }

  readCountries(){
    this.apiService.readAll('countries').subscribe(resp => {
      this.countries = resp.results;
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  editOwner(){

    if (this.ownerForm.invalid) {
      return Object.values(this.ownerForm.controls).forEach((control) => {
        control.markAllAsTouched();
      });
    }

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor',
    });
    Swal.showLoading();

    this.owner.name_owner = this.ownerForm.controls.name.value; 
    this.owner.lastname_owner = this.ownerForm.controls.lastname.value;
    this.owner.dni_owner = this.ownerForm.controls.dni.value;
    this.owner.email_owner = this.ownerForm.controls.email.value;
    this.owner.phone_owner = this.ownerForm.controls.phone.value;
    this.owner.address_owner = this.ownerForm.controls.address.value;
    this.owner.obs_owner = this.ownerForm.controls.obs.value;
    this.owner.id_country_owner = this.ownerForm.controls.country.value;

    this.ownerService.editOwner(this.owner, this.data.id).subscribe(resp => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'El propietario ha sido editado con éxito',
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        this.dialogRef.close();
      });
    })
  }
}
