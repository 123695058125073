<div class="container-fluid">
  <div class="row justify-content-center login">

    <div class="col-12 col-lg-4" *ngIf="access == false">
      <mat-card class="example-card">
        <mat-card-title>Registro</mat-card-title>
        <mat-card-subtitle>Ingresar código enviado a su correo electrónico.</mat-card-subtitle>
        <mat-divider></mat-divider>
        <mat-card-content>

          <form [formGroup]="codeForm">
            <mat-form-field appearance="fill" class="w-100 mt-4">
              <mat-label>Código</mat-label>
              <input matInput formControlName="codeMail">
            </mat-form-field>
            <div>
              <button mat-raised-button type="button" color="primary" (click)="validateCode()">Enviar</button>
            </div>
          </form>

        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-12 col-lg-8" *ngIf="access">
      <mat-card class="example-card">
        <mat-card-header>
          <mat-card-title>Registro de usuario</mat-card-title>
          <mat-card-subtitle>{{parametro}}</mat-card-subtitle>
        </mat-card-header>
        <mat-horizontal-stepper [linear]="isLinear" #stepper>
          <mat-step [stepControl]="accessForm">
            <form [formGroup]="accessForm">
              <ng-template matStepLabel>Datos de Acceso</ng-template>
              <div class="row mt-2">
                <div class="col-12 col-lg-6">
                  <span *ngIf="passwordPattern" class="text-danger"> No escriba caracteres especiales.</span>
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Contraseña</mat-label>
                    <input matInput type="password" formControlName="password" [class.form-inv]="passwordPattern"
                      matTooltip="Mínimo 6 caracteres y esta permitido -@!#%._" matTooltipClass="example-tooltip-red"
                      aria-label="Button that shows a red tooltip">
                  </mat-form-field>
                </div>
                <div class="col-12 col-lg-6">
                  <span *ngIf="password2Pattern" class="text-danger"> No escriba caracteres especiales.</span>
                  <span *ngIf="password2NoValido" class="text-danger"> Las contraseñas no coinciden.</span>
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Repetir Contraseña</mat-label>
                    <input matInput type="password" formControlName="password2" [class.form-inv]="password2Pattern ||password2NoValido">
                  </mat-form-field>
                </div>
              </div>

              <div>
                <button type="button" mat-raised-button matStepperNext>Siguiente</button>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="infoForm">
            <form [formGroup]="infoForm">
              <ng-template matStepLabel>Información de Usuario</ng-template>
              <div class="row mt-2">
                <div class="col-12 col-lg-4">
                  <span *ngIf="namePattern" class="text-danger"> No escriba caracteres especiales.</span>
                  <span *ngIf="nameRequired" class="text-danger"> Campo requerido.</span>
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Nombre</mat-label>
                    <input matInput formControlName="name" [class.form-inv]="namePattern ||nameRequired">
                  </mat-form-field>
                </div>
                <div class="col-12 col-lg-4">
                  <span *ngIf="lastnamePattern" class="text-danger"> No escriba caracteres especiales.</span>
                  <span *ngIf="lastnameRequired" class="text-danger"> Campo requerido.</span>
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Apellidos</mat-label>
                    <input matInput formControlName="lastname" [class.form-inv]="lastnamePattern ||lastnameRequired">
                  </mat-form-field>
                </div>
                <div class="col-12 col-lg-4">
                  <span *ngIf="dniPattern" class="text-danger"> Rut no válido (Ej: 11.111.111-1).</span>
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>RUT</mat-label>
                    <input matInput formControlName="dni" [class.form-inv]="dniPattern">
                  </mat-form-field>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 col-lg-4">
                  <span *ngIf="phonePattern" class="text-danger"> No escriba caracteres especiales.</span>
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Teléfono</mat-label>
                    <input matInput formControlName="phone" [class.form-inv]="phonePattern">
                  </mat-form-field>
                </div>
                <div class="col-12 col-lg-4">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>País</mat-label>
                    <mat-select formControlName="country">
                      <mat-option value="{{country.id_country}}" *ngFor="let country of countries">
                        {{country.namees_country | titlecase}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div>
                <button type="button" mat-raised-button matStepperPrevious>Atras</button>
                <button type="button" class="ms-2" mat-raised-button matStepperNext>Siguiente</button>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="imageForm">
            <form [formGroup]="imageForm">
              <ng-template matStepLabel>Imagen Usuario</ng-template>
              <div class="row justify-content-center mt-3">
                <div class="col-12 col-lg-4">
                  <img src="../../../../assets/img/no-image.webp" class="w-100" alt="" *ngIf="urlImage == undefined">
                  <img [src]="urlImage" class="w-100" *ngIf="urlImage">
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-12 col-lg-4 mb-4 mb-lg-0">
                  <button type="button" mat-raised-button color="primary" (click)="fileInput.click()" class="w-100">
                    <span><i class="fas fa-cloud-upload-alt mr-3"></i> Subir Imagen</span>
                    <input #fileInput type="file" (change)="onSelectFile($event)" style="display:none;"
                      formControlName="image" />
                  </button>
                </div>
              </div>
            </form>
            <div>
              <button type="button" mat-raised-button matStepperPrevious>Atras</button>
              <button mat-raised-button class="ms-2" color="primary" (click)="sendRegister()">Enviar</button>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
      </mat-card>
    </div>
  </div>
</div>
