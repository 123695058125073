<div class="container-fluid info">
    <div class="row justify-content-center">
        <div class="col-12 col-lg-5">
            <h1>Propietarios</h1>
        </div>
        <div class="col-12 col-lg-5 text-lg-end">
            <button mat-flat-button color="primary" (click)="addOwner()">Agregar Propietario</button>  
        </div>
        <div class="col-12 col-lg-11">
            <hr>
        </div>
        <div class="col-12 col-lg-10">
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Buscar por email</mat-label>
                        <span class="prefixSearch me-2" matPrefix><i class="fa-solid fa-magnifying-glass"></i>&nbsp;</span>
                        <input matInput>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-3" *ngIf="owners">
                <div class="col-12 col-lg-3 mb-3 mb-lg-0" *ngFor="let owner of owners">
                    <mat-card class="example-card">
                        <div class="row">
                          <div class="col-10">
                            <mat-card-header>
                              <mat-card-title>{{owner.name_owner}}<br> <small>{{owner.lastname_owner}}</small></mat-card-title>
                              <mat-card-subtitle class="mb-0">{{owner.email_owner}}</mat-card-subtitle>
                            </mat-card-header>
                            <div class="row mt-4">
                                <div class="col-2 ms-3" *ngIf="owner.id_country_owner == 3">
                                    <img src="../../assets/img/flags/chile.png" class="w-100" alt="chilean flag">
                                </div>
                                <div class="col-2 ms-3" *ngIf="owner.id_country_owner == 4">
                                    <img src="../../assets/img/flags/dominicana.png" class="w-100" alt="dominican flag">
                                </div>
                                <div class="col-2 p-0">
                                    <small *ngIf="owner.id_country_owner == 3">CL</small>
                                    <small *ngIf="owner.id_country_owner == 4">RD</small>
                                </div>
                                
                              </div>
                          </div>
                          <div class="col-2">
                            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                              <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                              <button mat-menu-item (click)="profileOwner(owner)">
                                <mat-icon>person</mat-icon>
                                <span>Perfil</span>
                              </button>
                              <button mat-menu-item (click)="editOwner(owner)">
                                <mat-icon>edit</mat-icon>
                                <span>Editar</span>
                              </button>
                              <button mat-menu-item *ngIf="owner.id_owner != 1" (click)="delOwner(owner.id_owner)">
                                <mat-icon>delete</mat-icon>
                                <span>Eliminar</span>
                              </button>
                            </mat-menu>
                          </div>
                        </div>
                      </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>
