import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { OwnerModel } from 'src/app/shared/models/owner';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class OwnersService {
  private url = 'https://api.dosiscl.com';
  private userTokenApi = 'c5LTA6WPbMwHhEabYu77nN9cn4VcMj';
  private userToken = '';
  public data: any[] = [];

  constructor(private http: HttpClient, private authService: AuthService) {
    this.readToken();
    if (this.userToken != '') {
      this.data = Object.values(
        this.authService.getDecodedAccessToken(this.userToken)
      );
    }
  }

  readTokenDecoded() {
    return this.data;
  }

  readToken() {
    if (localStorage.getItem('token')) {
      this.userToken = localStorage.getItem('token') || '';
    } else {
      this.userToken = '';
    }

    return this.userToken;
  }

  addOwner( owner: OwnerModel ){

    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Accept': '*/*',
      'Authorization':this.userTokenApi
    }); 

    const payload = new HttpParams()
    .set('name_owner', owner.name_owner)
    .set('lastname_owner', owner.lastname_owner)
    .set('dni_owner', owner.dni_owner)
    .set('email_owner', owner.email_owner)
    .set('id_country_owner', owner.id_country_owner.toString())
    .set('phone_owner', owner.phone_owner)
    .set('address_owner', owner.address_owner)
    .set('obs_owner', owner.obs_owner)
    .set('date_created_owner', owner.date_created_owner);

    return this.http.post(`${this.url}/owners?token=${this.userToken}&table=users&suffix=user&except`,payload,{headers:headers})
    .pipe(
      map((resp:any) => {
        return resp;
      })
    )

  }

  editOwner(owner:OwnerModel, id:number){
    
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    const payload = new HttpParams()
    .set('name_owner', owner.name_owner)
    .set('lastname_owner', owner.lastname_owner)
    .set('dni_owner', owner.dni_owner)
    .set('email_owner', owner.email_owner)
    .set('id_country_owner', owner.id_country_owner.toString())
    .set('phone_owner', owner.phone_owner)
    .set('address_owner', owner.address_owner)
    .set('obs_owner', owner.obs_owner);

    return this.http.put(`${this.url}/owners?id=${id}&nameId=id_owner&token=${this.userToken}&table=users&suffix=user`,payload,{headers:headers})
    .pipe(
      map((resp:any) => {
        return resp;
      })
    )
  }

  readPropertiesId( id : number ){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/properties2?select=*&linkTo=id_owner_property&search=${id}`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  async intoEdit(properties: any){

    for await (const prope of properties.map(prop => prop)){
      await this.editProperties(prope.id_property).subscribe(resp2 => {
      })      
    }

  }

  editProperties( id : number ){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    });

    const payload = new HttpParams()
    .set('id_owner_property', '1');

    return this.http.put(`${this.url}/properties2?id=${id}&nameId=id_property&token=${this.userToken}&table=users&suffix=user`,payload,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )

  }

  deleteOwners( id : number ){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    return this.http.delete(`${this.url}/owners?id=${id}&nameId=id_owner&token=${this.userToken}&table=users&suffix=user`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }
}
