import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { PropertiesModel } from 'src/app/shared/models/properties';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PropertiesService {

  private url = 'https://api.dosiscl.com';
  private userTokenApi = 'c5LTA6WPbMwHhEabYu77nN9cn4VcMj';
  private userToken = '';
  public data: any[] = [];

  constructor( private http: HttpClient,
               private authService: AuthService ) { 
    this.readToken();
    if( this.userToken != '' ){
      this.data = Object.values( this.authService.getDecodedAccessToken( this.userToken ) );
    }
  }

  readTokenDecoded(){
    return this.data;
  }

  readToken(){
    if (localStorage.getItem('token')) {
      this.userToken = localStorage.getItem('token') || '';
    } else {
      this.userToken = '';
    }

    return this.userToken;
  }

  

  readAllRelations(){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/relations?rel=properties2,countries,regions,communes,users,payments&type=property,country,region,commune,user,payment&select=*&orderBy=id_property&orderMode=ASC`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  searchRegion( id: number ){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/regions?select=*&linkTo=id_country_region&search=${id}`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  searchCommune( id: number ){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/communes?select=*&linkTo=id_region_commune&search=${id}`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  searchVisits( id:number ){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/visits?select=*&linkTo=id_property_visit&search=${id}`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  /*
  addProperty( property: PropertiesModel ){

    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    return this.http.post(`${this.url}/properties?token=${this.userToken}&table=users&suffix=user&except`,property,{headers:headers})
    .pipe(
      map((resp:any) => {
        return resp;
      })
    )
  }
  */

  addProperty( property: PropertiesModel ){

    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Accept': '*/*',
      'Authorization':this.userTokenApi
    }); 

    const payload = new HttpParams()
    .set('id_user_property', property.id_user_property.toString())
    .set('id_operation_property', property.id_operation_property.toString())
    .set('id_estate_property', property.id_estate_property.toString())
    .set('description_property', property.description_property)
    .set('description_short_property', property.description_short_property)
    .set('description_prin_property', property.description_prin_property)
    .set('url_property', property.url_property)
    .set('rol_property', property.rol_property)
    .set('value_property', property.value_property)
    .set('id_payment_property', property.id_payment_property.toString())
    .set('highlight_property', property.highlight_property.toString())
    .set('subsidy_property', property.subsidy_property.toString())
    .set('exchange_property', property.exchange_property.toString())
    .set('id_commune_property', property.id_commune_property.toString())
    .set('id_region_property', property.id_region_property.toString())
    .set('id_country_property', property.id_country_property.toString())
    .set('sector_property', property.sector_property)
    .set('id_geography_property', property.id_geography_property.toString())
    .set('map_property', property.map_property.toString())
    .set('markers_property', property.markers_property)
    .set('ground_property', property.ground_property.toString())
    .set('built_property', property.built_property.toString())
    .set('bedrooms_property', property.bedrooms_property.toString())
    .set('bathing_property', property.bathing_property.toString())
    .set('parking_property', property.parking_property.toString())
    .set('description2_property', property.description2_property)
    .set('id_owner_property', property.id_owner_property.toString())
    .set('amenities_property', property.amenities_property)
    .set('noval_property', property.noval_property.toString())
    .set('image_property', property.image_property)
    .set('status_property', property.status_property.toString())
    .set('date_created_property', property.date_created_property.toString());

    return this.http.post(`${this.url}/properties2?token=${this.userToken}&table=users&suffix=user&except`,payload,{headers:headers})
    .pipe(
      map((resp:any) => {
        return resp;
      })
    )
  }

  /*
  async deleteImage(name:any){

    for await (const image of name.map((img:any) => img)) {
      
      const storage = getStorage();
      const desertRef = ref(storage,'properties/'+image.name);

      await deleteObject(desertRef).then(() => {
        //console.log('si');
      }).catch((error) => {
        //console.log('no');
      })
    }
    
  }
  


  async intoImages( id: number, images: any ){

      let datos = [];

      for await (const image of images.map((img: any) => img)) {
        //let ext = image.substring("data:image/".length, image.indexOf(";base64"));
        let data = id.toString() + '_' + Date.now();
        await this.uploadImage(data, image).then((urlImage) => {
          datos.push({name: data, url: urlImage});
        })
        //datos.push(image);
      }
      return datos;

    /*
    await images.forEach(image => {
      let data = id.toString() + '_' + Date.now();
      this.uploadImage(data, image).then((urlImage) => {
        datos.push(urlImage);
      })
      
    });
    return datos;

  
    
    return await this.uploadImage(name,imgBase64).then((urlImage) => {
      return urlImage;
    })
    

  }
  

  async uploadImage( name: string, imgBase64: any ){

    try{
      let response = await this.storageRef.child("properties/"+name).putString(imgBase64, 'data_url');
      return await response.ref.getDownloadURL();
    }catch(err){
      return null;
    }

  }
  */

  

  editProperty( table: string, data: any, id: number ){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    const payload = new HttpParams()
    .set(table, data);

    return this.http.put(`${this.url}/properties2?id=${id}&nameId=id_property&token=${this.userToken}&table=users&suffix=user`,payload,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  editProperty2(property: any, id: number){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    });
    
    const payload = new HttpParams()
    .set('id_operation_property', property.id_operation_property.toString())
    .set('id_estate_property', property.id_estate_property.toString())
    .set('description_property', property.description_property)
    .set('description_short_property', property.description_short_property)
    .set('description_prin_property', property.description_prin_property)
    .set('url_property', property.url_property)
    .set('rol_property', property.rol_property)
    .set('value_property', property.value_property)
    .set('id_payment_property', property.id_payment_property.toString())
    .set('highlight_property', property.highlight_property.toString())
    .set('subsidy_property', property.subsidy_property.toString())
    .set('exchange_property', property.exchange_property.toString())
    .set('id_commune_property', property.id_commune_property.toString())
    .set('id_region_property', property.id_region_property.toString())
    .set('id_country_property', property.id_country_property.toString())
    .set('sector_property', property.sector_property)
    .set('id_geography_property', property.id_geography_property.toString())
    .set('map_property', property.map_property.toString())
    .set('markers_property', property.markers_property)
    .set('ground_property', property.ground_property.toString())
    .set('built_property', property.built_property.toString())
    .set('bedrooms_property', property.bedrooms_property.toString())
    .set('bathing_property', property.bathing_property.toString())
    .set('parking_property', property.parking_property.toString())
    .set('description2_property', property.description2_property)
    .set('status_property', property.status_property.toString())
    .set('id_owner_property', property.id_owner_property.toString())
    .set('amenities_property', property.amenities_property)
    .set('noval_property', property.noval_property.toString());

    return this.http.put(`${this.url}/properties2?id=${id}&nameId=id_property&token=${this.userToken}&table=users&suffix=user`,payload,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  translateProperty(property: any, id: number){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    });
    
    const payload = new HttpParams()
    .set('description_en_property', property.description_en_property)
    .set('description_en_short_property', property.description_en_short_property)
    .set('description_en_prin_property', property.description_en_prin_property);

    return this.http.put(`${this.url}/properties2?id=${id}&nameId=id_property&token=${this.userToken}&table=users&suffix=user`,payload,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  deleteProperty( id: number ){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    return this.http.delete(`${this.url}/properties2?id=${id}&nameId=id_property&token=${this.userToken}&table=users&suffix=user`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )

  }

}
