<div class="container-fluid info">
    <div class="row justify-content-center">
        <div class="col-12 col-lg-5">
            <h1>Blog</h1>
        </div>
        <div class="col-12 col-lg-5 text-lg-end">
            <button mat-flat-button color="primary" routerLink="./post">Agregar Entrada</button>
            <button mat-flat-button color="primary" class="ms-2" (click)="categoryOpen()">Categorías</button>
        </div>
        <div class="col-12 col-lg-11">
            <hr>
        </div>
        <div class="col-12 col-lg-10">
            <div class="row mt-3">
                <div class="col-12 mt-3 post" *ngFor="let ps of posts | post:page">
                    <mat-card class="example-card">
                        <div class="row">
                          <div class="col-12 col-lg-1">
                            <img src="{{ps.image2_post}}" class="w-100" alt="imagen blog">
                          </div>
                          <div class="col-12 col-lg-8">
                            <mat-card-header>
                              <mat-card-title>{{ps.title_post}}</mat-card-title>
                              <mat-card-subtitle>{{ps.name_category}}</mat-card-subtitle>
                            </mat-card-header>
                          </div>
                          <div class="col-7 col-lg-2 align-self-center info-post">
                            <p *ngIf="ps.status_post == 0">Estado: <span class="orange">En Borrador</span></p>
                            <p *ngIf="ps.status_post == 1">Estado: <span class="green">Publicado</span></p>
                            <p>Edición: <span>{{ps.date_updated_post | day}}</span></p>
                            <p>Autor: <span>{{ps.name_user}}</span></p>
                          </div>
                          <div class="col-5 col-lg-1 align-self-center">
                            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                <mat-icon>edit_note</mat-icon>
                              </button>
                              <mat-menu #menu="matMenu">
                                <button mat-menu-item routerLink="post/{{ps.url_post}}">
                                  <mat-icon>edit</mat-icon>
                                  <span>Editar</span>
                                </button>
                                <button mat-menu-item (click)="deletePost(ps.id_post)">
                                  <mat-icon>delete</mat-icon>
                                  <span>Eliminar</span>
                                </button>
                              </mat-menu>
                          </div>
                        </div>
                    </mat-card>
                </div>
                <div class="col-11 text-center no-muestra-start" *ngIf="posts.length == 0">
                    <div class="no-muestra">
                        No hay Entradas que mostrar
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <div class="row justify-content-center pagins">
        <div class="col-12 col-lg-6 text-center" *ngIf="posts.length != 0">
            <button class="nmberPag me-2" (click)="previousPage()" [disabled]="pages2 == 1"><i class="fa-solid fa-angle-left"></i></button>

            <span class="nmberPag2 me-2">Página {{pages2}} de {{pages}}</span>

            <button class="nmberPag" (click)="nextPage()" [disabled]="pages == pages2"><i class="fa-solid fa-angle-right"></i></button>
        </div>
    </div>
</div>
