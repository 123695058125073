<div class="row" *ngIf="interest">
    <div class="col-12">
        <h4>Solicitud de información</h4>
    </div>
    <div class="col-12">
        <hr>
    </div>
    <div class="col-12 col-lg-6">
        <p><strong>Nombres :</strong> {{interest.name_interest | titlecase}}</p>
    </div>
    <div class="col-12 col-lg-6">
        <p><strong>Apellidos :</strong> {{interest.lastname_interest | titlecase}}</p>
    </div>
    <div class="col-12 col-lg-6">
        <p><strong>Email :</strong> {{interest.email_interest}}</p>
    </div>
    <div class="col-12 col-lg-6">
        <p><strong>Teléfono :</strong> {{interest.phone_interest | titlecase}}</p>
    </div>
    <div class="col-12">
        <hr>
    </div>
</div>
<div class="row" *ngIf="property">
    <div class="col-12">
        <h4>Propiedad</h4>
    </div>
    <div class="col-12 col-lg-6">
        <hr>

        <p><strong>Propiedad :</strong> {{property.description_property | titlecase}}</p>
        <p><strong>Sector :</strong> {{property.sector_property | titlecase}}</p>
        
    </div>
    <div class="col-6 d-none d-lg-block">
        <img src="{{property.image2_property}}" class="w-100" alt="">
    </div>
    <div class="col-12">
        <button mat-flat-button color="primary" (click)="markedMessage()" *ngIf="interest.status_interest == 1">Marcar como leído</button>
    </div>
</div>