import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AgmCoreModule } from '@agm/core';
import { EditMapPropertyComponent } from './admin-properties/edit-map-property/edit-map-property.component';
import { InterceptorService } from './shared/services/interceptor.service';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FooterModule } from './shared/footer/footer.module';
import { LoginModule } from './login/login.module';
import { AdminUsersModule } from './admin-users/admin-users.module';
import { AdminHomeModule } from './admin-home/admin-home.module';
import { AdminPropertiesModule } from './admin-properties/admin-properties.module';
import { AdminServicesModule } from './admin-services/admin-services.module';
import { PropertyModule } from './property/property.module';
import { HomeModule } from './home/home.module';
import { PropertiesModule } from './properties/properties.module';
import { AdminOwnersModule } from './admin-owners/admin-owners.module';
import { DialogPropertyModule } from './dialog-property/dialog-property.module';
import { AdminRequestModule } from './admin-request/admin-request.module';
import { AdminMessagesModule } from './admin-messages/admin-messages.module';
import { AboutModule } from './about/about.module';
import { JoinModule } from './join/join.module';
import { AdminBlogModule } from './admin-blog/admin-blog.module';
import { AdminFaqModule } from './admin-faq/admin-faq.module';

import { BlogModule } from './blog/blog.module';
import { PostModule } from './post/post.module';
import { Faq2Module } from './faq2/faq2.module';
import { SafeModule } from './safe/safe.module';
import { AdminSafeModule } from './admin-safe/admin-safe.module';

export function createTranslateLoader(http: HttpClient){
  return new TranslateHttpLoader(http, './assets/i18n/','.json');
}

@NgModule({
  entryComponents: [
    EditMapPropertyComponent
  ],
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MaterialModule,
    HomeModule,
    AboutModule,
    PropertyModule,
    PropertiesModule,
    NavbarModule,
    FooterModule,
    LoginModule,
    AdminUsersModule,
    AdminHomeModule,
    AdminPropertiesModule,
    AdminServicesModule,
    AdminOwnersModule,
    AdminRequestModule,
    AdminMessagesModule,
    AdminBlogModule,
    AdminFaqModule,
    AdminSafeModule,
    DialogPropertyModule,
    JoinModule,
    Faq2Module,
    BlogModule,
    PostModule,
    SafeModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBF87jmEwF8LzQxbcuUPZkuMWQ_kIFD6Pc'
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
