import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SafeComponent } from '../safe/safe.component';
import { ApiService } from '../shared/services/api.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq2.component.html',
  styleUrls: ['./faq2.component.css']
})
export class Faq2Component implements OnInit {

  public faqs: any[] = [];
  public errorMessage = '';
  public lang = 'es';

  constructor( private apiSer : ApiService,
               private dialog : MatDialog ) {
    apiSer.changeLang.subscribe(data => {
      this.lang = data.data
    })
  }

  ngOnInit(): void {
    this.readFaqs();

    if(localStorage.getItem('lang')){
      if(localStorage.getItem('lang') == 'es'){
        this.lang = 'es';
      }else{
        this.lang = 'en';
      }
    }
  }

  readFaqs(){
    this.apiSer.readAll('faqs').subscribe(resp => {
      if(resp.results != 'Not Found'){
        this.faqs = resp.results;
      }
    })
  }

  /*
  safe(){
    const dialogRef = this.dialog.open(SafeComponent, {
      width:'700px'
    })
  }
  */

}
