import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-map-property',
  templateUrl: './edit-map-property.component.html',
  styleUrls: ['./edit-map-property.component.css']
})
export class EditMapPropertyComponent implements OnInit {

  dataMarker!: FormGroup;

  constructor( public dialogRef: MatDialogRef<EditMapPropertyComponent>, @Inject(MAT_DIALOG_DATA) public data: any, 
  public fb: FormBuilder ) { 
    this.dataMarker = fb.group({
      title: data.title,
      desc: data.desc
    })
  }

  ngOnInit(): void {
  }

  saveChanges(){
    this.dialogRef.close( this.dataMarker.value );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
