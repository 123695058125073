<div class="container-fluid">
    <div class="row justify-content-center login">
      <div class="col-12 col-lg-4 mt-4 align-middle" *ngIf="parameter1 == undefined && reset == false">
        <form [formGroup]="sendCode" (ngSubmit)="codeSend()">
          <mat-card class="example-card text-center">
            <mat-card-title>Recuperar Contraseña</mat-card-title>
            <mat-card-subtitle>No olvide revisar si la recuperación llego a su bandeja de spam.</mat-card-subtitle>
            <mat-card-content>
  
              <div class="row justify-content-center">
                <div class="col-10">
             
                  <span *ngIf="emailPattern" class="text-danger"> Ingrese un correo correcto</span>
                  
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>correo</mat-label>
                    <input matInput 
                           formControlName="email"
                           [class.form-inv]="emailPattern">
                  </mat-form-field>
                </div>
              </div>
  
            </mat-card-content>
            <mat-card-actions>
              <button mat-raised-button color="primary" type="submit">Ingresar</button>
            </mat-card-actions>
          </mat-card>
        </form>
      </div>
      <div class="col-12 col-lg-4 mt-4 align-middle" *ngIf="parameter1 != undefined && reset == false">
        <form [formGroup]="sendReset" (ngSubmit)="resetSend()">
          <mat-card class="example-card text-center">
            <mat-card-title>Ingresar Código</mat-card-title>
            <mat-card-subtitle>Enviado a su correo electrónico.</mat-card-subtitle>
            <mat-card-content>
  
              <div class="row justify-content-center">
                <div class="col-10">
                  
                  <span *ngIf="codePattern" class="text-danger">No escriba caracteres especiales.</span>
                  
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Código</mat-label>
                    <input matInput 
                           formControlName="code"
                           [class.form-inv]="codePattern">
                  </mat-form-field>
                </div>
              </div>
  
            </mat-card-content>
            <mat-card-actions>
              <button mat-raised-button color="primary" type="submit">Ingresar</button>
            </mat-card-actions>
          </mat-card>
        </form>
      </div>

      <div class="col-12 col-lg-4 mt-4 align-middle" *ngIf="reset">
        <form [formGroup]="infoReset" (ngSubmit)="resetInfo()">
          <mat-card class="example-card text-center">
            <mat-card-title>Nueva Contraseña</mat-card-title>
            <mat-card-subtitle>Escriba su nueva contraseña</mat-card-subtitle>
            <mat-card-content>
  
              <div class="row justify-content-center">
                <div class="col-10">
              
                  <span *ngIf="passwordPattern" class="text-danger">No escriba caracteres especiales.</span>
            
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Nueva Contraseña</mat-label>
                    <input matInput 
                           formControlName="pass1"
                           [class.form-inv]="passwordPattern"
                           type="password" >
                  </mat-form-field>
                </div>
                <div class="col-10">
      
                  <span *ngIf="password2NoValido" class="text-danger"> Las contraseñas no coinciden.</span>

                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Repetir Contraseña</mat-label>
                    <input matInput 
                           formControlName="pass2"
                           [class.form-inv]="password2NoValido"
                           type="password">
                  </mat-form-field>
                </div>
              </div>
  
            </mat-card-content>
            <mat-card-actions>
              <button mat-raised-button color="primary" type="submit">Ingresar</button>
            </mat-card-actions>
          </mat-card>
        </form>
      </div>
      
    </div>
  </div>
  