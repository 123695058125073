export class SafeModel {

    id_safe?: number;
    title_safe: string;
    titlen_safe: string;
    content_safe: string;
    contenten_safe: string;
    links_safe: string;
    id_user_safe: number;
    image_safe: string;
    status_safe: number;
    date_created_safe: string;


    constructor(){
        this.title_safe = '',
        this.titlen_safe = '',
        this.content_safe = '',
        this.contenten_safe = '',
        this.links_safe = '',
        this.id_user_safe = 0,
        this.image_safe = '',
        this.status_safe = 0,
        this.date_created_safe = ''
    }
}