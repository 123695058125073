<div class="container-fluid about">
    <div class="row justify-content-center about-bg">
        <div class="col-12 col-lg-10">
            <div class="col-12 separator pdestacadas"></div>
            <div class="row">
                <div class="col-12 col-lg-6 title mt-5">
                    <h2>{{'ABOUT1'|translate}}</h2>
                </div>
            </div>
        </div>
        
        <div class="col-12 col-lg-9">
            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src="../../assets/img/about_c1.jpg" class="d-block w-100" alt="About Batista">
                    </div>
                    
                    <div class="carousel-item">
                        <img src="../../assets/img/about_c2.jpg" class="d-block w-100" alt="About Batista">
                    </div>
                        
                    <div class="carousel-item">
                        <img src="../../assets/img/about_c3.jpg" class="d-block w-100" alt="About Batista">
                    </div>
                    
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls,#carouselExampleControls2" aria-controls="#carouselExampleControls,#carouselExampleControls2" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
        <!--
        <div class="col-12 col-lg-9 text-end mt-5">
            <button class="btn1">{{'PROP7'|translate}}</button>
        </div>
        -->
        <div class="col-12 col-lg-9 barb">
            <p>{{'ABOUT3'|translate}}</p>
            <p>{{'ABOUT33'|translate}}</p>
            <p>{{'ABOUT34'|translate}}</p>
        </div>
        <div class="col-12 col-lg-9">
            <div class="row justify-content-end">
                <div class="col-12 col-lg-6 textB">
                    <h3>{{'ABOUT4'|translate}}</h3>
                </div>
            </div>
            <div class="row justify-content-center mt-3 rev">
                <div class="col-12 col-lg-6">
                    <p>{{'ABOUT44'|translate}}</p>
                        
                    <p>{{'ABOUT45'|translate}}</p>   
                </div>
                <div class="col-12 col-lg-6">
                    <p>{{'ABOUT46'|translate}}</p>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12 col-lg-6 why">
                    <div class="why1">
                        <h6>{{'ABOUT5'|translate}}</h6>
                        <p>{{'ABOUT6'|translate}}</p>
                    </div>
                </div>
                <div class="col-12 col-lg-3 why">
                    <div class="why2">
                        <img src="../../assets/img/barb1.jpg" class="w-100" alt="">
                    </div>
                </div>
                <div class="col-12 col-lg-3 why">
                    <div class="why1">
                        <h6>{{'ABOUT7'|translate}}</h6>
                        <p>{{'ABOUT71'|translate}}</p>
                    </div>
                </div>
                <div class="col-12 col-lg-6 why">
                    <div class="why1">
                        <h6>{{'ABOUT72'|translate}}</h6>
                        <p>{{'ABOUT73'|translate}}</p>
                    </div>
                </div>
                <div class="col-12 col-lg-6 why">
                    <div class="why2">
                        <img src="../../assets/img/barb2.jpg" class="w-100" alt="">
                        <div class="devs">
                            <h6>{{'ABOUT74'|translate}}</h6>
                            <p>{{'ABOUT75'|translate}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--
        <div class="col-12 p-0 noval2">
            <img src="../../assets/img/about3.jpg" class="w-100" alt="">
            
            <div class="col-9 col-lg-3 texto-noval">
                {{'ABOUT9'|translate}} 
            </div>
            
        </div>
        -->
    </div>
    <!--
    <div class="row">
        
        <div class="col-3 p-0 d-none d-lg-block">
            <img src="../../assets/img/about4.jpg" class="w-100" alt="">
        </div>
        <div class="col-12 col-lg-9">
            <div class="row">
                <div class="col-12 noval3 text-center text-lg-end">
                    <h3>{{'ABOUT10'|translate}}</h3>
                </div>
                <div class="col-12">
                    <div class="row about-img">
                        <div class="col-12 col-lg-4 text-center">
                            <img src="../../assets/img/about5.jpg" class="w-100 rounded-circle ekis" alt="">
                            <h4>{{'ABOUT11'|translate}}</h4>
                            <p>{{'ABOUT12'|translate}} <br> {{'ABOUT13'|translate}} <br> {{'ABOUT14'|translate}}</p>
                            
                        </div>
                        <div class="col-12 col-lg-4 text-center">
                            <img src="../../assets/img/about6.jpg" class="w-100 rounded-circle ekis" alt="">
                            <h4>{{'ABOUT15'|translate}}</h4>
                            <p>{{'ABOUT16'|translate}} <br> {{'ABOUT17'|translate}}</p>
                        </div>
                        <div class="col-12 col-lg-4 text-center">
                            <img src="../../assets/img/about7.jpg" class="w-100 rounded-circle ekis" alt="">
                            <h4>{{'ABOUT18'|translate}}</h4>
                            <p>{{'ABOUT19'|translate}} <br> {{'ABOUT20'|translate}} <br> {{'ABOUT21'|translate}} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    -->
    <div class="row justify-content-center punton">
        <!--
        <div class="col-12 col-lg-9">
            <div class="row">
                
                <div class="col-6 col-lg-4 p-4">
                    <img src="../../assets/img/about8.jpg" class="w-100" alt="">
                </div>
                <div class="col-6 col-lg-4 p-4">
                    <img src="../../assets/img/about9.jpg" class="w-100" alt="">
                </div>
                <div class="col-6 col-lg-4 p-4">
                    <img src="../../assets/img/about10.jpg" class="w-100" alt="">
                </div>
                <div class="col-6 col-lg-4 p-4">
                    <img src="../../assets/img/about11.jpg" class="w-100" alt="">
                </div>
                <div class="col-6 col-lg-4 p-4">
                    <img src="../../assets/img/about12.jpg" class="w-100" alt="">
                </div>
                <div class="col-6 col-lg-4 p-4">
                    <img src="../../assets/img/about13.jpg" class="w-100" alt="">
                </div>
                <div class="col-6 col-lg-4 p-4">
                    <img src="../../assets/img/about14.jpg" class="w-100" alt="">
                </div>
                <div class="col-6 col-lg-4 p-4">
                    <img src="../../assets/img/about15.jpg" class="w-100" alt="">
                </div>
                <div class="col-6 col-lg-4 p-4">
                    <img src="../../assets/img/about16.jpg" class="w-100" alt="">
                </div>
                
                
            </div>
        </div>
        -->
        <div class="col-12 col-lg-9 text-end p-4 rrss2">
            <span><a target="_blank" href="{{facebook}}"><i class="fa-brands fa-facebook-f me-4"></i></a></span>
            <span><a target="_blank" href="{{instagram}}"><i class="fa-brands fa-instagram"></i></a></span>
        </div>
    </div>
</div>
