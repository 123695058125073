import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-choose-amenities',
  templateUrl: './choose-amenities.component.html',
  styleUrls: ['./choose-amenities.component.css']
})
export class ChooseAmenitiesComponent implements OnInit {

  public amenities: any[] = [];
  public amenitiesList: any[] = [];

  constructor( private apiService: ApiService,
               public snackBar: MatSnackBar,
               public dialogRef: MatDialogRef<ChooseAmenitiesComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any ) { 
                
                if(data){
                  this.amenitiesList = data.data;
                }
               }

  ngOnInit(): void {
    this.readAmenities();
  }

  readAmenities(){
    this.apiService.readAll('amenities').subscribe((resp) => {
      this.amenities = resp.results;
    });
  }

  selectAmenity(ico: string, name: string){
    let seleccion = {
      name,
      ico
    }

    const pos = this.amenitiesList.map(e => e.name).indexOf(name);

    if(pos == -1){
      if(this.amenitiesList.length < 5){
        this.amenitiesList.push(seleccion);
      }else{
        this.snackBar.open('Máximo 5 amenidades', 'Cerrar', { duration: 3000 });
      }
    }else{
      this.snackBar.open('Esta amenidad ya esta seleccionada', 'Cerrar', { duration: 3000 });
    }
    
  }

  delAmenity(index: number){
    this.amenitiesList.splice(index,1);
    this.snackBar.open('Amenidad eliminada', 'Cerrar', { duration: 3000 });
  }

  sendAmenity(){
    this.dialogRef.close(this.amenitiesList);
  }

}
