import { Component, OnInit } from '@angular/core';
import { BlogService } from '../admin-blog/shared/services/blog.service';
import { ApiService } from '../shared/services/api.service';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  public posts: any[] = [];
  public page = 0;
  public pages = 0;
  public pages2 = 1;
  public errorMessage = '';
  public lang = 'es';

  constructor( private blogSer : BlogService,
               private apiSer : ApiService,
               private authSer : AuthService ) {
                apiSer.changeLang.subscribe(data => {
                  this.lang = data.data
                })
               }

  ngOnInit(): void {
    this.readPosts();

    if(localStorage.getItem('lang')){
      if(localStorage.getItem('lang') == 'es'){
        this.lang = 'es';
      }else{
        this.lang = 'en';
      }
    }

    this.authSer.pagesPost.subscribe( data => {
      this.pages = data.data
    })
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  readPosts(){
    this.blogSer.readPostRelations().subscribe(resp => {
      if(resp.results != 'Not Found'){
        this.posts = resp.results;
        this.btnPaginate();
        resp.results.forEach(resp2 => {
          let image = JSON.parse(resp2.image_post);
          resp2.image2_post = image.url
        })
      }
    })
  }

  nextPage() {
    this.page += 6
    this.pages2 += 1;
    this.scrollToTop();
  }

  previousPage() {
    if (this.page > 0) {
      this.page -= 6;
      this.pages2 -= 1;
      this.scrollToTop();
    }
  }

  btnPaginate() {
    let pag1 = this.posts.length / 6;
    this.pages = Math.ceil(pag1);
  }
}
