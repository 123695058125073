<div class="container-fluid">
  <div class="row justify-content-center addUser">
    <div class="col-12 col-lg-8 mb-4 mb-lg-0">
      <mat-card class="example-card">
        <mat-card-title>Agregar Usuario</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
          <form [formGroup]="userForm" (ngSubmit)="addUser()">
            <mat-horizontal-stepper #stepper>
              <mat-step>
                <ng-template matStepLabel>Datos de Acceso</ng-template>
                <div class="row mt-3">

                  <div class="col-12 col-lg-6">
                    <span *ngIf="existingEmail" class="text-danger"> Este email ya existe.</span>
                    <span *ngIf="emailPattern" class="text-danger"> Evite escribir caracteres especiales.</span>
                    <span *ngIf="emailRequired" class="text-danger"> Campo requerido.</span>
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Correo</mat-label>
                      <input matInput 
                            formControlName="email"
                            [class.form-inv]="existingEmail || emailPattern || emailRequired">
                    </mat-form-field>
                  </div>

                  <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                    <mat-slide-toggle formControlName="passQuest"> ¿Crear contraseña ahora?</mat-slide-toggle>
                  </div>

                  <div class="col-12 col-lg-6" *ngIf="passQuest">
                    <span *ngIf="passwordPattern" class="text-danger"> No escriba caracteres especiales.</span>
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Contraseña</mat-label>
                      <input matInput 
                            placeholder="password" 
                            type="password" 
                            formControlName="password"
                            [class.form-inv]="passwordPattern"
                            matTooltip="Mínimo 6 caracteres y esta permitido -@!#%._"
                            matTooltipClass="example-tooltip-red"
                            aria-label="Button that shows a red tooltip">
                    </mat-form-field>
                  </div>

                  <div class="col-12 col-lg-6" *ngIf="passQuest">
                    <span *ngIf="password2Pattern" class="text-danger"> No escriba caracteres especiales.</span>
                    <span *ngIf="password2NoValido" class="text-danger"> Las contraseñas no coinciden.</span>
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Repetir Contraseña</mat-label>
                      <input matInput 
                            placeholder="password" 
                            type="password" 
                            formControlName="password2"
                            [class.form-inv]="password2Pattern ||password2NoValido">
                    </mat-form-field>
                  </div>

                </div>

                <div>

                  <button type="button" mat-raised-button matStepperNext>Siguiente</button>
                </div>

              </mat-step>
              <mat-step>

                <ng-template matStepLabel>Información de Usuario</ng-template>

                <div class="row mt-3">

                  <div class="col-12 col-lg-4">
                    <span *ngIf="namePattern" class="text-danger"> No escriba caracteres especiales.</span>
                    <span *ngIf="nameRequired" class="text-danger"> Campo requerido.</span>
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Nombre</mat-label>
                      <input matInput 
                            formControlName="name"
                            [class.form-inv]="namePattern ||nameRequired">
                    </mat-form-field>
                  </div>

                  <div class="col-12 col-lg-4">
                    <span *ngIf="lastnamePattern" class="text-danger"> No escriba caracteres especiales.</span>
                    <span *ngIf="lastnameRequired" class="text-danger"> Campo requerido.</span>
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Apellidos</mat-label>
                      <input matInput 
                            formControlName="lastname"
                            [class.form-inv]="lastnamePattern ||lastnameRequired">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-4">
                    <span *ngIf="dniPattern" class="text-danger"> Rut no válido (Ej: 11.111.111-1).</span>
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>RUT</mat-label>
                      <input matInput 
                            formControlName="dni"
                            [class.form-inv]="dniPattern">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-4">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Privilegio</mat-label>
                      <mat-select formControlName="role">
                        <mat-option value="{{role.id_role}}" *ngFor="let role of roles">
                          {{role.name_role | titlecase}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-4">
                    <span *ngIf="positionPattern" class="text-danger"> No escriba caracteres especiales.</span>
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Cargo</mat-label>
                      <input matInput 
                            formControlName="position"
                            [class.form-inv]="positionPattern">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-4">
                    <span *ngIf="phonePattern" class="text-danger"> No escriba caracteres especiales.</span>
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Teléfono</mat-label>
                      <input matInput 
                            formControlName="phone"
                            [class.form-inv]="phonePattern">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-4">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>País</mat-label>
                      <mat-select formControlName="country">
                        <mat-option value="{{country.id_country}}" *ngFor="let country of countries">
                          {{country.namees_country | titlecase}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-4">
                    <span *ngIf="communePattern" class="text-danger"> No escriba caracteres especiales.</span>
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Comuna</mat-label>
                      <input matInput 
                            formControlName="commune"
                            [class.form-inv]="communePattern">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-lg-4">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Código Corredora</mat-label>
                      <input matInput formControlName="brokerCode" type="number">
                    </mat-form-field>
                  </div>
                  

                </div>


                <div>
                  <button type="button" mat-raised-button matStepperPrevious>Anterior</button>
                  <button type="button" mat-raised-button matStepperNext class="ms-2">Siguiente</button>
                </div>

              </mat-step>
              <mat-step>
                <ng-template matStepLabel>Imagen de Usuario</ng-template>

                <div class="row justify-content-center mt-3">
                  <div class="col-12 col-lg-4">
                    <img src="../../../../assets/img/no-image.webp" class="w-100" alt="" *ngIf="urlImage == undefined">
                    <img [src]="urlImage" class="w-100" *ngIf="urlImage">
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-12 col-lg-4 mb-4 mb-lg-0">
                    <button type="button" mat-raised-button color="primary" (click)="fileInput.click()" class="w-100">
                      <span><i class="fas fa-cloud-upload-alt mr-3"></i> Subir Imagen</span>
                      <input #fileInput type="file" (change)="onSelectFile($event)" style="display:none;"
                        formControlName="image" />
                    </button>
                  </div>
                </div>
                <div>
                  <button type="button" mat-raised-button matStepperPrevious>Anterior</button>
                  <button type="submit" mat-raised-button color="primary" class="ms-2">Guardar</button>
                </div>
              </mat-step>
            </mat-horizontal-stepper>
          </form>
          <!--
          <pre>
            Estado del Formulario: {{userForm.valid}}
            <br>
            status: {{ userForm.status }}
          </pre>
          <pre>
            {{ userForm.value | json }}
          </pre>
          -->
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
