export class AsesoraModel {

    id_adviser?: number;
    name_adviser: string;
    info_adviser: string;
    country_adviser?: string;
    city_adviser?: string;
    email_adviser: string;
    phone_adviser: string;
    ip_adviser: string;
    type_adviser: number;
    status_adviser: number;
    id_user_adviser: number;
    date_created_adviser: string;

    constructor(){
        this.name_adviser = '',
        this.info_adviser = '',
        this.country_adviser = '',
        this.city_adviser = '',
        this.email_adviser = '',
        this.phone_adviser = '',
        this.ip_adviser = '',
        this.type_adviser = 0,
        this.status_adviser = 0,
        this.id_user_adviser = 0,
        this.date_created_adviser = ''
    }
}