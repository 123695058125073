import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { UserModel } from 'src/app/shared/models/user';
import { ApiService } from 'src/app/shared/services/api.service';
import { MailService } from 'src/app/shared/services/mail.service';
import { ValidatorsService } from 'src/app/shared/services/validators.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.css'],
})
export class InviteUserComponent implements OnInit {
  inviteForm!: FormGroup;
  public user: UserModel = new UserModel();
  public roles: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<InviteUserComponent>,
    public fb: FormBuilder,
    public apiService: ApiService,
    public mailService: MailService,
    public validService: ValidatorsService
  ) {}

  ngOnInit(): void {
    this.readRoles();
    this.createForm();
  }

  get namePattern() {
    if (
      this.inviteForm.controls.name.errors &&
      this.inviteForm.controls.name.touched
    ) {
      if (this.inviteForm.controls.name.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get nameRequired() {
    if (
      this.inviteForm.controls.name.errors &&
      this.inviteForm.controls.name.touched
    ) {
      if (this.inviteForm.controls.name.errors.required) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get existingEmail() {
    if (
      this.inviteForm.controls.email.errors &&
      this.inviteForm.controls.email.touched
    ) {
      if (this.inviteForm.controls.email.errors.existEmail) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get emailPattern() {
    if (
      this.inviteForm.controls.email.errors &&
      this.inviteForm.controls.email.touched
    ) {
      if (this.inviteForm.controls.email.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get emailRequired() {
    if (
      this.inviteForm.controls.email.errors &&
      this.inviteForm.controls.email.touched
    ) {
      if (this.inviteForm.controls.email.errors.required) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  createForm() {
    this.inviteForm = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/
          ),
        ],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
        (control) => this.validService.existEmail(control),
      ],
      role: 0,
    });
  }

  readRoles() {
    this.apiService.readAll('roles').subscribe((resp) => {
      this.roles = resp.results;
    });
  }
  

  inviteUser() {
    if (this.inviteForm.invalid) {
      return Object.values(this.inviteForm.controls).forEach((control) => {
        control.markAllAsTouched();
      });
    }

    /** 
    function makeid(length) {
      var result = '';
      var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    }
    */

    let data = {
      name: this.inviteForm.controls.name.value,
      email: this.inviteForm.controls.email.value,
      password: '@Ingr3z!oO33kiLl3r',
      role: this.inviteForm.controls.role.value,
      code: this.mailService.makeCode(5),
      action: '1',
      exp: moment().add(1, 'h').format('x'),
    };

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor',
    });
    Swal.showLoading();

    this.apiService.inviteUser(data).subscribe((resp) => {
   
      let dataMail = [
        {
          name: this.inviteForm.controls.name.value,
          email: data.email,
          code_api: data.code,
          code: 5,
        },
      ];

      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'El usuario ha sido invitado',
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        this.dialogRef.close();
      });

      this.mailService.sendMail(dataMail).subscribe((resp2: any) => {

      });
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
