import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'property'
})
export class PropertyPipe implements PipeTransform {

  transform(value: any[], page: number = 0, operation: number, beds: number, parking: number, bath: number, values: number, dest1: boolean, divisa:number, meas:number): unknown {
    value.forEach(resp => {
      var divisa2 = 0;
      if(divisa != 0){
        divisa2 = divisa;
      }else{
        if(resp.id_country_property == 4){
          divisa2 = 3;
        }else{
          divisa2 = 1;
        }
      }

      if(meas == 2){
        let ground = Number(resp.ground_property);
        resp.ground2_property = Math.round(ground*10.7639);

        let ground2 = Number(resp.built_property);
        resp.built2_property = Math.round(ground2*10.7639);
      }
    
      let diva = JSON.parse(resp.value_property);
      let index = diva.map((div) => div.div).indexOf(divisa2.toString());
      resp.value2_property = diva[index].value;
      resp.value3_property = diva[index].code;
    })

    if(!dest1){
      if( operation == 0 && 
        beds == 0 &&
        parking == 0 &&
        bath == 0 &&
        values == 0){
        const filte = value.filter(prof => prof.status_property.includes(1));
        return filte.slice(page, page +6);
      }

      if(operation > 1){
        if(beds > 0){
          if(parking > 0){
            if(bath > 0){
              if(values > 0){
                const filteredProperties = value.filter( prope => prope.id_operation_property.includes( operation ) && prope.bedrooms_property >= beds && prope.parking_property >= parking && prope.bathing_property >= bath && prope.value_property >= values && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }else{
                const filteredProperties = value.filter( prope => prope.id_operation_property.includes( operation ) && prope.bedrooms_property >= beds && prope.parking_property >= parking && prope.bathing_property >= bath && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }
            }else{
              if(values > 0){
                const filteredProperties = value.filter( prope => prope.id_operation_property.includes( operation ) && prope.bedrooms_property >= beds && prope.parking_property >= parking && prope.value_property >= values && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }else{
                const filteredProperties = value.filter( prope => prope.id_operation_property.includes( operation ) && prope.bedrooms_property >= beds && prope.parking_property >= parking && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }
            }
          }else{
            if(bath > 0){
              if(values > 0){
                const filteredProperties = value.filter( prope => prope.id_operation_property.includes( operation ) && prope.bedrooms_property >= beds && prope.bathing_property >= bath && prope.value_property >= values && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }else{
                const filteredProperties = value.filter( prope => prope.id_operation_property.includes( operation ) && prope.bedrooms_property >= beds && prope.bathing_property >= bath && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }
            }else{
              if(values > 0){
                const filteredProperties = value.filter( prope => prope.id_operation_property.includes( operation ) && prope.bedrooms_property >= beds && prope.value_property >= values && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }else{
                const filteredProperties = value.filter( prope => prope.id_operation_property.includes( operation ) && prope.bedrooms_property >= beds && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }
            }
          }
        }else{
          if(parking > 0){
            if(bath > 0){
              if(values > 0){
                const filteredProperties = value.filter( prope => prope.id_operation_property.includes( operation ) && prope.parking_property >= parking && prope.bathing_property >= bath && prope.value_property >= values && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }else{
                const filteredProperties = value.filter( prope => prope.id_operation_property.includes( operation ) && prope.parking_property >= parking && prope.bathing_property >= bath && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }
            }else{
              if(values > 0){
                const filteredProperties = value.filter( prope => prope.id_operation_property.includes( operation ) && prope.parking_property >= parking && prope.value_property >= values && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }else{
                const filteredProperties = value.filter( prope => prope.id_operation_property.includes( operation ) && prope.parking_property >= parking && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }
            }
          }else{
            if(bath > 0){
              if(values > 0){
                const filteredProperties = value.filter( prope => prope.id_operation_property.includes( operation ) && prope.bathing_property >= bath && prope.value_property >= values && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }else{
                const filteredProperties = value.filter( prope => prope.id_operation_property.includes( operation ) && prope.bathing_property >= bath && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }
            }else{
              if(values > 0){
                const filteredProperties = value.filter( prope => prope.id_operation_property.includes( operation ) && prope.value_property >= values && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }else{
                const filteredProperties = value.filter( prope => prope.id_operation_property.includes( operation ) && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }
            }
          }
        }
      }else{
        if(beds > 0){
          if(parking > 0){
            if(bath > 0){
              if(values > 0){
                const filteredProperties = value.filter( prope => prope.bedrooms_property >= beds && prope.parking_property >= parking && prope.bathing_property >= bath && prope.value_property >= values && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }else{
                const filteredProperties = value.filter( prope => prope.bedrooms_property >= beds && prope.parking_property >= parking && prope.bathing_property >= bath && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }
            }else{
              if(values > 0){
                const filteredProperties = value.filter( prope => prope.bedrooms_property >= beds && prope.parking_property >= parking && prope.value_property >= values && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }else{
                const filteredProperties = value.filter( prope => prope.bedrooms_property >= beds && prope.parking_property >= parking && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }
            }
          }else{
            if(bath > 0){
              if(values > 0){
                const filteredProperties = value.filter( prope => prope.bedrooms_property >= beds && prope.bathing_property >= bath && prope.value_property >= values && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }else{
                const filteredProperties = value.filter( prope => prope.bedrooms_property >= beds && prope.bathing_property >= bath && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }
            }else{
              if(values > 0){
                const filteredProperties = value.filter( prope => prope.bedrooms_property >= beds && prope.value_property >= values && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }else{
                const filteredProperties = value.filter( prope => prope.bedrooms_property >= beds && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }
            }
          }
        }else{
          if(parking > 0){
            if(bath > 0){
              if(values > 0){
                const filteredProperties = value.filter( prope => prope.parking_property >= parking && prope.bathing_property >= bath && prope.value_property >= values && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }else{
                const filteredProperties = value.filter( prope => prope.parking_property >= parking && prope.bathing_property >= bath && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }
            }else{
              if(values > 0){
                const filteredProperties = value.filter( prope => prope.parking_property >= parking && prope.value_property >= values && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }else{
                const filteredProperties = value.filter( prope => prope.parking_property >= parking && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }
            }
          }else{
            if(bath > 0){
              if(values > 0){
                const filteredProperties = value.filter( prope => prope.bathing_property >= bath && prope.value_property >= values && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }else{
                const filteredProperties = value.filter( prope => prope.bathing_property >= bath && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }
            }else{
              if(values > 0){
                const filteredProperties = value.filter( prope => prope.value_property >= values && prope.status_property == 1);

                return filteredProperties.slice(page, page + 6);
              }else{
                return value.slice(page, page +6);
              }
            }
          }
        }
      }
    }else{
      var count = 0;
      value.forEach(resp => {
        if(count == 0){
          resp.active = 1;
        }else{
          resp.active = 0;
        }
        count += 1;
      })

      const filterd = value.filter( prod => prod.status_property.includes(1));
      return filterd.slice(page, page +3);
    }
    

    /*
    const filteredProperties = value.filter( prope => prope.id_operation_property.includes( operation ));
    */

    
    
    //return [];
  }

}
