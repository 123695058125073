import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { SafeModel } from 'src/app/shared/models/safe';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SafeService {

  private url = 'https://api.dosiscl.com';
  private userTokenApi = 'c5LTA6WPbMwHhEabYu77nN9cn4VcMj';
  private userToken = '';
  public data: any[] = [];

  constructor( private http: HttpClient,
               private authSer : AuthService ) {
                this.readToken();
                if( this.userToken != '' ){
                  this.data = Object.values( this.authSer.getDecodedAccessToken( this.userToken ) );
                }
               }

  readTokenDecoded(){
    return this.data;
  }

  readToken(){
    if (localStorage.getItem('token')) {
      this.userToken = localStorage.getItem('token') || '';
    } else {
      this.userToken = '';
    }

    return this.userToken;
  }

  publish( safe : SafeModel ){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Accept': '*/*',
      'Authorization':this.userTokenApi
    });

    const payload = new HttpParams()
    .set('title_safe', safe.title_safe)
    .set('titlen_safe', safe.titlen_safe)
    .set('content_safe', safe.content_safe)
    .set('contenten_safe', safe.contenten_safe)
    .set('links_safe', safe.links_safe)
    .set('id_user_safe', safe.id_user_safe.toString())
    .set('image_safe', safe.image_safe)
    .set('status_safe', safe.status_safe.toString())
    .set('date_created_safe', safe.date_created_safe);

    return this.http.post(`${this.url}/safes?token=${this.userToken}&table=users&suffix=user`, payload, {headers:headers})
    .pipe(
      map((resp:any) => {
        return resp;
      })
    )
  }

  searchSafe(search : string){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/safes?select=*&linkTo=id_safe&search=${search}`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  editSafe( safe : SafeModel, id : number){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    const payload = new HttpParams()
    .set('title_safe', safe.title_safe)
    .set('titlen_safe', safe.titlen_safe)
    .set('content_safe', safe.content_safe)
    .set('contenten_safe', safe.contenten_safe)
    .set('links_safe', safe.links_safe)
    .set('image_safe', safe.image_safe)
    .set('status_safe', safe.status_safe.toString());

    return this.http.put(`${this.url}/safes?id=${id}&nameId=id_safe&token=${this.userToken}&table=users&suffix=user`,payload,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  delete( id: number, table: string, suffix:string ){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    return this.http.delete(`${this.url}/${table}?id=${id}&nameId=id_${suffix}&token=${this.userToken}&table=users&suffix=user`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  readSafeRelations(){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/relations?rel=safes,users&type=safe,user&select=*&orderBy=id_safe&orderMode=DESC`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }
}
