import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import Swal from 'sweetalert2';
import { PropertiesModel } from '../shared/models/properties';
import { ApiService } from '../shared/services/api.service';
import { EditPropertyComponent } from './edit-property/edit-property.component';
import { PropertiesService } from './shared/services/properties.service';
import { TranslatePropertyComponent } from './translate-property/translate-property.component';

@Component({
  selector: 'app-admin-properties',
  templateUrl: './admin-properties.component.html',
  styleUrls: ['./admin-properties.component.css'],
})
export class AdminPropertiesComponent implements OnInit {
  public properties: any[] = [];
  public visits: any[] = [];
  public interested: any[] = [];
  public errorMessage: any = '';

  public pages = 0;
  public pages2 = 1;
  public page = 0;

  constructor(
    private propertyServ: PropertiesService,
    private apiService: ApiService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.readProperties();
  }

  nextPage() {
    this.page += 8;
    this.pages2 += 1;
  }

  previousPage() {
    if (this.page > 0) {
      this.page -= 8;
      this.pages2 -= 1;
    }
  }

  btnPaginate() {
    let pag1 = this.properties.length / 6;
    this.pages = Math.ceil(pag1);
  }

  readProperties() {
    this.propertyServ.readAllRelations().subscribe((resp) => {
      this.apiService.readAll('visits').subscribe((resp1) => {
        if(resp1.results != 'Not Found'){
          this.visits = resp1.results;
        }

        this.apiService.readAll('interested').subscribe((resp2) => {
          if (resp.results != 'Not Found') {
            
            if(resp2.results != 'Not Found'){
              this.interested = resp2.results;
            }
            
            resp.results.forEach((resp3) => {
              switch (resp3.status_property) {
                case '0':
                  resp3.status2_property = 'En Borrador';
                  resp3.status3_property = 'yellow';
                  break;
                case '1':
                  resp3.status2_property = 'Publicada';
                  resp3.status3_property = 'green';
                  break;
                case '2':
                  resp3.status2_property = 'Vendida';
                  resp3.status3_property = 'lightblue';
                  break;
                case '3':
                  resp3.status2_property = 'Arrendada';
                  resp3.status3_property = 'blue';
                  break;
                case '4':
                  resp3.status2_property = 'Bajada';
                  resp3.status3_property = 'red';
                  break;
              }

              if (resp3.image_property != '[]') {
                let proper = JSON.parse(resp3.image_property);
                //resp3.image_property = proper[0];
                resp3.image2_property = proper[0].url;
                resp3.photos_property = proper.length;
              } else {
                resp3.image2_property =
                  'https://api.dosiscl.com/uploads/no-image.png';
                resp3.photos_property = 0;
              }

              this.btnPaginate();

              if(this.visits.length > 0){
                const filtered = this.visits.filter((req) =>
                  req.id_property_visit.includes(resp3.id_property)
                );

                resp3.visits_property = filtered.length;
              }else{
                resp3.visits_property = 0;
              }
              
              if(this.interested.length > 0){
                const filtered2 = this.interested.filter((req) =>
                  req.id_property_interest.includes(resp3.id_property)
                );

                resp3.message_property = filtered2.length;
              }else{
                resp3.message_property = 0;
              }
              
            });
          }
        });
      });

      if (resp.results != 'Not Found') {
        this.properties = resp.results;
      } else {
        this.properties = [];
      }
    });
  }

  editProperty(property: PropertiesModel) {
    const dialogRef = this.dialog.open(EditPropertyComponent, {
      width: '800px',
      data: {
        id: property.id_property,
        operation: property.id_operation_property,
        estate: property.id_estate_property,
        description: property.description_property,
        rol: property.rol_property,
        value: property.value_property,
        payment: property.id_payment_property,
        highlight: property.highlight_property,
        subsidy: property.subsidy_property,
        exchange: property.exchange_property,
        desc_short: property.description_short_property,
        desc_prin: property.description_prin_property,
        url: property.url_property,
        country: property.id_country_property,
        region: property.id_region_property,
        commune: property.id_commune_property,
        sector: property.sector_property,
        geography: property.id_geography_property,
        map: property.map_property,
        markers: property.markers_property,
        ground: property.ground_property,
        built: property.built_property,
        bedrooms: property.bedrooms_property,
        bathing: property.bathing_property,
        parking: property.parking_property,
        description2: property.description2_property,
        owner: property.id_owner_property,
        noval: property.noval_property,
        amenities: property.amenities_property,
        image: property.image_property,
        status: property.status_property,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.readProperties();
    });
  }

  deleteProperty(id: any) {
    Swal.fire({
      title: 'Estas seguro que deseas eliminar?',
      text: 'Si eliminas no recuperarás esta propiedad!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.propertyServ.deleteProperty(id).subscribe(
          (resp) => {
            Swal.close();

            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'La propiedad ha sido eliminada',
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              this.readProperties();
            });
          },
          (error) => {
            this.errorMessage = error;

            if (this.errorMessage != null) {
              Swal.fire({
                title: 'error',
                icon: 'error',
                text: error.error.message,
              });
            }
          }
        );
      }
    });
  }

  translateProperty(property: PropertiesModel) {
    const dialogRef = this.dialog.open(TranslatePropertyComponent, {
      width: '700px',
      data: {
        id: property.id_property,
        short: property.description_en_short_property,
        prin: property.description_en_prin_property,
        title: property.description_en_property,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.readProperties();
    });
  }
}
