import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlogComponent } from './blog.component';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from '../app-routing.module';
import { MaterialModule } from '../material.module';
import { PostfPipe } from '../shared/filters/postf.pipe';



@NgModule({
  declarations: [BlogComponent, PostfPipe],
  imports: [
    CommonModule,
    TranslateModule,
    AppRoutingModule,
    MaterialModule
  ],
  exports: [BlogComponent]
})
export class BlogModule { }
