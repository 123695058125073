<div class="container-fluid info">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-5">
      <h1>Preguntas Frecuentes</h1>
    </div>
    <div class="col-12 col-lg-5 text-lg-end">
      <button mat-flat-button color="primary" routerLink="./content">Agregar Contenido</button>
    </div>
    <div class="col-12 col-lg-11">
      <hr>
    </div>
    <div class="col-12 col-lg-10">
      <div class="row mt-3">
        <mat-accordion>
          <mat-expansion-panel *ngFor="let faq of faqs">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{faq.quest_faq}}
              </mat-panel-title>
              <mat-panel-description>
                
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div [innerHTML]="faq.answ_faq"></div>
            <div class="actions mt-4">
                <button type="button" mat-stroked-button color="primary" class="me-2" routerLink="content/{{faq.id_faq}}"><i class="fa-regular fa-pen-to-square me-2"></i>EDITAR</button>
                <button type="button" mat-stroked-button color="warn" (click)="delFaq(faq.id_faq)"><i class="fa-regular fa-trash-can me-2"></i>ELIMINAR</button>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div class="col-12 col-lg-10 text-center mt-3" *ngIf="faqs.length == 0">
        <div class="no-muestra">
            No hay preguntas que mostrar
        </div>
    </div>
  </div>
</div>
