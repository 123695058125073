<div class="row">
    <div class="col-12 col-lg-6">
        <b>{{data.owner.name_owner | titlecase}} {{data.owner.lastname_owner | titlecase}}</b>
        <span class="flag"><img src="../../../assets/img/flags/chile.png" class="ms-2" alt=""></span>
    </div>
    <div class="col-12 col-lg-6 text-lg-end">
        {{data.owner.email_owner}}
    </div>
    <div class="col-12">
        <hr>
    </div>
    <div class="col-12 col-lg-8">
        <i class="fa-solid fa-location-dot me-2"></i> 
        {{data.owner.address_owner | titlecase}}
    </div>
    <div class="col-12 col-lg-4 text-lg-end">
        <i class="fa-solid fa-phone me-2"></i> 
        {{data.owner.phone_owner}}
    </div>
    <div class="col-6 mt-4 title-profile">
        PROPIEDADES
    </div>
    <div class="col-6 mt-4 title-profile text-end">
        3 Propiedades
    </div>
    <!--
    <div class="col-12">
        <div class="row mt-2 carabinerus">
            <div class="col-2">
                <img src="../../../assets/img/nn.jpg" class="w-100" alt="">
            </div>
            <div class="col-10 align-self-center">
                <h6>Proyecto terrenas ecologico</h6>
            </div>
        </div>
    </div>
    -->
</div>