<div class="container-fluid info">
    <div class="row justify-content-center">
        <div class="col-12 col-lg-5">
            <h1>Invierte Seguro</h1>
        </div>
        <div class="col-12 col-lg-5 text-lg-end">
            <button mat-flat-button color="primary" routerLink="./content">Agregar</button>
        </div>
        <div class="col-12 col-lg-11">
            <hr>
        </div>
        <div class="col-12 col-lg-10">
            <div class="row mt-3">
                <div class="col-12 mt-3 safes" *ngFor="let sf of safes | safe:page">
                    <mat-card class="example-card">
                        <div class="row">
                            <div class="col-12 col-lg-1">
                                <img src="{{sf.image2_safe}}" class="w-100" alt="image safe">
                            </div>
                            <div class="col-12 col-lg-8">
                                <mat-card-header>
                                    <mat-card-title>{{sf.title_safe}}</mat-card-title>
                                </mat-card-header>
                            </div>
                            <div class="col-7 col-lg-2 align-self-center info-safes">
                                <p *ngIf="sf.status_safe == 0">Estado: <span class="orange">En Borrador</span></p>
                            <p *ngIf="sf.status_safe == 1">Estado: <span class="green">Publicado</span></p>
                            <p>Edición: <span>{{sf.date_updated_safe | daysafe}}</span></p>
                            <p>Autor: <span>{{sf.name_user}}</span></p>
                            </div>
                            <div class="col-5 col-lg-1 align-self-center">
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                    <mat-icon>edit_note</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item routerLink="content/{{sf.id_safe}}">
                                        <mat-icon>edit</mat-icon>
                                        <span>Editar</span>
                                    </button>
                                    <button mat-menu-item (click)="deleteSafe(sf.id_safe)">
                                        <mat-icon>delete</mat-icon>
                                        <span>Eliminar</span>
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center pagins">
        <div class="col-12 col-lg-6 text-center" *ngIf="safes.length != 0">
            <button class="nmberPag me-2" (click)="previousPage()" [disabled]="pages2 == 1"><i class="fa-solid fa-angle-left"></i></button>

            <span class="nmberPag2 me-2">Página {{pages2}} de {{pages}}</span>

            <button class="nmberPag" (click)="nextPage()" [disabled]="pages == pages2"><i class="fa-solid fa-angle-right"></i></button>
        </div>
    </div>
</div>