import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'user'
})
export class UserPipe implements PipeTransform {

  transform(value: any[], search: string, role: number): unknown {

    if(!search && !role){
      return value;
    }

    if(search && !role){
      const filteredProperties = value.filter( pers => pers.email_user.includes( search ));

      return filteredProperties;
    }

    if(role && !search){
      const filteredProperties = value.filter( pers => pers.id_role_user.includes( role ));

      return filteredProperties;
    }

    if(search && role){
      const filteredProperties0 = value.filter( pers => pers.email_user.includes( search ));
      const filteredProperties = filteredProperties0.filter( pers => pers.id_role_user.includes( role ));

      return filteredProperties;
    }

    

  }

}
