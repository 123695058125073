import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminUsersComponent } from './admin-users.component';
import { ProfileUserComponent } from './profile-user/profile-user.component';
import { AddUserComponent } from './add-user/add-user.component';
import { FilterUsersPipe } from './shared/filters/filter-users.pipe';
import { MaterialModule } from '../material.module';
import { InviteUserComponent } from './invite-user/invite-user.component';
import { RegisterUserComponent } from './register-user/register-user.component';
import { ResetPassUserComponent } from './reset-pass-user/reset-pass-user.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { EditUserComponent } from './edit-user/edit-user.component';
import { UserPipe } from '../shared/filters/user.pipe';



@NgModule({
  declarations: [AdminUsersComponent, ProfileUserComponent, AddUserComponent, FilterUsersPipe, InviteUserComponent, RegisterUserComponent, ResetPassUserComponent, EditUserComponent, UserPipe],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserModule
  ],
  exports: [
    AdminUsersComponent, ProfileUserComponent, AddUserComponent, FilterUsersPipe, InviteUserComponent, RegisterUserComponent, ResetPassUserComponent, EditUserComponent
  ]
})
export class AdminUsersModule { }
