import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { OwnerModel } from '../shared/models/owner';
import { ApiService } from '../shared/services/api.service';
import { AddOwnerComponent } from './add-owner/add-owner.component';
import { EditOwnerComponent } from './edit-owner/edit-owner.component';
import { ProfileComponent } from './profile/profile.component';
import { OwnersService } from './shared/services/owners.service';

@Component({
  selector: 'app-admin-owners',
  templateUrl: './admin-owners.component.html',
  styleUrls: ['./admin-owners.component.css'],
})
export class AdminOwnersComponent implements OnInit {
  public owners: any[] = [];
  public properties: any[] = [];
  public errorMessage = '';

  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    private ownerService: OwnersService
  ) {}

  ngOnInit(): void {
    this.readOwners();
    this.readProperties();
  }

  readOwners() {
    this.apiService.readAll('owners').subscribe((resp) => {
      this.owners = resp.results;
    });
  }

  readProperties() {
    this.apiService.readAll('properties2').subscribe((resp) => {
      if(resp.results != 'Not Found'){
        this.properties = resp.results;
      }
    });
  }

  editOwner(owner: OwnerModel) {
    const dialogRef = this.dialog.open(EditOwnerComponent, {
      width: '700px',
      data: {
        id: owner.id_owner,
        name: owner.name_owner,
        lastname: owner.lastname_owner,
        dni: owner.dni_owner,
        email: owner.email_owner,
        phone: owner.phone_owner,
        address: owner.address_owner,
        obs: owner.obs_owner,
        country: owner.id_country_owner,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.readOwners();
    });
  }

  profileOwner(owner: OwnerModel) {
    const dialogRef = this.dialog.open(ProfileComponent, {
      width: '700px',
      data: {
        owner
      }
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  delOwner(id: any) {
    Swal.fire({
      title: 'Estas seguro que deseas eliminar?',
      text: 'Si eliminas no recuperarás este propietario!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          allowOutsideClick: false,
          icon: 'info',
          text: 'Eliminando',
        });
        Swal.showLoading();
        
        if (id != 1) {
          this.ownerService.readPropertiesId(id).subscribe((resp) => {
            if(resp.results != 'Not Found'){
              let properties = resp.results;

              this.ownerService.intoEdit(properties).then((real) => {
                this.ownerService.deleteOwners(id).subscribe(
                  (resp) => {
                    Swal.close();

                    Swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: 'El propietario ha sido eliminado',
                      showConfirmButton: false,
                      timer: 1500,
                    }).then(() => {
                      this.readOwners();
                    });
                  },
                  (error) => {
                    this.errorMessage = error;

                    if (this.errorMessage != null) {
                      Swal.fire({
                        title: 'error',
                        icon: 'error',
                        text: error.error.message,
                      });
                    }
                  }
                );
              });
            }else{
              this.ownerService.deleteOwners(id).subscribe(
                (resp) => {
                  Swal.close();

                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'El propietario ha sido eliminado',
                    showConfirmButton: false,
                    timer: 1500,
                  }).then(() => {
                    this.readOwners();
                  });
                },
                (error) => {
                  this.errorMessage = error;

                  if (this.errorMessage != null) {
                    Swal.fire({
                      title: 'error',
                      icon: 'error',
                      text: error.error.message,
                    });
                  }
                }
              );
            }
          });
        } else {
          Swal.close();

          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Este propietario no se puede eliminar',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    });
  }

  addOwner() {
    const dialogRef = this.dialog.open(AddOwnerComponent, {
      width: '700px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.readOwners();
    });
  }
}
