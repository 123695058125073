<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-12">
      <h3>Traductor Propiedad</h3>
    </div>
    <div class="col-12">
      <form [formGroup]="transForm" (ngSubmit)="translateProperty()">
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Título</mat-label>
                <input matInput formControlName="title">
            </mat-form-field>
          </div>
          <div class="col-12 mt-2">
            <label for="">Descripción Corta</label>
            <ckeditor [editor]="Editor" data="" formControlName="short"></ckeditor>
          </div>
          <div class="col-12 mt-2">
            <label for="">Descripción Principal</label>
            <ckeditor [editor]="Editor" data="" formControlName="prin"></ckeditor>
          </div>
          <div class="col-5">
            <button type="submit" mat-raised-button color="primary">Guardar</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
