import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'visits'
})
export class VisitsPipe implements PipeTransform {

  transform(value: any[]): unknown {

    if(!value){
      return null;
    }

    let propet = [];

    value.forEach((resp: any) => {

      let index = propet.map((prop) => prop.id_property).indexOf(resp.id_property);

      if(index >= 0){
        if(propet[index].ip_visit != resp.ip_visit){
          propet[index].visits += 1;
        }
      }else{
        resp.visits = 1;
        propet.push(resp);
      }

    })

    let vise = propet.sort(((a, b) => b.visits - a.visits));
    return vise.slice(0, 4);
    
  }

}
