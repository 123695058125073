<form [formGroup]="ownerForm" (ngSubmit)="editOwner()">
    <div class="row">
        <div class="col-12 col-lg-6">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Nombre</mat-label>
                <input matInput 
                        placeholder="Ej: Juan"
                        formControlName="name">
            </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Apellidos</mat-label>
                <input matInput
                        formControlName="lastname">
            </mat-form-field>
        </div>
        <div class="col-12">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Email</mat-label>
                <input matInput
                        formControlName="email">
            </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>RUT</mat-label>
                <input matInput
                        formControlName="dni">
            </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>País</mat-label>
                <mat-select formControlName="country">
                  <mat-option value="{{country.id_country}}" *ngFor="let country of countries">
                    {{country.namees_country}}
                  </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Teléfono</mat-label>
                <input matInput
                        formControlName="phone">
            </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Dirección</mat-label>
                <input matInput
                        formControlName="address">
            </mat-form-field>
        </div>
        <div class="col-12">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Observaciones</mat-label>
                <input matInput
                        formControlName="obs">
            </mat-form-field>
        </div>
        <div class="col-6 col-lg-4">
            <button type="submit" mat-raised-button color="primary" class="mt-2 w-100">Editar</button>
        </div>
        <div class="col-6 col-lg-4">
            <button type="button" mat-raised-button color="warn" class="mt-2 w-100" (click)="onNoClick()">Cancelar</button>
        </div>
    </div>
</form>
