<mat-card class="example-card">
    <mat-card-header>
      <div mat-card-avatar class="example-header-image" [ngStyle]="{'background-image': 'url(' + data.user.image_user + ')'}"></div>
      <mat-card-title>{{data.user.name_user}} {{data.user.lastname_user}}</mat-card-title>
      <mat-card-subtitle>{{data.user.email_user}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="row justify-content-center profile">
        <div class="col-12">
            <hr>
        </div>
        <div class="col-6 col-lg-4">
            <span><strong>DNI: </strong></span>
            <span>{{data.user.dni_user}}</span>
        </div>
        <div class="col-6 col-lg-4">
            <span><strong>CARGO: </strong></span>
            <span>{{data.user.position_user}}</span>
        </div>
        <div class="col-6 col-lg-4 mt-3 mt-lg-0">
            <span><strong>PRIVILEGIO: </strong></span>
            <span>{{data.user.name_role | titlecase}}</span>
        </div>
        <div class="col-6 col-lg-4 mt-3">
            <span><strong>TELÉFONO: </strong></span>
            <span>{{data.user.phone_user}}</span>
        </div>
        <div class="col-6 col-lg-4 mt-3">
            <span><strong>PAÍS: </strong></span>
            <span>{{data.user.namees_country | titlecase}}</span>
        </div>
        <div class="col-6 col-lg-4 mt-3">
            <span><strong>CREADO: </strong></span>
            <span>{{data.user.date_created_user}}</span>
        </div>
      </div>
    </mat-card-content>
    <!--
    <mat-card-actions>
      <button mat-button>LIKE</button>
      <button mat-button>SHARE</button>
    </mat-card-actions>
    -->
  </mat-card>