<div class="container">
    <div class="row justify-content-center" *ngIf="property">
        <div class="col-12 col-lg-9 align-self-center titInter">
            <h5>¡Me interesa esta propiedad!</h5>
            <p>{{property.description_property}}</p>
        </div>
        <div class="col-3 imgInter d-none d-lg-block">
            <img src="{{property.image2_property}}" alt="">
        </div>
    </div>
    <form [formGroup]="interForm" (ngSubmit)="sendInterest()">  
        <div class="row justify-content-center">
            <div class="col-12">
                <hr>
            </div>
            <div class="col-12 mt-2">
                <p>Déjanos tus datos para enviarte más información sobre esta propiedad.</p>
            </div>
            <div class="col-12 col-lg-6">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Nombre</mat-label>
                    <input matInput formControlName="name">
                </mat-form-field>
            </div>
            <div class="col-12 col-lg-6">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Apellidos</mat-label>
                    <input matInput formControlName="lastname">
                </mat-form-field>
            </div>
            <div class="col-12 col-lg-6">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                </mat-form-field>
            </div>
            <div class="col-12 col-lg-6">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Teléfono</mat-label>
                    <input matInput formControlName="phone">
                </mat-form-field>
            </div>
            <div class="col-12 col-lg-6">
                <button type="submit" mat-raised-button color="primary" class="w-100">Obtener más información</button>
            </div>
        </div>
    </form>
</div>