import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { DialogPropertyComponent } from '../dialog-property/dialog-property.component';
import { ApiService } from '../shared/services/api.service';
import { AuthService } from '../shared/services/auth.service';
import { IpService } from '../shared/services/ip.service';
import { Markers } from '../admin-properties/shared/classes/markers.class';

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.css']
})
export class PropertyComponent implements OnInit {

  lat = 18.58182;
  lng = -68.40431;

  public markers: Markers[] = [];
  public parametro = '';
  public property: any[] = [];
  public images: any[] = [];
  public amenities: any[] = [];
  public url = '';
  public divisa = 0;
  public ipAddress = '';
  public lang = 'es';

  public instagram = '';
  public facebook = '';
  public whatsapp = '';

  public meas = 1;
  public mapp = 0;

  constructor( private apiService: ApiService,
               private ip: IpService,
               private authService: AuthService,
               private _activatedRoute: ActivatedRoute,
               private dialog : MatDialog ) { 

                if(localStorage.getItem('meas')){
                  this.meas = Number(localStorage.getItem('meas'));
                }

                this.divisa = authService.readCurrency();

                authService.changeCurrency.subscribe(data => {
                  if(data.data != this.divisa){
                    this.divisa = data.data;
                    this.property = [];
                    authService.changeCurrencyS(this.divisa);
                    this.readProperty();
                  }
                })

                apiService.changeLang.subscribe(data => {
                  this.lang = data.data
                })
              
              }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe((params: Params) =>{
      this.parametro = params['parametro'];
    })

    if(localStorage.getItem('lang')){
      if(localStorage.getItem('lang') == 'es'){
        this.lang = 'es';
      }else{
        this.lang = 'en';
      }
    }
    this.readSocial();
    this.readProperty();
    
  }

  changeMeas(code:number){
    this.meas = code;
    localStorage.setItem('meas',code.toString());

    if(code == 2){
      let ground = Number(this.property['ground_property']);
      this.property['ground2_property'] = Math.round(ground*10.7639);

      let ground2 = Number(this.property['built_property']);
      this.property['built2_property'] = Math.round(ground2*10.7639);
    }
  }

  readProperty(){
    this.apiService.read('properties2','url_property', this.parametro).subscribe(resp => {

      this.property = resp.results[0];
      /*
      let image = JSON.parse(this.property['image_property']);
      this.property['image2_property'] = image[0].url;
      */

      this.amenities = JSON.parse(this.property['amenities_property']);

      if(this.property['image_property'] != '[]'){
        this.images = JSON.parse(this.property['image_property']);
        this.property['image2_property'] = this.images[0].url;
      }else{
        this.property['image2_property'] = 'https://firebasestorage.googleapis.com/v0/b/batista-images.appspot.com/o/properties2%2Fnn.jpg?alt=media&token=1289b9b8-8b39-4c20-84c1-e779ac4870f3';
      }

      let divisa2 = 0;
      if(this.divisa != 0){
        divisa2 = this.divisa;
      }else{
        if(this.property['id_country_property'] == 4){
          divisa2 = 3;
        }else{
          divisa2 = 1;
        }
      }

      this.mapp = this.property['map_property'];
      console.log(this.mapp)

      this.markers = JSON.parse(this.property['markers_property']);
      
      let valor = JSON.parse(this.property['value_property']);
      let index = valor.map((div)=>div.div).indexOf(divisa2.toString());
      this.property['value2_property'] = valor[index].value;
      this.property['value3_property'] = valor[index].code;
      this.getIP();

      this.readCom(this.property['id_commune_property']);
    })
  }

  readCom(commune : string){
    console.log(commune);
    this.apiService.readAll('communes').subscribe(resp => {
      const filterss = resp.results.filter(com => com.id_commune == commune);
      this.lat = parseFloat(filterss[0]['lat_commune']);
      this.lng = parseFloat(filterss[0]['lng_commune']);
      console.log(this.lat);
    })
  }

  readSocial(){
    this.apiService.readAll('redes').subscribe(resp =>{
      if(resp.results != 'Not Found'){
        resp.results.forEach(resp2 => {
          switch (resp2.name_red) {
            case 'instagram':
              this.instagram = resp2.url_red;  
              break;

            case 'facebook':
              this.facebook = resp2.url_red; 
              break;

            case 'whatsapp':
              this.whatsapp = resp2.url_red; 
              break;
          }
        })
      }
    })
  }

  getIP()  {  
    this.ip.getIPAddress().subscribe((res:any)=>{  
      this.ipAddress = res.ip;  
      const dat = moment();
      let data = [{
        ip: this.ipAddress,
        property: this.property['id_property'],
        date: dat.format('YYYY-MM-DD')
      }]
      this.apiService.visitProperty(data).subscribe(resp2 => {
        
      })
    });  
  }  

  interested(id:number){
    const dialogRef = this.dialog.open(DialogPropertyComponent, {
      width:'700px',
      data: {
        id
      }
    })
  }

}
