import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Pipe({
  name: 'postf'
})
export class PostfPipe implements PipeTransform {

  constructor(private authSer: AuthService){}

  transform(value: any[], page: number = 0): unknown {
    if(!value){
      return null;
    }
  
    const filteredPost = value.filter(stato => stato.status_post.includes(1));

    let pag1 = filteredPost.length / 9;
    let pages = Math.ceil(pag1);

    this.authSer.pagesPost.emit({
      data: pages
    })

    return filteredPost.slice(page, page +6);
  }

}
