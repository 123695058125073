import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { DialogPropertyComponent } from '../dialog-property/dialog-property.component';

import { ApiService } from '../shared/services/api.service';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.css']
})
export class PropertiesComponent implements OnInit {

  public filters!: FormGroup;
  public parametro = '';
  public properties: any[] = [];
  public properties2: any[] = [];
  public divisa = 0;
  public lang = 'es';
  public locald = 'cl';

  public pages = 0;
  public pages2 = 1;
  public page = 0;
  public length = 0;
  public operation = 0;
  public operation2: boolean = false;
  public beds = 0;
  public parking = 0;
  public bath = 0;
  public values = 0;
  public topDest = 0;
  public dest1: boolean = true;

  public instagram = '';
  public facebook = '';
  public whatsapp = '';

  public meas = 1;

  constructor( private _activatedRoute: ActivatedRoute,
               private apiService: ApiService,
               private authService: AuthService,
               private fb: FormBuilder,
               private dialog: MatDialog ) { 

                if(localStorage.getItem('meas')){
                  this.meas = Number(localStorage.getItem('meas'));
                }

                this.divisa = authService.readCurrency();

                authService.changeCurrency.subscribe(data => {
                  if(data.data != this.divisa){
                    this.divisa = data.data;
                    this.properties = [];
                    authService.changeCurrencyS(this.divisa);
                    this.readProperties();
                  }
                })
                
                apiService.changeLang.subscribe(data => {
                  this.lang = data.data
                })
              }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe((params:Params) => {
      this.changeParameter(params['parametro']);
    })

    this.authService.clickCountry.subscribe( data => {
      this.changeParameter(data.data);
      
    })
    this.readSocial();
    this.readProperties();
    this.createForm();
    this.listen();
    this.captureToUp();
  }

  readSocial(){
    this.apiService.readAll('redes').subscribe(resp =>{
      if(resp.results != 'Not Found'){
        resp.results.forEach(resp2 => {
          switch (resp2.name_red) {
            case 'instagram':
              this.instagram = resp2.url_red;  
              break;

            case 'facebook':
              this.facebook = resp2.url_red; 
              break;

            case 'whatsapp':
              this.whatsapp = resp2.url_red; 
              break;
          }
        })
      }
    })
  }


  createForm(){
    this.filters = this.fb.group({
      operation: [''],
      beds: [''],
      parking: [''],
      bath: [''],
      values: ['']
    })
  }

  listen(){
    this.filters.controls.operation.valueChanges.subscribe((valor) => {
      this.page = 0;
      this.operation = valor;
    })

    this.filters.controls.beds.valueChanges.subscribe((valor2) => {
      this.page = 0;
      this.beds = valor2;
    })

    this.filters.controls.parking.valueChanges.subscribe((valor3) => {
      this.page = 0;
      this.parking = valor3;
    })

    this.filters.controls.bath.valueChanges.subscribe((valor4) => {
      this.page = 0;
      this.bath = valor4;
    })

    this.filters.controls.values.valueChanges.subscribe((valor4) => {
      this.page = 0;
      this.values = valor4;
    })
  }

  ope(operation:number){
    this.operation = operation;
    switch (operation) {
      case 5:
        this.operation2 = true;
        break;

      case 8:
        this.operation2 = false;
        break;
    }
  }

  changeMeas(code:number){
    this.meas = code;
    localStorage.setItem('meas',code.toString());
  }

  nextPage(){
    this.page += 6;
    this.pages2 += 1;
    this.upToProp();
  }

  previousPage(){
    if(this.page > 0){
      this.page -= 6;
      this.pages2 -= 1;
      this.upToProp();
    }  
  }

  compareFunction(o1: any, o2: any) {
    return (o1.name == o2.name && o1.id == o2.id);
  }

  captureToUp(){
    window.addEventListener('scroll', () => {
      const dest = document.querySelector('.pdestacadas');
      let coords = dest?.getBoundingClientRect();
      if(this.topDest == 0){
        if(coords.top > 180 && coords.top < 200){
          this.topDest = window.scrollY;
        }
      }
    })
    
  }

  upToProp(){
    window.scroll({
      top: this.topDest,
      left: 0,
      behavior: 'smooth'
    });
  }

  changeParameter(parameter: string){
    switch (parameter) {
      case 'cl':
        this.parametro = '3';
        this.locald = 'cl';
        this.readProperties();
        break;
    
      case 'rd':
        this.parametro = '4';
        this.locald = 'rd';
        this.readProperties();
    }
  }


  readProperties(){
    this.apiService.read('properties2','id_country_property',this.parametro).subscribe(resp => {
      if(resp.results != 'Not Found'){
        this.properties = resp.results;
        this.btnPaginate();
        this.destacado();
        resp.results.forEach(resp2=>{
          resp2.amenities2_property = JSON.parse(resp2.amenities_property);
          if(resp2.image_property != '[]'){
            let image = JSON.parse(resp2.image_property);
            resp2.image2_property = image[0].url;
          }else{
            resp2.image2_property = 'https://firebasestorage.googleapis.com/v0/b/batista-images.appspot.com/o/properties%2Fnn.jpg?alt=media&token=b1a6e800-1d4a-4a9e-af16-edc8b96c63b4';
          }
        })
      }
      
    })
  }

  destacado(){
    this.properties2 = [];
    let min = 0;
    let max = this.properties.length;
    let random = Math.floor(Math.random() * (+max - +min)) + +min;

    this.properties2.push(this.properties[random]);

    if(this.properties2[0]['status_property'] != "1"){
      this.destacado();
    }
  }

  btnPaginate(){
    let pag1 = this.properties.length / 6;
    this.pages = Math.ceil(pag1);
  }

  verif(value:boolean){
    this.authService.clickNav.emit({
      data: value
    })
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return value;
  }

  interested(id:number){
    const dialogRef = this.dialog.open(DialogPropertyComponent, {
      width:'700px',
      data: {
        id
      }
    })
  }

}
