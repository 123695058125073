<div class="container-fluid info">
  <div class="row justify-content-center">
    <div class="col-5">
      <h1>Servicios</h1>
    </div>
    <div class="col-5 text-end">
      <button mat-flat-button color="primary" routerLink="../add-service">Agregar Servicio</button>
    </div>
    <div class="col-10">
      <hr>
    </div>
    <div class="col-10">
      <div class="row">
        <div class="col-12 mt-3" *ngFor="let service of services">
          <mat-card class="example-card">
            <div class="row">
              <div class="col-11">
                <mat-card-header>
                  <div mat-card-avatar class="example-header-image"
                    [ngStyle]="{'background-image': 'url(' + service.image2_service + ')'}"></div>
                  <mat-card-title>{{service.title_service | titlecase}}</mat-card-title>
                  <mat-card-subtitle class="mb-0">{{service.date_created_service}}</mat-card-subtitle>
                </mat-card-header>
              </div>
              <div class="col-1 text-end">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item>
                    <mat-icon>visibility</mat-icon>
                    <span>Ver Servicio</span>
                  </button>
                  <button mat-menu-item>
                    <mat-icon>edit</mat-icon>
                    <span>Editar Servicio</span>
                  </button>
                  <button mat-menu-item (click)="deleteService(service.id_service)">
                    <mat-icon>delete</mat-icon>
                    <span>Eliminar Servicio</span>
                  </button>
                </mat-menu>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
