import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { map } from 'rxjs/operators';
import { AsesoraModel } from '../models/asesora';
import { FaqModel } from '../models/faq';
import { InterModel } from '../models/interested';
import { UserModel } from '../models/user';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  @Output() changeRequest: EventEmitter<any> = new EventEmitter();
  @Output() changeMessage: EventEmitter<any> = new EventEmitter();
  @Output() changeRequest2: EventEmitter<any> = new EventEmitter();
  @Output() changeMessage2: EventEmitter<any> = new EventEmitter();
  @Output() changeLang: EventEmitter<any> = new EventEmitter();

  private url = 'https://api.dosiscl.com';
  private userTokenApi = 'c5LTA6WPbMwHhEabYu77nN9cn4VcMj';
  private userToken = '';
  public data: any[] = [];

  constructor( private http: HttpClient,
               private authService: AuthService ) {
    
    this.readToken();
    if( this.userToken != '' ){
      this.data = Object.values( this.authService.getDecodedAccessToken( this.userToken ) );
    }
  }

  readTokenDecoded(){
    return this.data;
  }

  readToken(){
    if (localStorage.getItem('token')) {
      this.userToken = localStorage.getItem('token') || '';
    } else {
      this.userToken = '';
    }

    return this.userToken;
  }

  read( table: string, column: string, search: string ){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/${table}?select=*&linkTo=${column}&search=${search}`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  readAll( table : string ){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/${table}`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  readAll2( table : string, suffix: string ){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/${table}?&orderBy=id_${suffix}&orderMode=DESC`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  readPropertiesRelations(){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/relations?rel=properties2,payments&type=property,payment&select=*&orderBy=id_property&orderMode=ASC`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  readUsers(){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/users`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  readUsersRelations(){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/relations?rel=users,roles,countries&type=user,role,country&select=*&orderBy=id_user&orderMode=ASC`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  readUser( id: number ){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/users?select=*&linkTo=id_user&search=${id}`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  readUserEmail( email: string ){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/users?select=*&linkTo=email_user&search=${email}`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  addUser( user: UserModel ){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Accept': '*/*',
      'Authorization':this.userTokenApi
    }); 

    const payload = new HttpParams()
    .set('id_internal_user', user.id_internal_user)
    .set('email_user', user.email_user)
    .set('password_user', user.password_user)
    .set('name_user', user.name_user)
    .set('lastname_user', user.lastname_user)
    .set('dni_user', user.dni_user)
    .set('id_role_user', user.id_role_user.toString())
    .set('position_user', user.position_user)
    .set('phone_user', user.phone_user.toString())
    .set('commune_user', user.commune_user)
    .set('id_country_user', user.id_country_user.toString())
    .set('brokerCode_user', user.brokerCode_user.toString())
    .set('image_user', user.image_user)
    .set('code_user', user.code_user)
    .set('code_action_user', user.code_action_user.toString())
    .set('code_exp_user', user.code_exp_user)
    .set('date_created_user', user.date_created_user);

    return this.http.post(`${this.url}/users?register=true&suffix=user`,payload,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  inviteUser( data : any){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    console.log(data);

    const payload = new HttpParams()
    .set('email_user', data.email)
    .set('password_user', data.password)
    .set('id_role_user', data.role)
    .set('code_user', data.code)
    .set('code_action_user', data.action)
    .set('code_exp_user', data.exp)
    .set('image_user', 'https://api.dosiscl.com/uploads/no-image.png');

    return this.http.post(`${this.url}/users?register=true&suffix=user`,payload,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  editUser( table: string, data: any, id: number, token: any ){
    if(token){
      this.userToken = token;
    }

    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    const payload = new HttpParams()
    .set(table, data);

    return this.http.put(`${this.url}/users?id=${id}&nameId=id_user&token=${this.userToken}&table=users&suffix=user`,payload,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  editUser2( user: any, id: number, token: any ){

    if(token){
      this.userToken = token;
    }

    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    const payload = new HttpParams()
    .set('email_user', user.email_user)
    .set('password_user', user.password_user)
    .set('name_user', user.name_user)
    .set('lastname_user', user.lastname_user)
    .set('dni_user', user.dni_user)
    .set('phone_user', user.phone_user)
    .set('code_user', user.code_user)
    .set('code_action_user', user.code_action_user)
    .set('code_exp_user', user.code_exp_user)
    .set('id_country_user', user.id_country_user.toString())
    .set('date_created_user', user.date_created_user);

    return this.http.put(`${this.url}/users?id=${id}&nameId=id_user&token=${this.userToken}&table=users&suffix=user`,payload,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  readPages(start: number, end: number, country: string){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/properties2?select=*&orderBy=id_property&orderMode=DESC&startAt=${start}&endAt=${end}&linkTo=id_country_property&equalTo=${country}`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  readPages2( country: string ){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/properties2?select=*&orderBy=id_property&orderMode=DESC&linkTo=id_country_property&equalTo=${country}`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  sendInterested( interest : InterModel ){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    const payload = new HttpParams()
    .set('id_property_interest', interest.id_property_interest.toString())
    .set('name_interest', interest.name_interest)
    .set('lastname_interest', interest.lastname_interest)
    .set('email_interest', interest.email_interest)
    .set('phone_interest', interest.phone_interest)
    .set('ip_interest', interest.ip_interest)
    .set('status_interest', interest.status_interest.toString())
    .set('id_user_interest', interest.id_user_interest.toString())
    .set('date_created_interest', interest.date_created_interest);

    return this.http.post(`${this.url}/interested?token=no&table=users&suffix=user&except=id_interest`,payload,{headers:headers})
    .pipe(
      map((resp:any) => {
        return resp;
      })
    )
  }

  readInterestRelations(){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/relations?rel=interested,properties2,users&type=interest,property,user&select=*&orderBy=id_interest&orderMode=ASC`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  readMessagesRelations(){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/relations?rel=advisers,users&type=adviser,user&select=*&orderBy=id_adviser&orderMode=ASC`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  /*

  readInterest2Relations(){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/relations?rel=interested,properties2,users&type=interest,property,user&select=*&orderBy=id_interest&orderMode=ASC`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  */

  readVisitRelations(){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/relations?rel=visits,properties2&type=visit,property&select=*&orderBy=id_visit&orderMode=ASC`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  visitProperty( data: any[] ){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    const payload = new HttpParams()
    .set('ip_visit', data[0].ip)
    .set('id_property_visit', data[0].property)
    .set('date_created_visit', data[0].date);

    return this.http.post(`${this.url}/visits?token=no&table=users&suffix=user&except=id_visit`,payload,{headers:headers})
    .pipe(
      map((resp:any) => {
        return resp;
      })
    )
  }

  sendAsesora( adviser : AsesoraModel ){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    const payload = new HttpParams()
    .set('name_adviser', adviser.name_adviser)
    .set('info_adviser', adviser.info_adviser)
    .set('country_adviser', adviser.country_adviser)
    .set('city_adviser', adviser.city_adviser)
    .set('email_adviser', adviser.email_adviser)
    .set('phone_adviser', adviser.phone_adviser)
    .set('ip_adviser', adviser.ip_adviser)
    .set('type_adviser', adviser.type_adviser.toString())
    .set('status_adviser', adviser.status_adviser.toString())
    .set('id_user_adviser', adviser.id_user_adviser.toString())
    .set('date_created_adviser', adviser.date_created_adviser);

    return this.http.post(`${this.url}/advisers?token=no&table=users&suffix=user&except=id_adviser`,payload,{headers:headers})
    .pipe(
      map((resp:any) => {
        return resp;
      })
    )
  }

  editMsg( table: string, suffix: string, data: any, id: number ){

    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    const payload = new HttpParams()
    .set('status_'+suffix, data.status)
    .set('id_user_'+suffix, data.read);

    return this.http.put(`${this.url}/${table}?id=${id}&nameId=id_${suffix}&token=${this.userToken}&table=users&suffix=user`,payload,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  deleteReqMes( table : string, suffix : string, id : number ){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    return this.http.delete(`${this.url}/${table}?id=${id}&nameId=id_${suffix}&token=${this.userToken}&table=users&suffix=user`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  addFaq( faq : FaqModel ){
    this.readToken()
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Accept': '*/*',
      'Authorization':this.userTokenApi
    });

    const payload = new HttpParams()
    .set('quest_faq', faq.quest_faq)
    .set('answ_faq', faq.answ_faq)
    .set('questen_faq', faq.questen_faq)
    .set('answen_faq', faq.answen_faq)
    .set('date_created_faq', faq.date_created_faq);

    return this.http.post(`${this.url}/faqs?token=${this.userToken}&table=users&suffix=user`, payload, {headers:headers})
    .pipe(
      map((resp:any) => {
        return resp;
      })
    )
  }

  editFaq( faq : FaqModel, id : number){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    const payload = new HttpParams()
    .set('quest_faq', faq.quest_faq)
    .set('answ_faq', faq.answ_faq)
    .set('questen_faq', faq.questen_faq)
    .set('answen_faq', faq.answen_faq);

    return this.http.put(`${this.url}/faqs?id=${id}&nameId=id_faq&token=${this.userToken}&table=users&suffix=user`,payload,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  searchFaq(id : number){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/faqs?select=*&linkTo=id_faq&search=${id}`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  

  /*
  async uploadImage( name: string, imgBase64: any){

    try{
      let response = await this.storageRef.child("users/"+name).putString(imgBase64,'data_url');
      return await response.ref.getDownloadURL();
    }catch(err){
      return null;
    }

  }
  */
}
