import { Component, OnInit } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  public instagram = '';
  public facebook = '';
  public whatsapp = '';

  constructor( private authService: AuthService,
               private apiService: ApiService ) { }

  ngOnInit(): void {
    this.readSocial();
  }

  readSocial(){
    this.apiService.readAll('redes').subscribe(resp =>{
      if(resp.results != 'Not Found'){
        resp.results.forEach(resp2 => {
          switch (resp2.name_red) {
            case 'instagram':
              this.instagram = resp2.url_red;  
              break;

            case 'facebook':
              this.facebook = resp2.url_red; 
              break;

            case 'whatsapp':
              this.whatsapp = resp2.url_red; 
              break;
          }
        })
      }
    })
  }

}
