/*
export class Markers {

    constructor( public lat: number, public lng: number ){ }

}
*/

export class Markers {

    public lat: number;
    public lng: number;

    public title: string = 'Sin Título';
    public desc: string = 'Sin Descripción';

    constructor( lat: number, lng: number, title: string ){

        this.lat = lat;
        this.lng = lng;
        this.title = title;
    }

}