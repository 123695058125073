export class InterModel {

    id_interest?: number;
    id_property_interest: number;
    name_interest: string;
    lastname_interest: string;
    email_interest: string;
    phone_interest: string;
    ip_interest: string;
    status_interest: number;
    id_user_interest: number;
    date_created_interest: string;

    constructor(){
        this.id_property_interest = 0,
        this.name_interest = '',
        this.lastname_interest = '',
        this.email_interest = '',
        this.phone_interest = '',
        this.ip_interest = '',
        this.status_interest = 0,
        this.id_user_interest = 113,
        this.date_created_interest = ''
    }
}