<div class="row" *ngIf="message">
    <div class="col-12">
        <h4>Solicitud {{tit}}</h4>
    </div>
    <div class="col-12">
        <hr>
    </div>
    <div class="col-12 col-lg-6">
        <p><strong>Nombre :</strong> {{message.name_adviser}}</p>
    </div>
    <div class="col-12 col-lg-6">
        <p><strong>Email :</strong> {{message.email_adviser}}</p>
    </div>
    <div class="col-12 col-lg-6">
        <p><strong>Teléfono :</strong> {{message.phone_adviser}}</p>
    </div>
    <div class="col-12">
        <p><strong>{{message.info_adviser}}</strong></p>
    </div>
    <div class="col-12 mt-3">
        <button mat-flat-button color="primary" (click)="markedMessage()" *ngIf="message.status_adviser == 1">Marcar como leído</button>
    </div>
</div>