<div class="container-fluid">
    <div class="row justify-content-center unete">
        <div class="col-12 col-lg-10 text-center mt-5 mt-lg-0 separator">
            <h3>{{'UNETEQUIPO'|translate}}</h3>
            <!--<h4>{{'UNETEQUIPO2'|translate}}</h4>-->
        </div>
        <div class="col-12 secc-ventas text-center">
            <img src="../../assets/img/team.png" alt="">
        </div>
    </div>

    <form [formGroup]="aseForm" (ngSubmit)="sendAsesora()">
        <!--
    <div class="row justify-content-center forma" id="join">
        -->
    <div class="row justify-content-center forma">
        <div class="col-12 col-lg-10">
            <h3>{{'QUIEROASESORA'|translate}}</h3>
            <!--<p>{{'QUIEROASESORA2'|translate}}</p>-->
        </div>
        <div class="col-12 col-lg-10 mt-4">
            <label for="name">{{'NOMBRECOMPLETO'|translate}}</label>
            <span *ngIf="namePattern"> No escriba caracteres especiales.</span>
            <span *ngIf="nameRequired"> Campo requerido.</span>
            <input type="text" 
                   class="w-100" 
                   formControlName="name"
                   [class.form-inv]="namePattern ||nameRequired">
        </div>
        <div class="col-12 col-lg-10 mt-4">
            <label for="info">{{'MGRECIBIRINFO'|translate}}</label>
            <span *ngIf="infoPattern"> No escriba caracteres especiales.</span>
            <span *ngIf="infoRequired"> Campo requerido.</span>
            <textarea name="textarea" 
                      formControlName="info"
                      [class.form-inv]="infoPattern ||infoRequired"></textarea>
        </div>
        <div class="col-12 col-lg-5 mt-4">
            <label for="email">E-MAIL</label>
            <span *ngIf="emailPattern"> Escriba un email correcto.</span>
            <span *ngIf="emailRequired"> Campo requerido.</span>
            <input type="text" 
                   class="w-100" 
                   formControlName="email"
                   [class.form-inv]="emailPattern || emailRequired">
        </div>
        <div class="col-12 col-lg-5 mt-4">
            <label for="phone">{{'CCONTACTO'|translate}}</label>
            <span *ngIf="phonePattern"> Solo números y signos (+).</span>
            <span *ngIf="phoneRequired"> Campo requerido.</span>
            <input type="text" 
                   class="w-100" 
                   formControlName="phone"
                   [class.form-inv]="phonePattern || phoneRequired">
        </div>
        <div class="col-12 col-lg-5 mt-4 iconos align-selft-center order-2 order-lg-1">
            <i class="fa-brands fa-facebook-f me-4"></i>
            <i class="fa-brands fa-instagram"></i>
        </div>
        <div class="col-12 col-lg-5 text-end mt-4 order-1 order-lg-2">
            <button type="submit">{{'ENVIARBTN'|translate}}</button>
        </div>
    </div>
    </form>
</div> 