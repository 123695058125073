import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BlogService } from '../admin-blog/shared/services/blog.service';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css']
})
export class PostComponent implements OnInit {

  public parametro = '';
  public pst = {
    title_post : '',
    titlen_post : '',
    content_post : '',
    contenten_post : '',
    category_post: 1,
    url_post: '',
    image2_post: ''
  }

  constructor( private blogSer : BlogService,
               private _activatedRoute : ActivatedRoute ) {
                this._activatedRoute.params.subscribe((params: Params) => {
                  this.parametro = params['url'];
                })
               }

  ngOnInit(): void {
    this.readData();
  }

  readData(){
    this.blogSer.searchPost(this.parametro).subscribe(resp => {
      if(resp.results != 'Not Found'){
        this.pst = resp.results[0];
        let img = JSON.parse(this.pst['image_post']);
        this.pst['image2_post'] = img.url;
      }
    })
  }

}
