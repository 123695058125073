export class OwnerModel {

    id_owner?: number;
    name_owner: string;
    lastname_owner: string;
    dni_owner: string;
    email_owner: string;
    id_country_owner: number;
    phone_owner: string;
    address_owner: string;
    obs_owner: string;
    date_created_owner: string;

    constructor(){
        this.name_owner = '',
        this.lastname_owner = '',
        this.dni_owner = '',
        this.email_owner = '',
        this.id_country_owner = 0,
        this.phone_owner = '',
        this.address_owner = '',
        this.obs_owner = '',
        this.date_created_owner = ''
    }
}