import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

moment.locale('es');

@Pipe({
  name: 'day'
})
export class DayPipe implements PipeTransform {

  transform(value: string): string {
    if(!value){
      return null;
    }

    let fecha = moment(value, 'YYYY-MM-DD HH:mm:ss');
    let fecha2 = fecha.fromNow();

    return fecha2;
  }

}
