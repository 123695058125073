import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { UserModel } from 'src/app/shared/models/user';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ImageService } from 'src/app/shared/services/image.service';
import { ValidatorsService } from 'src/app/shared/services/validators.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.css'],
})
export class RegisterUserComponent implements OnInit {
  isLinear = true;
  public userModel: UserModel = new UserModel();
  public codeForm!: FormGroup;
  public accessForm!: FormGroup;
  public infoForm!: FormGroup;
  public imageForm!: FormGroup;
  public parametro = '';
  public user: any[] = [];
  public access: boolean = false;
  public archives: any[] = [];
  public countries: any[] = [];
  public urlImage: any;
  public password1 = '';
  public password2 = '';
  public errorMessage = '';

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private imgService: ImageService,
    private validService: ValidatorsService,
    private _activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.readUser();
    this.createForm();
    this.readCountries();
  }

  get passwordPattern() {
    if (
      this.accessForm.controls.password.errors &&
      this.accessForm.controls.password.touched
    ) {
      if (this.accessForm.controls.password.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get password2Pattern() {
    if (
      this.accessForm.controls.password2.errors &&
      this.accessForm.controls.password2.touched
    ) {
      if (this.accessForm.controls.password2.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get password2NoValido() {
    if (this.accessForm.controls.password2.touched) {
      this.password1 = this.accessForm.get('password')?.value;
      this.password2 = this.accessForm.get('password2')?.value;
    }
    return this.password1 === this.password2 ? false : true;
  }

  get namePattern() {
    if (
      this.infoForm.controls.name.errors &&
      this.infoForm.controls.name.touched
    ) {
      if (this.infoForm.controls.name.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get nameRequired() {
    if (
      this.infoForm.controls.name.errors &&
      this.infoForm.controls.name.touched
    ) {
      if (this.infoForm.controls.name.errors.required) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get lastnamePattern() {
    if (
      this.infoForm.controls.lastname.errors &&
      this.infoForm.controls.lastname.touched
    ) {
      if (this.infoForm.controls.lastname.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get lastnameRequired() {
    if (
      this.infoForm.controls.lastname.errors &&
      this.infoForm.controls.lastname.touched
    ) {
      if (this.infoForm.controls.lastname.errors.required) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get dniPattern() {
    if (
      this.infoForm.controls.dni.errors &&
      this.infoForm.controls.dni.touched
    ) {
      if (this.infoForm.controls.dni.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get phonePattern() {
    if (
      this.infoForm.controls.phone.errors &&
      this.infoForm.controls.phone.touched
    ) {
      if (this.infoForm.controls.phone.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  createForm() {
    this.codeForm = this.fb.group({
      codeMail: ['',[Validators.required,Validators.pattern(/^[a-zA-Z0-9]*$/)]],
    });
    this.accessForm = this.fb.group(
      {
        password: [
          '',
          [Validators.required, Validators.pattern(/^[a-zA-Z0-9_@.!#%]*$/)],
        ],
        password2: ['', Validators.required],
      },
      {
        validators: this.validService.idem('password', 'password2'),
      }
    );
    this.infoForm = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[,.A-Za-z0-9-À-ÿ\u00f1\u00d1\s]+$/),
        ],
      ],
      lastname: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[,.A-Za-z0-9-À-ÿ\u00f1\u00d1\s]+$/),
        ],
      ],
      dni: ['', Validators.pattern(/^(\d{1,3}(?:\.\d{1,3}){2}-[\dkK])$/)],
      phone: ['', Validators.pattern(/^[0-9()+-]*$/)],
      country: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]]
    });
    this.imageForm = this.fb.group({
      image: ['']
    })
  }

  readUser() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor',
    });
    Swal.showLoading();

    this._activatedRoute.params.subscribe((params: Params) => {
      this.parametro = params['email'];
    });

    this.apiService.readUserEmail(this.parametro).subscribe((resp) => {
      this.user = resp.results[0];
      Swal.close();
    });
  }

  validateCode() {
    if (this.codeForm.invalid) {
      return Object.values(this.codeForm.controls).forEach((control) => {
        control.markAsTouched();
      });
    }

    if (this.codeForm.controls.codeMail.value == this.user['code_user']) {
      const times2 = Number(moment().add(1, 'h').format('x'));

      if(Date.now() < times2){
        this.access = true;
      }else{
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'El código ha expirado',
          showConfirmButton: false,
          timer: 1500,
        });
      }
      
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Código incorrecto',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  readCountries() {
    this.apiService.readAll('countries').subscribe((resp) => {
      this.countries = resp.results;
    });
  }

  onSelectFile(event): any {
    this.archives = event.target.files;
    const reader = new FileReader();

    reader.readAsDataURL(this.archives[0]);
    reader.onloadend = () => {
      this.urlImage = reader.result;
    };
  }

  sendRegister() {
    if (this.accessForm.invalid) {
      return Object.values(this.accessForm.controls).forEach((control) => {
        control.markAsTouched();
      });
    }

    const dat = moment();

    this.userModel.email_user = this.parametro;
    this.userModel.password_user = '@Ingr3z!oO33kiLl3r';
    this.userModel.name_user = this.infoForm.controls.name.value;
    this.userModel.lastname_user = this.infoForm.controls.lastname.value;
    this.userModel.dni_user = this.infoForm.controls.dni.value;
    this.userModel.phone_user = this.infoForm.controls.phone.value;
    this.userModel.code_user = '';
    this.userModel.code_action_user = 0;
    this.userModel.code_exp_user = '';
    this.userModel.id_country_user = this.infoForm.controls.country.value;
    this.userModel.token_user = '';
    this.userModel.token_exp_user = '';
    this.userModel.date_created_user = dat.format('YYYY-MM-DD');

    let data = this.user['id_user'] + '_' + Date.now();

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor',
    });
    Swal.showLoading();

    this.authService.newPass(this.accessForm.controls.password.value).subscribe((resPass) => {
    
      this.authService.loginUser(this.userModel).subscribe(respTok => {
        this.userModel.password_user = resPass.results;
        
        const tokke = respTok.results[0].token_user;
        
        this.apiService
        .editUser2(this.userModel, this.user['id_user'], tokke)
        .subscribe((resp) => {

          if (this.urlImage) {
          
            this.imgService.uploadImage(data, this.urlImage,'users').then((urlImage) => {
              this.apiService
                .editUser('image_user', urlImage, this.user['id_user'], tokke)
                .subscribe((resp2: any) => {
                  Swal.close();
  
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'El usuario ha sido registrado con exito',
                    showConfirmButton: false,
                    timer: 1500,
                  }).then(() => {
                    this.router.navigate(['login']);
                  });
                });
            });
          } else {
            
            const url2 = 'https://api.dosiscl.com/uploads/no-image.png';
            this.apiService
              .editUser('image_user', url2, this.user['id_user'], tokke)
              .subscribe((resp2: any) => {
                Swal.close();
  
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'El usuario ha sido registrado con exito',
                  showConfirmButton: false,
                  timer: 1500,
                }).then(() => {
                  this.router.navigate(['login']);
                });
              });
          }
        },
        (error) => {
          this.errorMessage = error;
  
          if (this.errorMessage != null) {
            Swal.fire({
              title: 'error',
              icon: 'error',
              text: error.error.message,
            });
          }
        });
  
      })
    })

    /*
    
    */
  }
}
