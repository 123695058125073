import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'request'
})
export class RequestPipe implements PipeTransform {

  transform(value: any[], table: string): unknown {

    if(!value){
      return null;
    }

    if(value.length == 0){
      return value;
    }else{
      if(table == 'interest'){
        const filteredRequest = value.filter( req => req.status_interest.includes(1));
        return filteredRequest;
      }else{
        const filteredRequest = value.filter( req => req.status_adviser.includes(1));
        return filteredRequest;
      }
    }
    
    
  }

}
