<div class="container-fluid info">
    <div class="row justify-content-center">
        <div class="col-12 col-lg-8">   
            <mat-card class="example-card">
                <mat-card-title>Agregar Pregunta</mat-card-title>
                <mat-divider></mat-divider>
                <mat-card-content>
                    <mat-horizontal-stepper linear #stepper>
                        <mat-step [stepControl]="faqesForm">
                            <form [formGroup]="faqesForm">
                                <ng-template matStepLabel>Español</ng-template>
                                <div class="row mt-4">
                                    <div class="col-12">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <mat-label>Pregunta</mat-label>
                                            <input matInput formControlName="quest">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12">
                                        <ckeditor data="" [editor]="Editor" [config]="editorConfig" formControlName="answer"></ckeditor>
                                    </div>
                                    <div class="col-12 mt-3">
                                        <hr>
                                    </div>
                                    <div class="col-12 mt-2">
                                        <button mat-raised-button color="primary" class="btnss" matStepperNext>Siguiente</button>
                                    </div>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="faqenForm">
                            <form [formGroup]="faqenForm">
                                <ng-template matStepLabel>Inglés</ng-template>
                                <div class="row mt-4">
                                    <div class="col-12">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <mat-label>Pregunta</mat-label>
                                            <input matInput formControlName="questen">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12">
                                        <ckeditor [editor]="Editor" data="" [config]="editorConfig" formControlName="answeren"></ckeditor>
                                    </div>
                                    <div class="col-12 mt-3">
                                        <hr>
                                    </div>
                                    <div class="col-12 mt-2">
                                        <button mat-button matStepperPrevious mat-raised-button color="basic" class=" btnss">Anterior</button>
                                        <button type="submit" mat-raised-button color="primary" class="ms-2 btnss" *ngIf="!parametro" (click)="publish(1)">Publicar</button>
                                        <button type="submit" mat-raised-button color="primary" class="ms-2 btnss" *ngIf="parametro" (click)="publish(2)">Editar</button>
                                        <button type="button" mat-raised-button color="warn" class="ms-2 btnss" *ngIf="parametro" routerLink="../../../faq">Cancelar</button>
                                    </div>
                                </div>
                            </form>
                        </mat-step>
                    </mat-horizontal-stepper>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>