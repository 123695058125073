import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminMessagesComponent } from './admin-messages.component';
import { MaterialModule } from '../material.module';
import { ViewMessageComponent } from './view-message/view-message.component';
import { AdviserHomePipe } from '../shared/filters/adviser-home.pipe';
import { TypeMessagePipe } from '../admin-home/shared/filters/type-message.pipe';



@NgModule({
  declarations: [AdminMessagesComponent, ViewMessageComponent, AdviserHomePipe],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    AdminMessagesComponent
  ]
})
export class AdminMessagesModule { }
