import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyComponent } from './property.component';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from '../app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { AgmCoreModule } from '@agm/core';



@NgModule({
  declarations: [PropertyComponent],
  imports: [
    CommonModule,
    TranslateModule,
    BrowserModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBF87jmEwF8LzQxbcuUPZkuMWQ_kIFD6Pc'
    })
  ],
  exports: [
    PropertyComponent
  ]
})
export class PropertyModule { }
