import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRequestComponent } from './admin-request.component';
import { MaterialModule } from '../material.module';
import { ViewRequestComponent } from './view-request/view-request.component';
import { RequestHomePipe } from '../shared/filters/request-home.pipe';



@NgModule({
  declarations: [AdminRequestComponent, ViewRequestComponent, RequestHomePipe],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    AdminRequestComponent
  ]
})
export class AdminRequestModule { }
