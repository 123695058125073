import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'homeDest'
})
export class HomeDestPipe implements PipeTransform {

  transform(value: any[], divisa: number, meas:number): unknown {

    if(!value){
      return null
    }

    const filteredProperties = value.filter(prope => prope.highlight_property.includes(1));

    const filteredProperties2 = filteredProperties.filter(propa => propa.status_property.includes(1));

    filteredProperties2.forEach(resp => {

      if(meas == 2){
        let ground = Number(resp.ground_property);
        resp.ground2_property = Math.round(ground*10.7639);

        let ground2 = Number(resp.built_property);
        resp.built2_property = Math.round(ground2*10.7639);
      }

      var divisa2 = 0;
      if(divisa != 0){
        divisa2 = divisa;
      }else{
        if(resp.id_country_property == 4){
          divisa2 = 3;
        }else{
          divisa2 = 1;
        }
      }
    
      let diva = JSON.parse(resp.value_property);
      let index = diva.map((div) => div.div).indexOf(divisa2.toString());
      resp.value2_property = diva[index].value;
      resp.value3_property = diva[index].code;

    })

    if(filteredProperties2.length <= 2){
      return filteredProperties2;
    }else{
      const min = 3;
      const max = filteredProperties2.length;
      const random = Math.floor(Math.random() * (+max - +min)) +min;

      return filteredProperties2.slice(random-3,random);
    }
    
  }

}
