import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { ServicesModel } from 'src/app/shared/models/services';
import { AuthService } from 'src/app/shared/services/auth.service';



@Injectable({
  providedIn: 'root'
})
export class ServicsService {

  private url = 'https://api.dosiscl.com';
  private userTokenApi = 'c5LTA6WPbMwHhEabYu77nN9cn4VcMj';
  private userToken = '';
  public data: any[] = [];

  constructor( private http: HttpClient,
               private authService: AuthService ) { 
    this.readToken();
    if( this.userToken != '' ){
      this.data = Object.values( this.authService.getDecodedAccessToken( this.userToken ) );
    }
  }

  readToken(){
    if (localStorage.getItem('token')) {
      this.userToken = localStorage.getItem('token') || '';
    } else {
      this.userToken = '';
    }

    return this.userToken;
  }

  addService( service: ServicesModel ){

    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Accept': '*/*',
      'Authorization':this.userTokenApi
    }); 

    const payload = new HttpParams()
    .set('id_user_service', service.id_user_service.toString())
    .set('title_service', service.title_service)
    .set('content_service', service.content_service)
    .set('image_service', service.image_service)
    .set('link_service', service.link_service)
    .set('date_created_service', service.date_created_service);

    return this.http.post(`${this.url}/services?token=${this.userToken}&table=users&suffix=user&except`,payload,{headers:headers})
    .pipe(
      map((resp:any) => {
        return resp;
      })
    )
    
  }

  editService( table: string, data: any, id: number ){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    const payload = new HttpParams()
    .set(table, data);

    return this.http.put(`${this.url}/services?id=${id}&nameId=id_service&token=${this.userToken}&table=users&suffix=user`,payload,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  deleteService( id: number ){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    return this.http.delete(`${this.url}/services?id=${id}&nameId=id_service&token=${this.userToken}&table=users&suffix=user`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )

  }

}
