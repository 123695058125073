import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'adviserHome'
})
export class AdviserHomePipe implements PipeTransform {

  transform(value: any[], page: number = 0): unknown {
    if(!value){
      return null;
    }

    console.log(value);

    value = value.reverse();

    return value.slice(page, page + 5);
  }

}
