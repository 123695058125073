<div class="container-fluid info">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-5">
        <h1>Resumen</h1>
      </div>
      <div class="col-5 d-none d-lg-block">
        
      </div>
      <div class="col-12 col-lg-7 mt-3">
        <div class="row">
          <div class="col-12">
            <p>Propiedades con mayor interés</p>
          </div>
          <div class="col-12 col-lg-4 mb-2 mb-lg-0" *ngFor="let inter of interested | interest">
            <mat-card class="example-card" [ngStyle]="{'background-image': 'url(' + inter.image2_property + ')'}">
              <mat-card-header class="petero">
                <mat-card-title>{{inter.description_property | titlecase}}</mat-card-title>
                <mat-card-subtitle><i class="fa-solid fa-message"></i> {{inter.interested}} Interesado<small *ngIf="inter.interested > 1">s</small></mat-card-subtitle>
              </mat-card-header>
            </mat-card>
          </div>
          <div class="col-12 col-lg-4 mb-2 mb-lg-0 no-existe" *ngIf="interested.length == 0">
            <p>No hay propiedades con interés aun.</p>
          </div>
          <div class="col-12 text-end mt-3">
            <small>VER TODAS</small>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-3 mt-3">
        <div class="row">
          <div class="col-12">
            <p>Propiedades más visitadas</p>
          </div>
          <div class="col-12 mb-1" *ngFor="let visit of visits | visits">
            <mat-card class="example-card2">
              <div class="row">
                <div class="col-4 vis text-center align-self-center">
                  <h4>{{visit.visits}}</h4>
                  <p><small>Visitas</small></p>
                </div>
                <div class="col-8 align-self-center">
                  <h6>{{visit.description_property}}</h6>
                </div>
              </div>
            </mat-card>
          </div>
          <div class="col-12 mb-1 no-existe" *ngIf="visits.length == 0">
            <p>No hay propiedades visitadas.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-4 pb-5">
      <div class="col-12 col-lg-5">
        <div class="row justify-content-center">
          <div class="col-12 mb-2">
            <p><i class="fa-regular fa-bell"></i> Solicitudes Pendientes</p>
          </div>
          <div class="col-11 mb-1 text-center no-existe" *ngIf="interested2.length == 0">
            <p class="m-0">No hay solicitudes pendientes</p>
          </div>
          <div class="col-12 mb-3 ficha" *ngFor="let inte of interested2">
            <mat-card class="example-card2">
              <div class="row">
                <div class="col-12 align-self-center">
                  <h6>{{inte.description_property | titlecase}}</h6>
                  <small>{{inte.name_interest | titlecase}} {{inte.lastname_interest | titlecase}} ha enviado una solicitud de información.</small>
                </div>
                <div class="blacker" (click)="viewRequest(inte.id_interest)">
                  <i class="fa-regular fa-eye"></i>
                </div>
              </div>
            </mat-card>
          </div>
          <div class="col-12 text-end mt-3" *ngIf="interested3.length > 3">
            <small>VER TODAS</small>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-5">
        <div class="row justify-content-center">
          <div class="col-12 mb-2">
            <p><i class="fa-regular fa-message"></i> Mensajes Pendientes</p>
          </div>
          <div class="col-11 mb-1 text-center no-existe" *ngIf="messages2.length == 0">
            <p class="m-0">No hay mensajes pendientes</p>
          </div>
          <div class="col-12 mb-3 ficha" *ngFor="let msg of messages2">
            <mat-card class="example-card2">
              <div class="row">
                <div class="col-12 align-self-center">
                  <h6>{{msg.name_adviser | titlecase}}</h6>
                  <small>ha enviado una mensaje.   <strong><small>{{msg.type_adviser | typeMessage}}</small></strong></small>
                </div>
                <div class="blacker" (click)="viewMessage(msg.id_adviser)">
                  <i class="fa-regular fa-eye"></i>
                </div>
              </div>
            </mat-card>
          </div>
          <div class="col-12 text-end mt-3" *ngIf="messages.length > 3">
            <small>VER TODAS</small>
          </div>
        </div>
      </div>
    </div>
</div>
