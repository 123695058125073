<form [formGroup]="inviteForm" (ngSubmit)="inviteUser()">
    <span *ngIf="namePattern" class="text-danger"> Evite escribir caracteres especiales.</span>
    <span *ngIf="nameRequired" class="text-danger"> Campo requerido.</span>
    <mat-form-field appearance="fill" class="w-100">
        <mat-label>Nombre</mat-label>
        <input matInput 
            placeholder="Ej: Juan" 
            formControlName="name"
            [class.form-inv]="namePattern || nameRequired">
    </mat-form-field>
    <span *ngIf="existingEmail" class="text-danger"> Este email ya existe.</span>
    <span *ngIf="emailPattern" class="text-danger"> Evite escribir caracteres especiales.</span>
    <span *ngIf="emailRequired" class="text-danger"> Campo requerido.</span>
    <mat-form-field appearance="fill" class="w-100">
        <mat-label>Email</mat-label>
        <input matInput 
            formControlName="email"
            [class.form-inv]="existingEmail || emailPattern || emailRequired">
    </mat-form-field>
    <mat-form-field appearance="fill" class="w-100">
        <mat-label>Privilegio</mat-label>
        <mat-select formControlName="role">
          <mat-option value="{{rol.id_role}}" *ngFor="let rol of roles">
            {{rol.name_role}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    <button type="submit" mat-raised-button color="primary" class="mt-2">Invitar</button>
    <button type="button" mat-raised-button color="warn" class="ms-2 mt-2" (click)="onNoClick()">Cancelar</button>
</form>
