import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ViewMessageComponent } from '../admin-messages/view-message/view-message.component';
import { ViewRequestComponent } from '../admin-request/view-request/view-request.component';
import { ApiService } from '../shared/services/api.service';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent implements OnInit {

  public users: any[] = [];
  public properties: any[] = [];
  public visits: any[] = [];
  public interested: any[] = [];
  public interested2: any[] = [];
  public interested3: any[] = [];
  public messages: any[] = [];
  public messages2: any[] = [];
  public errorMessage = '';

  constructor( private apiService: ApiService,
               private dialog: MatDialog ) { }

  ngOnInit(): void {

    this.readUsers();
    this.readPropertiesInter();
    this.readPropertiesVisit();
    this.readMessages();
    //this.readProperties();

    this.apiService.changeMessage2.subscribe(data => {
      if(data.data){
        this.readMessages();
      }
    })

    this.apiService.changeRequest2.subscribe(data => {
      if(data.data){
        this.readPropertiesInter();
      }
    })
    
  }

  readUsers(){
    this.apiService.readUsers().subscribe(resp => {
      this.users = resp.results;
    })
  }

  readPropertiesInter(){
    this.interested = [];
    
    this.apiService.readInterestRelations().subscribe(resp => {
      if(resp.results != 'Not Found'){
        this.interested = resp.results;
        const filtered = this.interested.filter(req => req.status_interest.includes(1));
        let sorte = filtered.sort(((a,b) => b.id_interest - a.id_interest));
        this.interested2 = sorte.slice(0,3);
        this.interested3 = sorte;
      }
    })
  }

  readPropertiesVisit(){
    this.apiService.readVisitRelations().subscribe(resp => {
      if(resp.results != 'Not Found'){
        this.visits = resp.results;
      }
    })
  }

  readMessages(){
    this.messages = [];

    this.apiService.readAll2('advisers','adviser').subscribe(resp => {
      if(resp.results != 'Not Found'){
        resp.results.forEach(resp2 => {
          if(resp2.status_adviser == '1'){
            this.messages.push(resp2);
          }
        })
        this.messages2 = this.messages.slice(0,3);
      }
    })
  }

  viewMessage(id: number){
    const dialogRef = this.dialog.open(ViewMessageComponent, {
      width:'700px',
      data: {
        id
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.readMessages();
    });
  }

  viewRequest(id: number){
    
    const dialogRef = this.dialog.open(ViewRequestComponent, {
      width:'700px',
      data: {
        id
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.readPropertiesInter();
    });
  }

  /*
  readProperties(){
    this.apiService.readPropertiesRelations().subscribe((resp) => {
      this.apiService.readAll('visits').subscribe(resp1 => {
        this.visits = resp1.results;
        this.apiService.readAll('interested').subscribe(resp2 => {
          this.interested = resp2.results;

          resp.results.forEach(resp3 => {
            if(resp3.image_property != '[]'){
              let proper = JSON.parse(resp3.image_property);
              //resp3.image_property = proper[0];
              resp3.image2_property = proper[0].url;
              resp3.photos_property = proper.length;
            }else{
              resp3.image2_property = 'https://api.dosiscl.com/uploads/no-image.png';
              resp3.photos_property = 0;
            }
          })
        })
      })
    })
  }
  */

}
