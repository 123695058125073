<div class="container-fluid faq separator">
    <div class="row justify-content-center">
        <div class="col-12 col-lg-7 title align-self-center">
            <h3>{{'FOOT1' | translate}}</h3>
        </div>
        <div class="col-12 col-lg-3 text-end">
            <button class="btn3 w-100" routerLink="/safe"><i class="fa-solid fa-shield-heart me-2"></i>{{'FOOT2'|translate}}</button>
        </div>
    </div>
    <div class="row justify-content-center mt-5 mb-5">
        <div class="col-12 col-lg-10">
            <mat-accordion>
                <mat-expansion-panel *ngFor="let faq of faqs">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="deve" *ngIf="lang == 'es' || lang == '' || faq.questen_faq == ''">
                      {{faq.quest_faq}}
                    </mat-panel-title>
                    <mat-panel-title class="deve" *ngIf="lang == 'en' && faq.questen_faq != ''">
                      {{faq.questen_faq}}
                    </mat-panel-title>
                    <mat-panel-description>
                      
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="response" [innerHTML]="faq.answ_faq" *ngIf="lang == 'es' || lang == '' || faq.questen_faq == ''"></div>
                  <div class="response" [innerHTML]="faq.answen_faq" *ngIf="lang == 'en' && faq.questen_faq != ''"></div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="col-10 text-center">
            <div class="no-existe" *ngIf="faqs.length == 0">
                {{'ERROR5' | translate}}
            </div>
        </div>
    </div>
</div>
