import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Faq2Component } from './faq2.component';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from '../app-routing.module';
import { MaterialModule } from '../material.module';



@NgModule({
  declarations: [Faq2Component],
  imports: [
    CommonModule,
    TranslateModule,
    AppRoutingModule,
    MaterialModule
  ],
  exports: [Faq2Component]
})
export class Faq2Module { }
