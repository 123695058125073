<div class="container-fluid post">
    <div class="row justify-content-center separator">
        <div class="col-12 col-lg-7 text-center">
            <img src="{{pst.image2_post}}" class="w-100" alt="">
        </div>
        <div class="col-12 col-lg-7">
            <hr>
        </div>
        <div class="col-12 col-lg-7 title text-center mt-4">
            <h3>{{pst.title_post}}</h3>
        </div>
        <div class="col-12 col-lg-7 mt-4">
            <div class="content" [innerHTML]="pst.content_post"></div>
        </div>
    </div>
</div>