import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'interest'
})
export class InterestPipe implements PipeTransform {

  transform(value: []): unknown {

    if(!value){
      return null;
    }

    let propert = [];

    value.forEach((resp:any) => {

      if(resp.image_property != '[]'){
        let proper = JSON.parse(resp.image_property);
        //resp.image_property = proper[0];
        resp.image2_property = proper[0].url;
      }else{
        resp.image2_property = 'https://api.dosiscl.com/uploads/no-image.png';
      }

      let index = propert.map((prop) => prop.id_property).indexOf(resp.id_property);

      if(index >= 0){
        propert[index].interested += 1;
      }else{
        resp.interested = 1;
        propert.push(resp);
      }
    })

    let vise = propert.sort(((a, b) => b.interested - a.interested));
    return vise.slice(0, 3);
    
  }

}
