import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { InterModel } from '../shared/models/interested';
import { ApiService } from '../shared/services/api.service';
import { MailService } from '../shared/services/mail.service';

@Component({
  selector: 'app-dialog-property',
  templateUrl: './dialog-property.component.html',
  styleUrls: ['./dialog-property.component.css']
})
export class DialogPropertyComponent implements OnInit {

  public interForm!: FormGroup;
  public inter: InterModel = new InterModel();
  public property: any;
  public errorMessage = '';

  constructor( private apiService: ApiService,
               private mailService: MailService, 
               public dialogRef:MatDialogRef<DialogPropertyComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any,
               public fb: FormBuilder ) {}

  ngOnInit(): void {
    this.readProperty();
    this.createForm();
  }

  readProperty(){
    this.apiService.read('properties2', 'id_property', this.data.id).subscribe(resp => {

      this.property = resp.results[0];
      let img = JSON.parse(this.property.image_property);
      this.property.image2_property = img[0].url;
      
    })
  }

  createForm(){
    this.interForm = this.fb.group({
      name: [''],
      lastname: [''],
      email: [''],
      phone: ['']
    })
  }

  sendInterest(){
    if (this.interForm.invalid) {
      return Object.values(this.interForm.controls).forEach((control) => {
        control.markAsTouched();
      });
    }

    const dat = moment();
    
    this.inter.id_property_interest = this.property.id_property;
    this.inter.name_interest = this.interForm.controls.name.value;
    this.inter.lastname_interest = this.interForm.controls.lastname.value;
    this.inter.email_interest = this.interForm.controls.email.value;
    this.inter.phone_interest = this.interForm.controls.phone.value;
    this.inter.ip_interest = '';
    this.inter.status_interest = 1;
    this.inter.date_created_interest = dat.format('YYYY-MM-DD');

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Enviando solicitud',
    });
    Swal.showLoading();

    this.apiService.sendInterested(this.inter).subscribe(resp => {

      let dataMail = [
        {
          email: this.interForm.controls.email.value,
          name: this.interForm.controls.name.value,
          last_name: this.interForm.controls.lastname.value,
          property: this.property.description_property,
          code: 6
        }
      ]

      this.mailService.sendMail(dataMail).subscribe((resp2: any) => {
        Swal.close();

        Swal.fire({
          position: 'center',
          icon: 'success',
          title: '¡Enviado!',
          text: 'Pronto le enviaremos la información',
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          this.dialogRef.close();
        });
      })
    },
    (error) => {
      this.errorMessage = error;

      if (this.errorMessage != null) {
        Swal.fire({
          title: 'error',
          icon: 'error',
          text: error.error.message,
        });
      }
    })

  }

}
