import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserModel } from '../models/user';

import jwt_decode from 'jwt-decode';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  @Output() clickNav: EventEmitter<any> = new EventEmitter();

  @Output() clickCountry: EventEmitter<any> = new EventEmitter();

  @Output() userLogin: EventEmitter<any> = new EventEmitter();

  @Output() changeCurrency: EventEmitter<any> = new EventEmitter();

  @Output() pagesPost: EventEmitter<any> = new EventEmitter();

  @Output() pagesSafe: EventEmitter<any> = new EventEmitter();

  private url = 'https://api.dosiscl.com';
  private userTokenApi = 'c5LTA6WPbMwHhEabYu77nN9cn4VcMj';
  private economicKey = 'bee5991f99cf1072c56e8a81ebd24f5ecd1c35c1';
  private userToken = '';
  public data: any[] = [];
  public role = '';
  public currency = 0;
  public peso = 1;
  public dolar = 0;
  public uf = 0;

  constructor( private http: HttpClient,
               public router: Router ) { 
                this.leerToken();
                if( this.userToken != '' ){
                  this.data = Object.values( this.getDecodedAccessToken( this.userToken ) );
                }
              }

  getRoleSuper(){

    if(this.data[2].role == '1'){
      return true;
    }else{
      return false;
    }
    
  }

  readTokenDecoded(){
    return this.data;
  }

  getDecodedAccessToken( token: string ): any {
    try {
      return jwt_decode( token );
    } catch {
      return null;
    }
  }

  leerToken(){
    if (localStorage.getItem('token')) {
      this.userToken = localStorage.getItem('token') || '';
    } else {
      this.userToken = '';
    }

    return this.userToken;
  }

  isAuth(): boolean {
    if( this.userToken.length < 2 ){
      return false;
    }
    return this.userToken.length > 2;
  }

  saveToken( token: string ){
    this.userToken = token;
    localStorage.setItem( 'token', token );
  }

  changeCurrencyS(currency:number){
    this.currency = currency;
  }

  readCurrency(){
    return this.currency;
  }

  readEconomic(divisa:string){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded'
    });

    return this.http.get(`https://api.cmfchile.cl/api-sbifv3/recursos_api/${divisa}?apikey=${this.economicKey}&formato=json`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  urlExist( url : string ){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/properties2?select=*&linkTo=url_property&search=${url}`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }


  /** 

  addUserAdmin( user: UserModel ){

    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    const payload = new HttpParams()
    .set('id_internal_user', user.id_internal_user)
    .set('email_user', user.email_user)
    .set('password_user', user.password_user)
    .set('name_user', user.name_user)
    .set('lastname_user', user.lastname_user)
    .set('dni_user', user.dni_user)
    .set('role_user', user.id_role_user.toString())
    .set('position_user', user.position_user)
    .set('phone_user', user.phone_user.toString())
    .set('commune_user', user.commune_user)
    .set('country_user', user.country_user)
    .set('brokerCode_user', user.brokerCode_user.toString())
    .set('image_user', user.image_user)
    .set('date_created_user', user.date_created_user);

    return this.http.post(`${this.url}/users?register=true&suffix=user`,payload,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )

  }

  */

  newPass(pass: string){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    const payload = new HttpParams()
    .set('password_user', pass);

    return this.http.post(`${this.url}/users?password=true&suffix=user`,payload,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  resetPass1( user: any, id: number ){

    this.userToken = 'no';

    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    });

    const payload = new HttpParams()
    .set('code_user', user.code)
    .set('code_action_user', user.action)
    .set('code_exp_user', user.exp);

    return this.http.put(`${this.url}/users?id=${id}&nameId=id_user&token=${this.userToken}&table=users&suffix=user&except=id_user`,payload,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )

  }

  editPass( user: any, id: number ){

    this.userToken = 'no';

    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    });

    const payload = new HttpParams()
    .set('password_user', user.password)
    .set('code_user', user.code)
    .set('code_action_user', user.action)
    .set('code_exp_user', user.exp);

    return this.http.put(`${this.url}/users?id=${id}&nameId=id_user&token=${this.userToken}&table=users&suffix=user&except=id_user`,payload,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )

  }

  loginUser( user: UserModel ){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    const payload = new HttpParams()
    .set('email_user', user.email_user)
    .set('password_user', user.password_user);

    return this.http.post(`${this.url}/users?login=true&suffix=user`,payload,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  /*
  readUser( id: number ){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/users?select=*&linkTo=id_user&search=${id}`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }
  
  readUsers(){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/users`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }
  
  async uploadImage( name: string, imgBase64: any){

    try{
      let response = await this.storageRef.child("users/"+name).putString(imgBase64,'data_url');
      return await response.ref.getDownloadURL();
    }catch(err){
      return null;
    }

  }
  
  editUser(table: string, data: any, id: number){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    const payload = new HttpParams()
    .set(table, data);

    return this.http.put(`${this.url}/users?id=${id}&nameId=id_user&token=${this.userToken}&table=users&suffix=user`,payload,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )

  }
  */
  emailExist( email : string ){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/users?select=*&linkTo=email_user&search=${email}`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }


  logoutUsuario() {
    this.userToken = '';
    localStorage.removeItem('token');
    this.router.navigate(['home']);
    this.userLogin.emit({
      data: null
    })
  }

  
}
