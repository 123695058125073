import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterProperties'
})
export class FilterPropertiesPipe implements PipeTransform {

  transform(value: [], page: number = 0): unknown {
    if(!value){
      return null;
    }

    return value.slice(page, page +8);
  }

}
