import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ViewMessageComponent } from 'src/app/admin-messages/view-message/view-message.component';
import { ViewRequestComponent } from 'src/app/admin-request/view-request/view-request.component';
import { AsesoraModel } from '../models/asesora';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { MailService } from '../services/mail.service';
import { SafeComponent } from 'src/app/safe/safe.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  langs: string[] = [];

  public puntero: any;
  public navFix: boolean = false;
  public logean: boolean = false;
  public verify: boolean = false;
  public data: any[] = [];
  public imageProfile = 'https://api.dosiscl.com/uploads/no-image.png';
  public role = '';
  public url : any[] = [];
  public requests: any[] = [];
  public messages: any[] = [];
  public openMenu: boolean = false;
  public errorMessage = '';
  
  public prop: boolean = false;
  public idio: boolean = false;
  public mone: boolean = false;
  public admi: boolean = false;
  public menuDer: boolean = false;
  public forma1: boolean = false;
  public direction: boolean = false;

  public adviser: AsesoraModel = new AsesoraModel();
  public adForm!: FormGroup;

  constructor( private authService: AuthService,
               private apiService: ApiService,
               private dialog: MatDialog,
               private translate: TranslateService,
               private fb : FormBuilder,
               private mailService : MailService ) {
                this.authService.clickNav.subscribe(data => {

                  if(localStorage.getItem('lang') == 'es'){
                    this.translate.setDefaultLang('es');
                    this.translate.use('es');
                  }else{
                    this.translate.setDefaultLang('en');
                    this.translate.use('en');
                  }
                  
                  this.translate.addLangs(['es','en']);
                  this.langs = this.translate.getLangs();

                  this.navFix = data.data;
                  this.verif(data.data);
                })

                
              }

  ngOnInit(): void {

    this.searchToken();
    this.readRequest();
    this.readMessage();
    this.createForm();

    if(localStorage.getItem('lang')){
      if(localStorage.getItem('lang') == 'es'){
        this.translate.use('es');
      }else{
        this.translate.use('en');
      }
    }

    if(this.data[1]){
      if (Date.now() >= this.data[1] * 1000) {
        this.authService.logoutUsuario();
        this.logean = false;
        this.navFix = false;
      }
    }
   
    this.authService.userLogin.subscribe( data => {
      if(data.data){
        this.searchToken();
        this.logean = true;
        this.navFix = true;
      }else{
        this.imageProfile = 'https://api.dosiscl.com/uploads/no-image.png';
        this.logean = false;
        this.navFix = false;
      }
    })

    this.apiService.changeRequest.subscribe( data => {
      if(data.data){
        this.readRequest();
      }
    })

    this.apiService.changeMessage.subscribe( data => {
      if(data.data){
        this.readMessage();
      }
    })

    let urll = document.location.href;
    this.url = urll.split('/');

    if(this.url[3] == 'admin' || this.url[3] == 'login'){
      this.verif(true);
    }else{
      this.verif(false);
    }
 

    this.eventScroll();

  }

  get namePattern() {
    if (
      this.adForm.controls.name.errors &&
      this.adForm.controls.name.touched
    ) {
      if (this.adForm.controls.name.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get nameRequired() {
    if (
      this.adForm.controls.name.errors &&
      this.adForm.controls.name.touched
    ) {
      if (this.adForm.controls.name.errors.required) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get infoPattern() {
    if (
      this.adForm.controls.info.errors &&
      this.adForm.controls.info.touched
    ) {
      if (this.adForm.controls.info.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get infoRequired() {
    if (
      this.adForm.controls.info.errors &&
      this.adForm.controls.info.touched
    ) {
      if (this.adForm.controls.info.errors.required) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get countryPattern() {
    if (
      this.adForm.controls.country.errors &&
      this.adForm.controls.country.touched
    ) {
      if (this.adForm.controls.country.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get countryRequired() {
    if (
      this.adForm.controls.country.errors &&
      this.adForm.controls.country.touched
    ) {
      if (this.adForm.controls.country.errors.required) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get cityPattern() {
    if (
      this.adForm.controls.city.errors &&
      this.adForm.controls.city.touched
    ) {
      if (this.adForm.controls.city.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get cityRequired() {
    if (
      this.adForm.controls.city.errors &&
      this.adForm.controls.city.touched
    ) {
      if (this.adForm.controls.city.errors.required) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get emailPattern() {
    if (
      this.adForm.controls.email.errors &&
      this.adForm.controls.email.touched
    ) {
      if (this.adForm.controls.email.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get emailRequired() {
    if (
      this.adForm.controls.email.errors &&
      this.adForm.controls.email.touched
    ) {
      if (this.adForm.controls.email.errors.required) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get phonePattern() {
    if (
      this.adForm.controls.phone.errors &&
      this.adForm.controls.phone.touched
    ) {
      if (this.adForm.controls.phone.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get phoneRequired() {
    if (
      this.adForm.controls.phone.errors &&
      this.adForm.controls.phone.touched
    ) {
      if (this.adForm.controls.phone.errors.required) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }


  createForm(){
    this.adForm = this.fb.group({
      name: ['',[Validators.required,Validators.pattern(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/)]],
      info: ['',[Validators.required,Validators.pattern(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/)]],
      country: ['',[Validators.required,Validators.pattern(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/)]],
      city: ['',[Validators.required,Validators.pattern(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/)]],
      email: ['',[Validators.required,Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      phone: ['', [Validators.required,Validators.pattern(/^[0-9()+-]*$/)]]
    })
  }

  formAsesora(){
    this.openMenuMov();
  }

  /*
  safe(){
    const dialogRef = this.dialog.open(SafeComponent, {
      width:'700px'
    })
  }
  */

  openMenuMov(){
    if(this.navFix == false){
      this.navFix = !this.navFix;
    }
    this.openMenu = !this.openMenu;
  }

  openMenuDer(forma: number){
    switch (forma) {
      case 1:
        if(this.menuDer == false){
          this.menuDer = !this.menuDer;
        }
        this.forma1 = false;
 
        break;
    
      case 2:
        if(this.menuDer == false){
          this.menuDer = !this.menuDer;
        }
        this.forma1 = true;
        break;
      case 3:
        if(this.menuDer == true){
          this.menuDer = !this.menuDer;
        }
        break;
    }
  }

  subes(item:string){

    switch (item) {

      case 'nn':
        this.prop = false;
        this.idio = false;
        this.mone = false;
        this.admi = false;
        break;

      case 'prop':
        this.prop = true;
        this.idio = false;
        this.mone = false;
        this.admi = false;
        break;
    
      case 'idio':
        this.prop = false;
        this.idio = true;
        this.mone = false;
        this.admi = false;
        break;

      case 'mone':
        this.prop = false;
        this.idio = false;
        this.mone = true;
        this.admi = false;
        break;

      case 'admi':
        this.prop = false;
        this.idio = false;
        this.mone = false;
        this.admi = true;
        break;
    }

  }

  changeLang(lang: string){
    this.translate.use(lang);
    this.apiService.changeLang.emit({
      data: lang
    })
    localStorage.setItem('lang', lang);
  }

  readRequest(){
    this.requests = [];
    this.apiService.readAll('interested').subscribe(resp => {
      if(resp.results != 'Not Found'){
        this.requests = resp.results;
      }else{
        this.requests = [];
      }
      
    })
  }

  readMessage(){
    this.messages = [];
    this.apiService.readAll('advisers').subscribe(resp => {
      
      if(resp.results != 'Not Found'){
        this.messages = resp.results;
      }else{
        this.messages = [];
      }
    })
  }

  verif(data: boolean){

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    if(data){
      this.navFix = true;
      this.verify = true;
    }else{
      this.navFix = false;
      this.verify = false; 
    }
  }

  searchToken(){
    if(localStorage.getItem('token')){
      this.logean = true;
      this.data =Object.values(this.authService.getDecodedAccessToken( localStorage.getItem('token') || '' ));
      this.role = this.data[2].role;
      this.apiService.readUser(this.data[2].id).subscribe(resp=>{
        if(resp.results[0].image_user){
          this.imageProfile = resp.results[0].image_user;
        }
      })
    }
  }

  eventScroll(){
    var scrollPos = 0;
    window.addEventListener('scroll', () => {

      if((document.body.getBoundingClientRect()).top > scrollPos){
        
        this.direction = false;
      }else{
        this.direction = true;
      }
    
      scrollPos = (document.body.getBoundingClientRect()).top;

      if(this.url[3] != 'admin' || this.verify == false){
        const puntero = document.querySelector('.separator');
        this.puntero = puntero?.getBoundingClientRect();

        if(this.puntero != undefined){
          if(this.puntero.top <= 0){
            if(this.navFix != true){
              this.navFix = true;
            }
          }else{
              if(this.navFix != false){
                this.navFix = false;
              }
          }
        }
      }
    
    })
  }

  property(country:string){
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.authService.clickCountry.emit({
      data: country
    })
  }

  funko(funct:number, prop:string, bool?:boolean, vale?:number){
    
    switch (funct) {
      case 1:
        this.verif(bool);
        this.subes('nn');
        this.openMenuMov();
        break;
    
      case 2:
        this.property(prop);
        this.subes('nn');
        this.openMenuMov();
        break;

      case 3:
        this.changeLang(prop);
        this.subes('nn');
        this.openMenuMov();
        break;

      case 4:
        this.changeCurrency(vale);
        this.subes('nn');
        this.openMenuMov();
        break;

      case 5:
        this.verif(bool);
        this.subes('nn');
        if(this.openMenu == true){
          this.openMenuMov();
        }
        break;

      case 6:
        this.salir();
        this.subes('nn');
        if(this.openMenu == true){
          this.openMenuMov();
        }
        break;
    }

  }

  viewMessage(id: number){
    const dialogRef = this.dialog.open(ViewMessageComponent, {
      width:'700px',
      data: {
        id
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.readMessage();
      this.apiService.changeMessage2.emit({
        data: true
      })
    })
  }

  viewRequest(id:number){
    const dialogRef = this.dialog.open(ViewRequestComponent, {
      width:'700px',
      data: {
        id
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.readRequest();
      this.apiService.changeRequest2.emit({
        data: true
      })
    })
  }

  changeCurrency(divisa:number){
    this.authService.changeCurrency.emit({
      data: divisa
    })
  }

  salir(){
    this.logean = false;
    this.authService.logoutUsuario();
  }

  sendAdviser(type:number){
    if (this.adForm.invalid) {
      return Object.values(this.adForm.controls).forEach((control) => {
        control.markAsTouched();
      });
    }

    
    const dat = moment();

    this.adviser.name_adviser = this.adForm.controls.name.value;
    this.adviser.info_adviser = this.adForm.controls.info.value;
    this.adviser.country_adviser = this.adForm.controls.country.value;
    this.adviser.city_adviser = this.adForm.controls.city.value;
    this.adviser.email_adviser = this.adForm.controls.email.value;
    this.adviser.phone_adviser = this.adForm.controls.phone.value;
    this.adviser.ip_adviser = '';
    this.adviser.type_adviser = type;
    this.adviser.status_adviser = 1;
    this.adviser.id_user_adviser = 113;
    this.adviser.date_created_adviser = dat.format('YYYY-MM-DD');

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Enviando solicitud',
    });
    Swal.showLoading();

    this.apiService.sendAsesora(this.adviser).subscribe(resp => {

      let dataMail = [
        {
          email: this.adForm.controls.email.value,
          name: this.adForm.controls.name.value,
          code: 7
        }
      ]

      this.mailService.sendMail(dataMail).subscribe((resp2:any) => {
        Swal.close();
        this.adForm.reset({
          name: [''],
          info: [''],
          country: [''],
          city: [''],
          email: [''],
          phone: ['']
        })

        this.menuDer = !this.menuDer;
        
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: '¡Enviado!',
          text: 'Te responderemos a la brevedad posible',
          showConfirmButton: false,
          timer: 2000,
        })
      })

    },
    (error) => {
      this.errorMessage = error;

      if (this.errorMessage != null) {
        Swal.fire({
          title: 'error',
          icon: 'error',
          text: error.error.message,
        });
      }
    })
    
  }

}
