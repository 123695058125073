import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'requestHome'
})
export class RequestHomePipe implements PipeTransform {

  transform(value: any[], page: number = 0): unknown {
    if(!value){
      return null;
    }

    console.log(value);

    value = value.reverse();
    
    return value.slice(page, page + 5);
    
  }

}
