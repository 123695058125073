import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserModel } from 'src/app/shared/models/user';
import { ApiService } from 'src/app/shared/services/api.service';
import Swal from 'sweetalert2';
import { UsersService } from '../shared/services/users.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  public userForm!: FormGroup;
  public user: UserModel = new UserModel();
  public countries: any[] = [];
  public roles: any[] = [];

  constructor( private apiService: ApiService,
               private userService: UsersService,
               private fb: FormBuilder,
               public dialogRef:MatDialogRef<EditUserComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any ) { 
                this.userForm = fb.group({
                  email: data.email,
                  name: data.name,
                  lastname: data.lastname,
                  dni: data.dni,
                  role: data.role,
                  position: data.position,
                  phone: data.phone,
                  country: data.country,
                  commune: data.commune,
                  brokerCode: data.brokerCode,
                  userCode: data.userCode
                })
              }

  ngOnInit(): void {

    this.readCountries();
    this.readRoles();

    this.userForm.setValue({
      email: this.userForm.controls.email.value,
      name: this.userForm.controls.name.value,
      lastname: this.userForm.controls.lastname.value,
      dni: this.userForm.controls.dni.value,
      role: this.userForm.controls.role.value,
      position: this.userForm.controls.position.value,
      phone: this.userForm.controls.phone.value,
      country: this.userForm.controls.country.value,
      commune: this.userForm.controls.commune.value,
      brokerCode: this.userForm.controls.brokerCode.value,
      userCode: this.userForm.controls.userCode.value
    })

  }

  readCountries(){
    this.apiService.readAll('countries').subscribe(resp => {
      this.countries = resp.results;
    })
  }

  readRoles(){
    this.apiService.readAll('roles').subscribe(resp => {
      this.roles = resp.results;
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  editUser(){
    if (this.userForm.invalid) {
      return Object.values(this.userForm.controls).forEach((control) => {
        control.markAllAsTouched();
      });
    }

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor',
    });
    Swal.showLoading();

    this.user.email_user = this.userForm.controls.email.value;
    this.user.name_user = this.userForm.controls.name.value;
    this.user.lastname_user = this.userForm.controls.lastname.value;
    this.user.dni_user = this.userForm.controls.dni.value;
    this.user.id_role_user = this.userForm.controls.role.value;
    this.user.position_user = this.userForm.controls.position.value;
    this.user.phone_user = this.userForm.controls.phone.value;
    this.user.id_country_user = this.userForm.controls.country.value;
    this.user.commune_user = this.userForm.controls.commune.value;
    this.user.brokerCode_user = this.userForm.controls.brokerCode.value;

    this.userService.editUser(this.user, this.data.id).subscribe(resp => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'El usuario ha sido editado con éxito',
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        this.dialogRef.close();
      });
    })
  }

}
