<form [formGroup]="dataProperty" (ngSubmit)="editProperty()">
  <mat-dialog-content>
    <mat-horizontal-stepper #stepper>
      <mat-step>
        <ng-template matStepLabel>Info</ng-template>
        <div class="row mt-3">
          <div class="col-12 col-lg-4">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Selecciona la operación</mat-label>
              <mat-select formControlName="operation">
                <mat-option value="{{operation.id_operation}}" *ngFor="let operation of operations">
                  {{operation.namees_operation}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-4">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Selecciona el tipo de propiedad</mat-label>
              <mat-select formControlName="estate">
                <mat-option value="{{estate.id_estate}}" *ngFor="let estate of estates">{{estate.namees_estate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-4">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Rol</mat-label>
              <input matInput formControlName="rol">
            </mat-form-field>
          </div>
          <div class="col-12">
            <!--<span *ngIf="descPattern" class="text-danger"> Evite escribir caracteres especiales.</span>-->
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Título de propiedad</mat-label>
              <input matInput formControlName="description">
              <mat-hint align="start">Cambiar - <b>URL: https://www.batista-properties.com/property/{{link}}</b></mat-hint>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-3 mt-lg-3">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Valor</mat-label>
              <input matInput type="number" formControlName="value">
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-3 mt-lg-3">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Tipo de moneda</mat-label>
              <mat-select formControlName="payment">
                <mat-option value="{{payment.id_payment}}" *ngFor="let payment of payments">{{payment.namees_payment}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-2 mt-lg-3">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Destacar</mat-label>
              <mat-select formControlName="highlight">
                <mat-option value="0">No</mat-option>
                <mat-option value="1">Si</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-2 mt-lg-3">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Subsidio</mat-label>
              <mat-select formControlName="subsidy">
                <mat-option value="0">No</mat-option>
                <mat-option value="1">Si</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-2 mt-lg-3">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Canje</mat-label>
              <mat-select formControlName="exchange">
                <mat-option value="0">No</mat-option>
                <mat-option value="1">Si</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-3 valuer me-lg-2 mt-2" *ngIf="dolar">
            <p>{{rDolar | currency:'USD ':'code'}}</p>
          </div>
          <div class="col-12 col-lg-3 valuer me-lg-2 mt-2" *ngIf="pesos">
            <p>{{rPesos | currency:'CLP ':'code':'4.0'}}</p>
          </div>
          <div class="col-12 col-lg-3 valuer me-lg-2 mt-2" *ngIf="uf">
            <p>{{rUf | currency:'UF ':'code'}}</p>
          </div>
        </div>
        <div class="mt-3">
          <button type="button" mat-raised-button matStepperNext>Siguiente</button>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Descripción</ng-template>
        <div class="row mt-3">
          <div class="col-12">
            <label for="">Descripción corta</label>
            <ckeditor [editor]="Editor" data="" formControlName="desc_short"></ckeditor>
          </div>
          <div class="col-12 mt-3">
            <label for="">Descripción Principal</label>
            <ckeditor [editor]="Editor" data="" formControlName="desc_prin"></ckeditor>
          </div>
        </div>
        <div class="mt-3">
          <button type="button" mat-raised-button matStepperNext>Siguiente</button>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Ubicación</ng-template>
        <div class="row mt-3">
          <div class="col-12 col-lg-4">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>País</mat-label>
              <mat-select formControlName="country">
                <mat-option value="{{country.id_country}}" *ngFor="let country of countries">{{country.namees_country}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-4">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Región</mat-label>
              <mat-select formControlName="region">
                <mat-option value="{{region.id_region}}" *ngFor="let region of regions">{{region.name_region}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-4">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Comuna</mat-label>
              <mat-select formControlName="commune">
                <mat-option value="{{commune.id_commune}}" *ngFor="let commune of communes">{{commune.name_commune}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-8 mt-lg-3">
            <!--<span *ngIf="sectorPattern" class="text-danger"> Evite escribir caracteres especiales.</span>-->
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Sector</mat-label>
              <input matInput formControlName="sector">
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-2 mt-lg-3">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Geografía</mat-label>
              <mat-select formControlName="geography">
                <mat-option value="{{geography.id_geography}}" *ngFor="let geography of geographies">
                  {{geography.namees_geography}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-2 mt-lg-3">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Mostar Mapa</mat-label>
              <mat-select formControlName="map">
                <mat-option value="0">No</mat-option>
                <mat-option value="1">Si</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 mt-3">
            <mat-slide-toggle formControlName="modMap"> ¿Modificar marcadores en el mapa?</mat-slide-toggle>
          </div>
          <div class="col-12" *ngIf="map">
            <agm-map (mapClick)="addMarker( $event )" [latitude]="lat" [longitude]="lng" [zoom]="13">
              <agm-marker *ngFor="let marker of markers; let i = index" [latitude]="marker.lat"
                [longitude]="marker.lng">
                <agm-info-window>
                  <strong>{{ marker.title }}</strong>
                  <p>{{ marker.desc }}</p>

                  <div>
                    <button type="button" mat-raised-button color="primary" (click)="editMarker( marker )">Editar</button>
                    <button type="button" mat-raised-button color="warn" class="ms-2" (click)="delMarker(i)">Borrar</button>
                  </div>
                </agm-info-window>
              </agm-marker>
            </agm-map>
          </div>
        </div>
        <div class="mt-3">
          <button type="button" mat-raised-button matStepperNext>Siguiente</button>
        </div>
      </mat-step>
      
      <mat-step>
        <ng-template matStepLabel>Programa</ng-template>
        <div class="row mt-3">
          <div class="col-12 col-lg-4">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Terreno</mat-label>
              <input matInput type="number" formControlName="ground">
              <mat-hint align="start">mt2</mat-hint>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-2">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Construido</mat-label>
              <input matInput type="number" formControlName="built">
              <mat-hint align="start">mt2</mat-hint>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-2">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Habitaciones</mat-label>
              <input matInput type="number" formControlName="bedrooms">
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-2">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Baños</mat-label>
              <input matInput type="number" formControlName="bathing">
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-2">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Estacionamientos</mat-label>
              <input matInput type="number" formControlName="parking">
            </mat-form-field>
          </div>
          <!--
        <div class="col-12 mt-3">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Descripción complementaria de la propiedad</mat-label>
            <textarea matInput formControlName="description2"></textarea>
          </mat-form-field>
        </div>
        -->
          <div class="col-12 col-lg-6 mt-3">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Propietario</mat-label>
              <mat-select formControlName="owner">
                <mat-option value="{{owner.id_owner}}" *ngFor="let owner of owners">{{owner.name_owner}}
                  {{owner.lastname_owner}}</mat-option>
              </mat-select>
              <mat-hint align="end" class="cpointer great" (click)="addOwner()">+ Agregar Propietario</mat-hint>
            </mat-form-field>
          </div>

          <div class="col-12 col-lg-6 mt-3">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Estado</mat-label>
              <mat-select formControlName="status">
                <mat-option value="0">EN BORRADOR</mat-option>
                <mat-option value="1">PUBLICADO</mat-option>
                <mat-option value="2">VENDIDO</mat-option>
                <mat-option value="3">ARRENDADO</mat-option>
                <mat-option value="4">BAJA</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-lg-6 mt-3 align-self-center">
            <div class="row">
              <div class="col-12">
                <button type="button" mat-raised-button color="primary" class="w-100" (click)="chooseAmenities()">Amenidades</button>
              </div>
              <div class="col-12 mt-2">
                <small class="m-0">{{qAmenity}}</small> 
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-6 mt-lg-3 align-self-center">
            <mat-slide-toggle formControlName="noval"> Agregar mobiliario y gestión hotelera.</mat-slide-toggle>
          </div>

        </div>
        <div class="mt-3">
          <button type="button" mat-raised-button matStepperNext>Siguiente</button>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Imagenes</ng-template>
        <div class="row mt-3">
          <div class="col-12 imagesDash">
            <div class="row">
              <div class="col-12 col-lg-3 imageEdit" *ngFor="let image of images; let i = index">
                <span class="imageDel text-center" (click)="imageDel(i)"><i class="fa-solid fa-xmark"></i></span>
                <img src="{{image.url}}" class="w-100" alt="">
                <!--<img src="https://api.dosiscl.com/uploads/no-image.png" class="w-100" alt="">-->
              </div>
              <div class="col-12 col-lg-3 imageAdd" (click)="fileInput.click()">
                <span class="text-center"><i class="fa-solid fa-circle-plus fa-2x"></i> <br>
                  <p class="mt-2">Agregar imágenes</p>
                </span>
                <input #fileInput multiple type="file" (change)="onSelectFile($event)" style="display:none;" />
              </div>
            </div>
          </div>
          <div class="col-4 mt-3">
            <!--
          <button type="button" mat-raised-button color="primary" (click)="fileInput.click()" class="w-100">
            <span><i class="fas fa-cloud-upload-alt mr-3"></i> Subir Imagen</span>
            <input #fileInput type="file" (change)="onSelectFile($event)" style="display:none;"
              formControlName="image" />
          </button>
        -->
          </div>

        </div>
        <div class="mt-3">
          <button type="button" mat-raised-button matStepperPrevious>Anterior</button>
          <button type="submit" mat-raised-button color="primary" class="ms-2">Guardar</button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-dialog-content>
</form>
