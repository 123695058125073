import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ApiService } from '../shared/services/api.service';
import { ImageService } from '../shared/services/image.service';
import { ServicsService } from './shared/services/servics.service';

@Component({
  selector: 'app-admin-services',
  templateUrl: './admin-services.component.html',
  styleUrls: ['./admin-services.component.css'],
})
export class AdminServicesComponent implements OnInit {
  public services: any[] = [];
  public errorMessage = '';

  constructor(
    private apiService: ApiService,
    private servService: ServicsService,
    private imgService: ImageService
  ) {}

  ngOnInit(): void {
    this.readServices();
  }

  readServices() {
    this.apiService.readAll('services').subscribe((resp) => {
      resp.results.forEach((resp2) => {
        if (resp2.image_service != '[]') {
          let proper = JSON.parse(resp2.image_service);
          resp2.image2_service = proper[0].url;
        } else {
          resp2.image2_service = 'https://api.dosiscl.com/uploads/no-image.png';
        }
      });
      this.services = resp.results;
    });
  }

  deleteService(id: number) {

    let index = this.services
      .map((img) => img.id_service)
      .indexOf(id);

    Swal.fire({
      title: 'Estas seguro que deseas eliminar?',
      text: 'Si eliminas no recuperarás esta propiedad!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          allowOutsideClick: false,
          icon: 'info',
          text: 'Eliminando Servicio. Espere por favor',
        });
        Swal.showLoading();

        this.servService.deleteService(id).subscribe(
          (resp) => {

            let imageDel = JSON.parse(this.services[index].image_service);

            this.imgService.deleteImage(imageDel, 'services');

            Swal.close();

            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'El servicio ha sido eliminado',
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              this.readServices();
            });
          },
          (error) => {
            this.errorMessage = error;

            if (this.errorMessage != null) {
              Swal.fire({
                title: 'error',
                icon: 'error',
                text: error.error.message,
              });
            }
          }
        );
      }
    });
  }
}
