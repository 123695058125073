import { Injectable } from '@angular/core';

import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { getStorage, ref, deleteObject } from "firebase/storage";
import { environment } from 'src/environments/environment';

firebase.initializeApp(environment.firebaseConfig);

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  storageRef= firebase.app().storage().ref();

  constructor() { }

  async deleteImage(name:any, table:string){

    for await (const image of name.map((img:any) => img)) {
      
      const storage = getStorage();
      const desertRef = ref(storage,table+'/'+image.name);

      await deleteObject(desertRef).then(() => {
        //console.log('si');
      }).catch((error) => {
        //console.log('no');
      })
    }
    
  }

  async deleteImageBlog(name: any, table:string){

    const storage = getStorage();
    const desertRef = ref(storage,table+'/'+name);

    await deleteObject(desertRef).then(() => {
      //console.log('si');
    }).catch((error) => {
      //console.log('no');
    })
  }

  async intoImages( id: number, images: any, table: string ){

    let datos = [];

    for await (const image of images.map((img: any) => img)) {
     
      let data = id.toString() + '_' + Date.now();
      await this.uploadImage(data, image, table).then((urlImage) => {
        datos.push({name: data, url: urlImage});
      })
     
    }
    return datos;
  }

  async uploadImage( name: string, imgBase64: any, table: string ){

    try{
      let response = await this.storageRef.child(table+"/"+name).putString(imgBase64, 'data_url');
      return await response.ref.getDownloadURL();
    }catch(err){
      return null;
    }

  }

}
