import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { PropertiesModel } from 'src/app/shared/models/properties';
import Swal from 'sweetalert2';
import { Markers } from '../shared/classes/markers.class';
import { EditMapPropertyComponent } from '../edit-map-property/edit-map-property.component';

import { PropertiesService } from '../shared/services/properties.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { ImageService } from 'src/app/shared/services/image.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AddOwnerComponent } from 'src/app/admin-owners/add-owner/add-owner.component';
import { ChooseAmenitiesComponent } from '../choose-amenities/choose-amenities.component';

@Component({
  selector: 'app-edit-property',
  templateUrl: './edit-property.component.html',
  styleUrls: ['./edit-property.component.css']
})
export class EditPropertyComponent implements OnInit {

  lat = -33.45694;
  lng = -70.64827;

  public dataProperty!: FormGroup;
  public urlImage: any = '';

  public pesos: boolean = false;
  public dolar: boolean = false;
  public uf: boolean = false;
  public vPesos = 1;
  //public vDolar = 900.90;
  //public vUf = 33821.34;
  public vDolar = 0;
  public vUf = 0;
  public rPesos = 0;
  public rDolar = 0;
  public rUf = 0;

  public valueFinal = 0;
  public guarda: any[] = [];

  public markers: Markers[] = [];
  public property: PropertiesModel = new PropertiesModel();
  public errorMessage = '';
  public operations: any[] = [];
  public estates: any[] = [];
  public payments: any[] = [];
  public countries: any[] = [];
  public owners: any[] = [];
  public geographies: any[] = [];
  public regions: any[] = [{ id_region: '0', name_region: 'Sin Región' }];
  public communes: any[] = [{ id_commune: '0', name_commune: 'Sin Comuna' }];
  public communesMap: any[] = [];
  public images: any[] = [];
  public imagesUp: any[] = [];
  public imagesDel: any[] = [];
  public archives: any[] = [];
  public map: boolean = false;
  public Editor = ClassicEditor;
  public link = '';
  public qAmenity = 'Sin amenidades seleccionadas';
  public amenitiesList: any[] = [];
  public noval = 0;


  constructor( public dialogRef: MatDialogRef<EditPropertyComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any,
               public fb: FormBuilder,
               public propertyService: PropertiesService,
               public imgService: ImageService,
               public apiService: ApiService,
               public authService: AuthService,
               public dialog: MatDialog,
               public snackBar: MatSnackBar ) {

                this.link = data.url;

                this.amenitiesList = JSON.parse(data.amenities);
                this.noval = data.noval;
                if(data.noval == 1){
                  data.noval = true;
                }else{
                  data.noval = false;
                }
                this.qAmenity = `${this.amenitiesList.length} Amenidades seleccionadas`;
                
                this.dataProperty = fb.group({
                  operation: data.operation,
                  estate: data.estate,
                  description: data.description,
                  rol: data.rol,
                  value: data.value,
                  payment: data.payment,
                  highlight: data.highlight,
                  subsidy: data.subsidy,
                  exchange: data.exchange,
                  desc_short: data.desc_short,
                  desc_prin: data.desc_prin,
                  url: data.url,
                  country: data.country,
                  region: data.region,
                  commune: data.commune,
                  sector: data.sector,
                  geography: data.geography,
                  map: data.map,
                  markers: data.markers,
                  ground: data.ground,
                  built: data.built,
                  bedrooms: data.bedrooms,
                  bathing: data.bathing,
                  parking: data.parking,
                  description2: data.description2,
                  noval: data.noval,
                  owner: data.owner,
                  image: data.image,
                  status: data.status,
                  modMap: false
                });
                this.markers = JSON.parse(data.markers);
                this.guarda = JSON.parse(data.value);
                let index = this.guarda.map((div) => div.div).indexOf(data.payment.toString());
                this.divisa(this.guarda[index].value,data.payment);
                this.valueFinal = this.guarda[index].value;

              }

  ngOnInit(): void {
    this.urlImage = JSON.parse(this.data.image);
    this.images = JSON.parse(this.data.image);
    
    /*
    if(this.urlImage.length == 0){
      this.urlImage.push('https://api.dosiscl.com/uploads/no-image.png');
    }
    */

    this.readOperations();
    this.readEstates();
    this.readPayments();
    this.readCountries();
    this.readCommunes();
    this.readOwners();
    this.readGeographies();
    this.changeCountry();
    this.cargarData();
    
  }

  cargarData(){
    this.dataProperty.setValue({
      operation: this.dataProperty.controls.operation.value,
      estate: this.dataProperty.controls.estate.value,
      description: this.dataProperty.controls.description.value,
      rol: this.dataProperty.controls.rol.value,
      value: this.valueFinal,
      payment: this.dataProperty.controls.payment.value,
      highlight: this.dataProperty.controls.highlight.value,
      subsidy: this.dataProperty.controls.subsidy.value,
      exchange: this.dataProperty.controls.exchange.value,
      desc_short: this.dataProperty.controls.desc_short.value,
      desc_prin: this.dataProperty.controls.desc_prin.value,
      url: this.link,
      country: this.dataProperty.controls.country.value,
      region: this.dataProperty.controls.region.value,
      commune: this.dataProperty.controls.commune.value,
      sector: this.dataProperty.controls.sector.value,
      geography: this.dataProperty.controls.geography.value,
      map: this.dataProperty.controls.map.value,
      markers: this.dataProperty.controls.markers.value,
      ground: this.dataProperty.controls.ground.value,
      built: this.dataProperty.controls.built.value,
      bedrooms: this.dataProperty.controls.bedrooms.value,
      bathing: this.dataProperty.controls.bathing.value,
      parking: this.dataProperty.controls.parking.value,
      description2: this.dataProperty.controls.description2.value,
      owner: this.dataProperty.controls.owner.value,
      noval: this.dataProperty.controls.noval.value,
      image: this.dataProperty.controls.image.value,
      status: this.dataProperty.controls.status.value,
      modMap: false
    })

    
  }

  addMarker(event: any) {
    const coords: { lat: number; lng: number } = event.coords;
    const titleMarker: string = this.dataProperty.get('description').value;
    const newMarker = new Markers(coords.lat, coords.lng, titleMarker);
    this.markers.push(newMarker);

    this.saveStorage();
    this.snackBar.open('Marcador agregado', 'Cerrar', { duration: 3000 });
  }

  delMarker(i: number) {
    this.markers.splice(i, 1);
    this.saveStorage();
    this.snackBar.open('Marcador eliminado', 'Cerrar', { duration: 3000 });
  }

  editMarker(marker: Markers) {
    const dialogRef = this.dialog.open(EditMapPropertyComponent, {
      width: '250px',
      data: { title: marker.title, desc: marker.desc },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      marker.title = result.title;
      marker.desc = result.desc;

      this.saveStorage();
      this.snackBar.open('Marcador actualizado', 'Cerrar', { duration: 3000 });
    });
  }

  saveStorage() {
    localStorage.setItem('markers', JSON.stringify(this.markers));
  }

  readOperations(){
    this.apiService.readAll('operations').subscribe((resp) => {
      this.operations = resp.results;
    });
  }


  readEstates(){
    this.apiService.readAll('estates').subscribe((resp) => {
      this.estates = resp.results;
    });
  }

  readPayments(){
    this.apiService.readAll('payments').subscribe((resp) => {
      this.payments = resp.results;
    });
  }

  readCountries(){
    this.apiService.readAll('countries').subscribe((resp) => {
      this.countries = resp.results;
    });
  }

  readCommunes(){
    this.apiService.readAll('communes').subscribe((resp) => {
      let comme = resp.results;
      const index = comme.findIndex(
        (element) => element.id_commune === this.data.commune
      );

      let comuns = resp.results[index];
      this.lat = parseFloat(comuns.lat_commune);
      this.lng = parseFloat(comuns.lng_commune);
    });
  }

  readOwners(){
    this.apiService.readAll('owners').subscribe((resp) => {
      this.owners = resp.results;
    });
  }

  readGeographies(){
    this.apiService.readAll('geographies').subscribe((resp) => {
      this.geographies = resp.results;
    });
  }

  changeCountry(){
    this.dataProperty.controls.country.valueChanges.subscribe((valor) => {
      this.propertyService.searchRegion(valor).subscribe((resp) => {
        const filterReg = resp.results.filter(com => com.id_country_region == valor);
        this.regions = filterReg;
      });
    });

    this.dataProperty.controls.region.valueChanges.subscribe((valor2) => {
      this.propertyService.searchCommune(valor2).subscribe((resp2) => {
        const filterCom = resp2.results.filter(com => com.id_region_commune == valor2);
        this.communes = filterCom;
      });
    });

    this.dataProperty.controls.modMap.valueChanges.subscribe((valor3) => {
      this.map = valor3;
    });

    this.dataProperty.controls.commune.valueChanges.subscribe((valor4) => {
      if(this.communes.length > 1){
        const index = this.communes.findIndex(
          (element) => element.id_commune === valor4
        );
  
        let comuns = this.communes[index];
  
        this.lat = parseFloat(comuns.lat_commune);
        this.lng = parseFloat(comuns.lng_commune);
      }
    });

    this.dataProperty.controls.payment.valueChanges.subscribe((valor5) => { this.divisa(null,valor5);})

    this.dataProperty.controls.value.valueChanges.subscribe((valor6) => {
      this.divisa(valor6,'');
    })

    this.dataProperty.controls.noval.valueChanges.subscribe((resp) => {
      if(resp == true){
        this.noval = 1;
      }else{
        this.noval = 0;
      }
    })
  }

  onSelectFile(event):any{
    this.archives = event.target.files;

    for (let i=0; i<this.archives.length; i++){
      const reader = new FileReader();
      reader.readAsDataURL(this.archives[i]);
      reader.onloadend = () => {
        this.imagesUp.push(reader.result);
        this.images.push({name:'',url:reader.result});
      }
    }
  }

  imageDel(i:number){
    this.imagesDel.push(this.images[i]);
    this.images.splice(i,1);
  }

  divisa(cantidad: number, divisa: string){
    const vale = this.dataProperty.controls.value.value;
    const dive = this.dataProperty.controls.payment.value;

    if(!cantidad){
      cantidad = vale;
    }
    
    if(!divisa){
      divisa = dive.toString();
    }

    if(this.vDolar == 0 || this.vUf == 0){
      this.authService.readEconomic('dolar').subscribe(resp => {
        let dollar = resp.Dolares;
        let nDol = dollar[0].Valor.replace(',', '.');
        this.vDolar = Number(nDol);
  
        this.authService.readEconomic('uf').subscribe(resp => {
          let uf = resp.UFs;
          let nUF1 = uf[0].Valor.replace('.', '');
          let nUF = nUF1.replace(',', '.');
          this.vUf = Number(nUF);
          
          switch (divisa) {
            case '1':
              this.dolar = true;
              this.uf = true;
              this.pesos = false;
              this.rDolar = cantidad * (1 / this.vDolar); //0.0011;
              this.rUf = cantidad * (1 / this.vUf);//0.000030; // valor real
              break;
          
            case '2':
              this.dolar = true;
              this.pesos = true;
              this.uf = false;
              this.rDolar = cantidad * (this.vUf / this.vDolar);//37.54;
              this.rPesos = cantidad * this.vUf; // valor real
              break;
            
            case '3':
              this.pesos = true;
              this.uf = true;
              this.dolar = false;
              this.rPesos = cantidad * this.vDolar;  // valor real
              this.rUf = cantidad * (this.vDolar / this.vUf);//0.027;
              break;
            
            default:
              this.pesos = false;
              this.uf = false;
              this.dolar = false;
              break;
          }
        })
      })
    }else{
      switch (divisa) {
        case '1':
          this.dolar = true;
          this.uf = true;
          this.pesos = false;
          this.rDolar = cantidad * (1 / this.vDolar); //0.0011;
          this.rUf = cantidad * (1 / this.vUf);//0.000030; // valor real
          break;
      
        case '2':
          this.dolar = true;
          this.pesos = true;
          this.uf = false;
          this.rDolar = cantidad * (this.vUf / this.vDolar);//37.54;
          this.rPesos = cantidad * this.vUf; // valor real
          break;
        
        case '3':
          this.pesos = true;
          this.uf = true;
          this.dolar = false;
          this.rPesos = cantidad * this.vDolar;  // valor real
          this.rUf = cantidad * (this.vDolar / this.vUf);//0.027;
          break;
        
        default:
          this.pesos = false;
          this.uf = false;
          this.dolar = false;
          break;
      }
    }
    
  }

  chooseAmenities(){
    const dialogRef = this.dialog.open(ChooseAmenitiesComponent, {
      width: '700px',
      data: {
        data: this.amenitiesList
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined){
        this.amenitiesList = result;
        this.qAmenity = `${result.length} Amenidades seleccionadas`;
      }
    })
  }

  addOwner(){
    const dialogRef = this.dialog.open(AddOwnerComponent,{
      width: '700px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.readOwners();
    })
  }

  editProperty(){
    
    
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando cambios. Espere por favor',
    });
    Swal.showLoading();

    //this.property.id_user_property = this.data.id_user_property;
    this.property.id_operation_property = this.dataProperty.controls.operation.value;
    this.property.id_estate_property = this.dataProperty.controls.estate.value;
    this.property.description_property = this.dataProperty.controls.description.value;
    this.property.rol_property = this.dataProperty.controls.rol.value;
    
    this.property.id_payment_property = this.dataProperty.controls.payment.value;
    this.property.highlight_property = this.dataProperty.controls.highlight.value;
    this.property.subsidy_property = this.dataProperty.controls.subsidy.value;
    this.property.exchange_property = this.dataProperty.controls.exchange.value;
    this.property.description_short_property = this.dataProperty.controls.desc_short.value;
    this.property.description_prin_property = this.dataProperty.controls.desc_prin.value;
    this.property.url_property = this.link;
    this.property.id_country_property = this.dataProperty.controls.country.value;
    this.property.id_region_property = this.dataProperty.controls.region.value;
    this.property.id_commune_property = this.dataProperty.controls.commune.value;
    this.property.sector_property = this.dataProperty.controls.sector.value;
    this.property.id_geography_property = this.dataProperty.controls.geography.value;
    this.property.map_property = this.dataProperty.controls.map.value;
    this.property.markers_property = JSON.stringify(this.markers);
    this.property.ground_property = this.dataProperty.controls.ground.value;
    this.property.built_property = this.dataProperty.controls.built.value;
    this.property.bedrooms_property = this.dataProperty.controls.bedrooms.value;
    this.property.bathing_property = this.dataProperty.controls.bathing.value;
    this.property.parking_property = this.dataProperty.controls.parking.value;
    //this.property.description2_property = this.dataProperty.controls.description2.value;
    this.property.status_property = this.dataProperty.controls.status.value;
    this.property.noval_property = this.noval;
    this.property.id_owner_property = this.dataProperty.controls.owner.value;
    this.property.amenities_property = JSON.stringify(this.amenitiesList);

    switch (this.dataProperty.controls.payment.value) {
      case '1':
        this.guarda[0].value = this.dataProperty.controls.value.value.toString();
        this.guarda[1].value = this.rUf.toString()
        this.guarda[2].value = this.rDolar.toString()
        break;
      case '2':
        this.guarda[0].value = this.rPesos.toString()
        this.guarda[1].value = this.dataProperty.controls.value.value.toString();
        this.guarda[2].value = this.rDolar.toString()
        break;
      case '3':
        this.guarda[0].value = this.rPesos.toString()
        this.guarda[1].value = this.rUf.toString()
        this.guarda[2].value = this.dataProperty.controls.value.value.toString();
        break;
    }
    
    this.property.value_property = JSON.stringify(this.guarda);

    this.propertyService.editProperty2(this.property,this.data.id).subscribe(
      (resp) => {
        if(this.imagesDel.length > 0){

          this.imgService.deleteImage(this.imagesDel,'properties2');
          this.imagesDel.forEach(element => {
           
            let indexDel = this.urlImage.map((url) => url.url).indexOf(element.url);
            
            if(indexDel >= 0){
              this.urlImage.splice(indexDel,1);
            }
           
          });
        }
        if(this.imagesUp.length > 0){
          this.imgService.intoImages(this.data.id, this.imagesUp, 'properties2').then((urlImage) => {

            urlImage.forEach((resp5:any) =>{
              this.urlImage.push(resp5);
            })
         
            let urlImage2 = JSON.stringify(this.urlImage);

            this.propertyService.editProperty('image_property', urlImage2, this.data.id).subscribe((resp3:any) => {
              Swal.close();

              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Propiedad editada con éxito',
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.dialogRef.close();
              });
            })
          })
        }else{
          let imgU = JSON.stringify(this.urlImage);
          this.propertyService.editProperty('image_property', imgU,this.data.id).subscribe((resp3:any) => {
            Swal.close();

              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Propiedad editada con éxito',
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.dialogRef.close();
              });
          })
        }
      },
      (error) => {
        this.errorMessage = error;

        if( this.errorMessage != null ){
          Swal.fire({
            title: 'error',
            icon: 'error',
            text: error.error.message
          });
        }
      }
    )
    
  }
}
