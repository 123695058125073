export class PropertiesModel {

    id_property?: number;
    id_user_property: number;
    id_operation_property: number;
    id_estate_property: number;
    description_property: string;
    description_short_property: string;
    description_prin_property: string;
    description_en_property: string;
    description_en_short_property: string;
    description_en_prin_property: string;
    url_property: string;
    rol_property: string;
    value_property: string;
    id_payment_property: number;
    highlight_property: number;
    subsidy_property: number;
    exchange_property: number;
    id_commune_property: number;
    id_region_property: number;
    id_country_property: number;
    sector_property: string;
    id_geography_property: number;
    markers_property: string;
    map_property: number;
    ground_property: number;
    built_property: number;
    bedrooms_property: number;
    bathing_property: number;
    parking_property: number;
    short_description_property: string;
    description2_property: string;
    id_owner_property: number;
    amenities_property: string;
    noval_property: number;
    image_property: string;
    status_property: number;
    date_created_property: string;


    constructor(){
        this.id_user_property = 0,
        this.id_operation_property = 0,
        this.id_estate_property = 0,
        this.description_property = '',
        this.description_short_property = "",
        this.description_prin_property = "",
        this.description_en_property = "",
        this.description_en_short_property = "",
        this.description_en_prin_property = "",
        this.url_property = '',
        this.rol_property = '',
        this.value_property = '',
        this.id_payment_property = 0,
        this.highlight_property = 0;
        this.subsidy_property = 0;
        this.exchange_property = 0;
        this.id_commune_property = 0,
        this.id_region_property = 0,
        this.id_country_property = 0,
        this.sector_property = '',
        this.id_geography_property = 0,
        this.map_property = 0;
        this.markers_property = '';
        this.ground_property = 0,
        this.built_property = 0,
        this.bedrooms_property = 0,
        this.bathing_property = 0,
        this.parking_property = 0,
        this.short_description_property = '',
        this.description2_property = '',
        this.id_owner_property = 0,
        this.amenities_property = '',
        this.noval_property = 0,
        this.image_property = '',
        this.status_property = 0,
        this.date_created_property = ''

    }
}