export class FaqModel {

    id_faq?: number;
    quest_faq: string;
    answ_faq: string;
    questen_faq: string;
    answen_faq : string;
    date_created_faq: string;


    constructor(){
        this.quest_faq = '',
        this.answ_faq = '',
        this.questen_faq = '',
        this.answen_faq = '',
        this.date_created_faq = ''
    }
}