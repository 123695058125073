export class CategoryModel {

    id_category?: number;
    name_category: string;
    namen_category: string;
    date_created_category: string;


    constructor(){
        this.name_category = '',
        this.namen_category = '',
        this.date_created_category = ''
    }
}