import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ApiService } from '../shared/services/api.service';
import { ViewRequestComponent } from './view-request/view-request.component';

@Component({
  selector: 'app-admin-request',
  templateUrl: './admin-request.component.html',
  styleUrls: ['./admin-request.component.css']
})
export class AdminRequestComponent implements OnInit {

  public interested: any[] = [];
  public page = 0;
  public pages = 0;
  public pages2 = 1;
  public errorMessage = '';

  constructor( private apiService: ApiService,
               private dialog: MatDialog ) { }

  ngOnInit(): void {
    this.readPropertiesInter();
  }

  readPropertiesInter(){
    this.apiService.readInterestRelations().subscribe(resp => {
      if(resp.results != 'Not Found'){
        resp.results.forEach(resp2 => {
          let proper = JSON.parse(resp2.image_property);
          resp2.image2_property = proper[0].url;
        })
        this.interested = resp.results;
        this.btnPaginate();
      }
      
    })
  }

  nextPage(){
    this.page += 5;
    this.pages2 += 1;
  }

  previousPage(){
    if(this.page > 0){
      this.page -= 5;
      this.pages2 -= 1;
    }  
  }

  btnPaginate(){
    let pag1 = this.interested.length / 5;
    this.pages = Math.ceil(pag1);
  }

  viewRequest(id:number){
    const dialogRef = this.dialog.open(ViewRequestComponent, {
      width:'700px',
      data: {
        id
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.readPropertiesInter();
      this.apiService.changeRequest2.emit({
        data: true
      })
    })
  }

  delRequest(id: number) {
    Swal.fire({
      title: 'Estas seguro que deseas eliminar?',
      text: 'Si eliminas no recuperarás esta solicitud!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.apiService.deleteReqMes('interested','interest',id).subscribe(
          (resp) => {
            Swal.close();

            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'La solicitud ha sido eliminado',
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              this.readPropertiesInter();
            });
          },
          (error) => {
            this.errorMessage = error;

            if (this.errorMessage != null) {
              Swal.fire({
                title: 'error',
                icon: 'error',
                text: error.error.message,
              });
            }
          }
        );
      }
    });
  }

}
