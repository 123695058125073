import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-message',
  templateUrl: './view-message.component.html',
  styleUrls: ['./view-message.component.css']
})
export class ViewMessageComponent implements OnInit {

  public errorMessage = '';
  public message: any;
  public tit = '';

  constructor( private apiService: ApiService,
               private authService: AuthService,
               public dialogRef:MatDialogRef<ViewMessageComponent>,
               @Inject(MAT_DIALOG_DATA) public data:any ) { }

  ngOnInit(): void {
    this.readMessage();
  }

  readMessage(){
    this.apiService.read('advisers', 'id_adviser', this.data.id).subscribe(resp => {
      this.message = resp.results[0];

      switch (this.message['type_adviser']) {
        case "1":
          this.tit = 'Asesora Comercial';
          break;
        case "2":
          this.tit = 'de Venta';
          break;
        case "3":
          this.tit = 'de Compra';
          break;
       
      }
    })
  }

  markedMessage(){
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor',
    });
    Swal.showLoading();

    let token = this.apiService.readTokenDecoded();
    
    let data = {
      status : '2',
      read: token[2].id
    }

    this.apiService.editMsg('advisers','adviser',data,this.data.id).subscribe(resp => {
      this.apiService.changeMessage.emit({
        data: true
      })
      Swal.close();
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Leído',
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        this.dialogRef.close();
      });
    })
  }

}
