import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ApiService } from '../shared/services/api.service';

@Component({
  selector: 'app-admin-faq',
  templateUrl: './admin-faq.component.html',
  styleUrls: ['./admin-faq.component.css']
})
export class AdminFaqComponent implements OnInit {

  public faqs: any[] = [];
  public errorMessage = '';

  constructor( private apiSer : ApiService ) { }

  ngOnInit(): void {
    this.readFaqs(); 
  }

  readFaqs(){
    this.apiSer.readAll('faqs').subscribe(resp => {
      if(resp.results != 'Not Found'){
        this.faqs = resp.results;
      }
    })
  }

  delFaq(id:number){
    Swal.fire({
      title: 'Estas seguro que deseas eliminar?',
      text: 'Si eliminas no recuperarás esta pregunta!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
    }).then((result) => {
      if (result.isConfirmed) {

        this.apiSer.deleteReqMes('faqs','faq',id).subscribe(resp => {
          Swal.close();
      
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'La pregunta ha sido eliminada',
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.readFaqs();
          });
        },
        (error) => {
          this.errorMessage = error;
      
          if (this.errorMessage != null) {
            Swal.fire({
              title: 'error',
              icon: 'error',
              text: error.error.message,
            });
          }    
        }); 
      }
    });
  }

}
