import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { AsesoraModel } from '../shared/models/asesora';
import { ApiService } from '../shared/services/api.service';
import { AuthService } from '../shared/services/auth.service';
import { MailService } from '../shared/services/mail.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public aseForm!: FormGroup;
  public asesora: AsesoraModel = new AsesoraModel();
  public properties: any[] = [];
  public proDests: any[] = [];
  public divisa = 0;
  public errorMessage = '';
  public parametro = '';

  public instagram = '';
  public facebook = '';
  public whatsapp = '';

  public meas = 1;

  constructor( private apiService: ApiService,
               private authService: AuthService,
               private mailService: MailService,
               private fb: FormBuilder,
               private _activatedRoute: ActivatedRoute ) {

                if(localStorage.getItem('meas')){
                  this.meas = Number(localStorage.getItem('meas'));
                }

                this.divisa = authService.readCurrency();

                authService.changeCurrency.subscribe(data => {
                  if(data.data != this.divisa){
                    this.divisa = data.data;
                    this.properties = [];
                    authService.changeCurrencyS(this.divisa);
                    this.readProperties();
                  }
                })
              }

  ngOnInit(): void {
    this.readSocial();
    this.readProperties();
    this.createForm();
  }

  readSocial(){
    this.apiService.readAll('redes').subscribe(resp =>{
      if(resp.results != 'Not Found'){
        resp.results.forEach(resp2 => {
          switch (resp2.name_red) {
            case 'instagram':
              this.instagram = resp2.url_red;  
              break;

            case 'facebook':
              this.facebook = resp2.url_red; 
              break;

            case 'whatsapp':
              this.whatsapp = resp2.url_red; 
              break;
          }
        })
      }
    })
  }

  readProperties(){
    this.apiService.readAll('properties2').subscribe(resp => {
      if(resp.results != 'Not Found'){
        this.properties = resp.results;
        resp.results.forEach(resp2=>{
          resp2.amenities2_property = JSON.parse(resp2.amenities_property);
          if(resp2.image_property != '[]'){
            let image = JSON.parse(resp2.image_property);
            resp2.image2_property = image[0].url;
          }else{
            resp2.image2_property = 'https://firebasestorage.googleapis.com/v0/b/batista-images.appspot.com/o/properties%2Fnn.jpg?alt=media&token=b1a6e800-1d4a-4a9e-af16-edc8b96c63b4';
          }
          
        })
      }
      
    })
  }

  get namePattern() {
    if (
      this.aseForm.controls.name.errors &&
      this.aseForm.controls.name.touched
    ) {
      if (this.aseForm.controls.name.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get nameRequired() {
    if (
      this.aseForm.controls.name.errors &&
      this.aseForm.controls.name.touched
    ) {
      if (this.aseForm.controls.name.errors.required) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get infoPattern() {
    if (
      this.aseForm.controls.info.errors &&
      this.aseForm.controls.info.touched
    ) {
      if (this.aseForm.controls.info.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get infoRequired() {
    if (
      this.aseForm.controls.info.errors &&
      this.aseForm.controls.info.touched
    ) {
      if (this.aseForm.controls.info.errors.required) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get emailPattern() {
    if (
      this.aseForm.controls.email.errors &&
      this.aseForm.controls.email.touched
    ) {
      if (this.aseForm.controls.email.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get emailRequired() {
    if (
      this.aseForm.controls.email.errors &&
      this.aseForm.controls.email.touched
    ) {
      if (this.aseForm.controls.email.errors.required) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get phonePattern() {
    if (
      this.aseForm.controls.phone.errors &&
      this.aseForm.controls.phone.touched
    ) {
      if (this.aseForm.controls.phone.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get phoneRequired() {
    if (
      this.aseForm.controls.phone.errors &&
      this.aseForm.controls.phone.touched
    ) {
      if (this.aseForm.controls.phone.errors.required) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  createForm(){
    this.aseForm = this.fb.group({
      name: ['',Validators.required],
      info: ['',Validators.required],
      email: ['',[Validators.required,Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      phone: ['', [Validators.required,Validators.pattern(/^[0-9()+-]*$/)]]
    })
  }

  verif(value:boolean){
    this.authService.clickNav.emit({
      data: value
    })
  }

  changeMeas(code:number){
    this.meas = code;
    localStorage.setItem('meas',code.toString());
  }

  sendAsesora(){
    if (this.aseForm.invalid) {
      return Object.values(this.aseForm.controls).forEach((control) => {
        control.markAsTouched();
      });
    }

    const dat = moment();

    this.asesora.name_adviser = this.aseForm.controls.name.value;
    this.asesora.info_adviser = this.aseForm.controls.info.value;
    this.asesora.email_adviser = this.aseForm.controls.email.value;
    this.asesora.phone_adviser = this.aseForm.controls.phone.value;
    this.asesora.ip_adviser = '';
    this.asesora.type_adviser = 1;
    this.asesora.status_adviser = 1;
    this.asesora.id_user_adviser = 113;
    this.asesora.date_created_adviser = dat.format('YYYY-MM-DD');

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Enviando solicitud',
    });
    Swal.showLoading();

    this.apiService.sendAsesora(this.asesora).subscribe(resp => {

      let dataMail = [
        {
          email: this.aseForm.controls.email.value,
          name: this.aseForm.controls.name.value,
          code: 7
        }
      ]

      this.mailService.sendMail(dataMail).subscribe((resp2:any) => {
        Swal.close();
        this.aseForm.reset({
          name: [''],
          info: [''],
          email: [''],
          phone: ['']
        })
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: '¡Enviado!',
          text: 'Te responderemos a la brevedad posible',
          showConfirmButton: false,
          timer: 2000,
        })
      })
    },
    (error) => {
      this.errorMessage = error;

      if (this.errorMessage != null) {
        Swal.fire({
          title: 'error',
          icon: 'error',
          text: error.error.message,
        });
      }
    })
  }

}
