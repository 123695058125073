export class UserModel {

    id_user?: number;
    id_internal_user: string;
    email_user: string;
    password_user: string;
    name_user: string;
    lastname_user: string;
    dni_user: string;
    id_role_user: number;
    position_user: string;
    phone_user: string;
    commune_user: string;
    id_country_user: number;
    brokerCode_user: number;
    userCode_user: number;
    image_user: string;
    code_user: string;
    code_action_user: number;
    code_exp_user: string;
    token_user: string;
    token_exp_user: string;
    date_created_user: string;


    constructor(){
        this.id_internal_user = '',
        this.email_user = '',
        this.password_user = '',
        this.name_user = '',
        this.lastname_user = '',
        this.dni_user = '',
        this.id_role_user = 0,
        this.position_user = '',
        this.phone_user = '',
        this.commune_user = '',
        this.id_country_user = 0,
        this.brokerCode_user = 0,
        this.userCode_user = 0,
        this.image_user = '',
        this.code_user = '',
        this.code_action_user = 0,
        this.code_exp_user = '';
        this.token_user = '',
        this.token_exp_user = '',
        this.date_created_user = ''

    }
}