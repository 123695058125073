import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostComponent } from './post.component';
import { MaterialModule } from '../material.module';
import { AppRoutingModule } from '../app-routing.module';



@NgModule({
  declarations: [PostComponent],
  imports: [
    CommonModule,
    MaterialModule,
    AppRoutingModule
  ],
  exports: [PostComponent]
})
export class PostModule { }
