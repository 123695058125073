<div class="container-fluid property">
  <div class="row">
    <div class="col-12 hero" style="background-image:url('../../assets/img/am10.png')" *ngIf="locald == 'cl'">
    </div>
    <div class="col-12 hero" style="background-image:url('../../assets/img/am11.png')" *ngIf="locald == 'rd'">
    </div>
    <div class="separator pdestacadas"></div>
  </div>

  <div class="row justify-content-center botonera1">
    <div class="col-11 col-lg-4 text-center btn1 cpointer" routerLink="/properties/cl" (click)="changeParameter('cl')">
      {{'PROPIEDADES-CL'|translate}}
    </div>
    <div class="col-11 col-lg-4 mt-3 mt-lg-0 ms-lg-5 text-center btn1 cpointer" routerLink="/properties/rd"
      (click)="changeParameter('rd')">
      {{'PROPIEDADES-PR'|translate}}
    </div>
  </div>

  <!-- FILTROS -->
  <div class="row" *ngIf="properties.length != 0">
    <form [formGroup]="filters">
      <div class="col-12 text-center d-lg-none">
        <div class="row mt-3 mb-4">
          <div class="col-12">
            <span class="wonder3">
              <button class="me-5 chandal1" (click)="ope(8)"
                [ngClass]="{'chandal1-fix': operation2}">{{'Comprar'|translate}}</button>
              <button class="chandal2" (click)="ope(5)"
                [ngClass]="{'chandal2-fix': operation2}">{{'Arrendar'|translate}}</button>
              <div class="fondox" [ngClass]="{'fondox-fix': operation2}"></div>
            </span>
          </div>
          <div class="col-4 mt-4">
            <span>
              <label for=""><i class="fa-solid fa-bed"></i></label>
              <input type="number" class="wonder2 ms-2 text-center" placeholder="0" formControlName="beds">
            </span>
          </div>
          <div class="col-4 mt-4">
            <span>
              <label for=""><i class="fa-solid fa-bath"></i></label>
              <input type="number" class="wonder2 ms-2 text-center" placeholder="0" formControlName="bath">
            </span>
          </div>
          <div class="col-4 mt-4">
            <span>
              <label for=""><i class="fa-solid fa-car"></i></label>
              <input type="number" class="wonder2 ms-2 text-center" placeholder="0" formControlName="parking">
            </span>
          </div>
          <div class="col-12 mt-3">
            <span>
              <label><i class="fa-solid fa-filter-circle-dollar me-2"></i></label>
              <mat-slider class="wonder" thumbLabel [displayWith]="formatLabel" tickInterval="1000" step="1000" min="0"
                max="100000" aria-label="units" formControlName="values">
              </mat-slider>
            </span>
          </div>
        </div>
      </div>

      <div class="col-12 text-center d-none d-lg-block">

        <span class="me-5 wonder3">
          <button class="me-5 chandal1" (click)="ope(8)"
            [ngClass]="{'chandal1-fix': operation2}">{{'Comprar'|translate}}</button>
          <button class="chandal2" (click)="ope(5)"
            [ngClass]="{'chandal2-fix': operation2}">{{'Arrendar'|translate}}</button>
          <div class="fondox" [ngClass]="{'fondox-fix': operation2}"></div>
        </span>

        <span class="me-5">
          <label for=""><i class="fa-solid fa-bed"></i></label>
          <input type="number" class="wonder2 ms-2 text-center" placeholder="0" formControlName="beds">
        </span>

        <span class="me-5">
          <label for=""><i class="fa-solid fa-bath"></i></label>
          <input type="number" class="wonder2 ms-2 text-center" placeholder="0" formControlName="bath">
        </span>

        <span class="me-5">
          <label for=""><i class="fa-solid fa-car"></i></label>
          <input type="number" class="wonder2 ms-2 text-center" placeholder="0" formControlName="parking">
        </span>

        <span>
          <label><i class="fa-solid fa-filter-circle-dollar me-2"></i></label>
          <mat-slider class="wonder" thumbLabel [displayWith]="formatLabel" tickInterval="1000" step="1000" min="0"
            max="100000" aria-label="units" formControlName="values"></mat-slider>
        </span>
      </div>
    </form>
  </div>

  <div class="row justify-content-center pdestacadas ps-lg-5 pe-lg-5 pb-5" *ngIf="properties">
    <div class="col-12 col-lg-4 pdes"
      *ngFor="let prope of properties | property:page:operation:beds:parking:bath:values:false:divisa:meas">

      <div class="row">
        <a routerLink="/property/{{prope.url_property}}" (click)="verif(false)">
          <div class="col-12 image">
            <div class="image2">
              <img src="{{prope.image2_property}}" class="w-100" alt="">
            </div>
          </div>
          <div class="col-12 mt-3 p-0">
            <h6 *ngIf="lang == 'es' || lang == '' || prope.description_en_property == ''">{{prope.description_property}}
            </h6>
            <h6 *ngIf="lang == 'en' && prope.description_en_property != ''">{{prope.description_en_property}}</h6>
            <p>{{'UBI'|translate}}: {{prope.sector_property | titlecase}}</p>
            <p>{{'HAB'|translate}}: {{prope.bedrooms_property}}</p>
            <p>{{'BANO'|translate}}: {{prope.bathing_property}}</p>
            <p *ngIf="meas == 1">{{'SUPUTIL'|translate}}: {{prope.built_property}} {{'MT2'|translate}}</p>
            <p *ngIf="meas == 2">{{'SUPUTIL'|translate}}: {{prope.built2_property}} {{'FEET'|translate}}</p>
            <p *ngIf="meas == 1">{{'SUPTOTAL'|translate}}: {{prope.ground_property}} {{'MT2'|translate}}</p>
            <p *ngIf="meas == 2">{{'SUPTOTAL'|translate}}: {{prope.ground2_property}} {{'FEET'|translate}}</p>
            <p *ngIf="prope.value3_property != 'CLP '">{{'DSDE'|translate}}:
              {{prope.value2_property | currency:prope.value3_property}}</p>
            <p *ngIf="prope.value3_property == 'CLP '">{{'DSDE'|translate}}:
              {{prope.value2_property | currency:prope.value3_property:'code':'4.0'}}</p>
          </div>
        </a>
        <div class="col-12">
          <p class="feet cpointer" *ngIf="meas == 1" (click)="changeMeas(2);$event.stopPropagation()">
            <span>{{'FEET1'|translate}}</span> </p>
          <p class="feet cpointer" *ngIf="meas == 2" (click)="changeMeas(1);$event.stopPropagation()">
            <span>{{'FEET2'|translate}}</span> </p>
        </div>
        <div class="col-12 ico-des mt-5">
          <div class="row">
            <div class="cajaX text-center" *ngFor="let ico of prope.amenities2_property"><i class="{{ico.ico}}"></i>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-12 col-lg-10 no-existe mt-5 mb-5" *ngIf="properties.length == 0">
      <p>{{'ERROR5'|translate}}</p>
    </div>
  </div>

  <!-- Paginación -->
  <div class="row justify-content-center mb-5">
    <div class="col-12 col-lg-6 text-center" *ngIf="properties.length != 0">
      <button class="nmberPag me-2" (click)="previousPage()" [disabled]="pages2 == 1"><i
          class="fa-solid fa-angle-left"></i></button>

      <span class="nmberPag2 me-2">{{'Pagina'|translate}} {{pages2}} {{'de'|translate}} {{pages}}</span>
      <!--
            <span class="nmberPag" *ngFor="let item of [].constructor(pages); let i = index">{{i+1}}</span>-->

      <button class="nmberPag" (click)="nextPage()" [disabled]="pages == pages2"><i
          class="fa-solid fa-angle-right"></i></button>
    </div>
  </div>

  <div class="row justify-content-center slider-bg">
    <div class="col-12 col-lg-9 text-center">
      <h3>{{'LMRENC'|translate}}</h3>
    </div>
    <div class="col-12 col-lg-10 mt-5">
      <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item" [class.active]="prope.active == 1"
            *ngFor="let prope of properties | property:0:0:0:0:0:0:dest1:divisa">
            <div class="row justify-content-center">
              <div class="col-12">
                <img src="{{prope.image2_property}}" class="d-block w-100" alt="...">
              </div>
              <div class="col-12 col-lg-6 text-center ico-property">
                <div class="row justify-content-center">
                  <div class="cajaX text-center" *ngFor="let ico of prope.amenities2_property"><i
                      class="{{ico.ico}}"></i></div>
                </div>
              </div>
              <div class="col-12 textCompleto" [innerHTML]="prope.description_short_property"
                *ngIf="lang == 'es' || lang == '' || prope.description_en_property == ''"></div>
              <div class="col-12 textCompleto" [innerHTML]="prope.description_en_short_property"
                *ngIf="lang == 'en' && prope.description_en_property != ''"></div>
              <button class="col-11 col-lg-4 btn3 me-lg-4"
                (click)="interested(prope.id_property)">{{'INTERESA1'|translate}}</button>
              <a class="col-11 col-lg-4 mt-3 mt-lg-0" target="_blank"
                href="https://wa.me/{{whatsapp}}?text={{'WTS1' | translate}}%20{{prope.description_property}}."><button
                  class="btn3 w-100">{{'WHATSAPP1'|translate}}</button></a>
            </div>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
          data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button"
          data-bs-target="#carouselExampleControls,#carouselExampleControls2"
          aria-controls="#carouselExampleControls,#carouselExampleControls2" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  </div>


  <div class="row justify-content-center destacada2" *ngFor="let prope2 of properties2">
    <div class="col-12 col-lg-10">
      <h3 *ngIf="lang == 'es' || lang == '' || prope2.description_en_property == ''">{{prope2.description_property}}
      </h3>
      <h3 *ngIf="lang == 'en' && prope2.description_en_property != ''">{{prope2.description_en_property}}</h3>
    </div>
    <div class="col-12 col-lg-5 me-lg-5 mt-3 mt-lg-5 align-self-center">
      <div class="row justify-content-center fine">
        <div class="col-11 col-lg-12 image">
          <img src="{{prope2.image2_property}}" class="w-100" alt="">
        </div>
        <div class="col-12">
          <button class="col-11 col-lg-12 btn3 mt-4"
            (click)="interested(prope2.id_property)">{{'INTERESA1'|translate}}</button>
          <a class="p-0" target="_blank"
            href="https://wa.me/{{whatsapp}}?text={{'WTS1' | translate}}%20{{prope2.description_property}}."><button
              class="col-11 col-lg-12 btn3 mt-2">{{'WHATSAPP1'|translate}}</button></a>
        </div>
      </div>

    </div>
    <div class="col-12 col-lg-4 ms-lg-5 mt-3 mt-lg-5">
      <div class="row justify-content-center">
        <div class="col-11 col-lg-12">
          <p>{{'UBI'|translate}}: {{prope2.sector_property | titlecase}}</p>
          <p>{{'HAB'|translate}}: {{prope2.bedrooms_property}}</p>
          <p>{{'SUPUTIL'|translate}}: {{prope2.built_property}} mt2</p>
          <p>{{'SUPTOTAL'|translate}}: {{prope2.ground_property}} mt2</p>
          <p *ngIf="prope2.value3_property != 'CLP '">{{'DSDE'|translate}}:
            {{prope2.value2_property | currency:prope2.value3_property}}</p>
          <p *ngIf="prope2.value3_property == 'CLP '">{{'DSDE'|translate}}:
            {{prope2.value2_property | currency:prope2.value3_property:'code':'4.0'}}</p>
        </div>
        <!--
                <button class="col-11 col-lg-12 btn3 mt-4" (click)="interested(prope2.id_property)">{{'INTERESA1'|translate}}</button>
                <button class="col-11 col-lg-12 btn3 mt-2">{{'WHATSAPP1'|translate}}</button>
                -->
        <div class="col-12 bloce mt-4" [innerHTML]="prope2.description_short_property"
          *ngIf="lang == 'es' || prope2.description_en_property == ''"></div>
        <div class="col-12 bloce mt-4" [innerHTML]="prope2.description_en_short_property"
          *ngIf="lang == 'en' && prope2.description_en_property != ''"></div>
      </div>
    </div>
    <div class="col-10 text-end p-4 rrss2 mt-4">
      <span><a target="_blank" href="{{facebook}}"><i class="fa-brands fa-facebook-f me-4"></i></a></span>
      <span><a target="_blank" href="{{instagram}}"><i class="fa-brands fa-instagram"></i></a></span>
    </div>
  </div>

</div>
