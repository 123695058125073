<div class="container-fluid blog">
    <div class="row justify-content-center">
        <div class="col-12 col-lg-10 text-center">
            <h3>{{'FOOT3' | translate}}</h3>

            <div class="row mt-5 separator">
                <div class="col-12 text-center" *ngIf="posts.length == 0">
                    <div class="no-existe">
                        {{'ERROR6' | translate}}
                    </div>
                </div>
                <div class="col-12 col-lg-4 pip mb-5" *ngFor="let post of posts | postf:page">
                    <div class="cate">
                        <span *ngIf="lang == 'es' || lang == '' || post.namen_category == ''">{{post.name_category}}</span> <span *ngIf="lang == 'en' && post.namen_category != ''">{{post.namen_category}}</span>
                    </div>
                    <mat-card class="example-card cpointer" routerLink="./post/{{post.url_post}}">
                        <img mat-card-image src="{{post.image2_post}}" alt="{{post.title_post}}">
                        <mat-card-header>

                          <mat-card-title> <span *ngIf="lang == 'es' || lang == '' || post.titlen_post == ''">{{post.title_post}}</span> <span *ngIf="lang == 'en' && post.titlen_post != ''">{{post.titlen_post}}</span> </mat-card-title>

                          <mat-card-subtitle>
                            
                          </mat-card-subtitle>

                        </mat-card-header>
                        
                        
                      </mat-card>
                </div>
            </div>
            <div class="row justify-content-center pagins">
                <div class="col-12 col-lg-6 text-center" *ngIf="posts.length != 0">
                    <button class="nmberPag me-2" (click)="previousPage()" [disabled]="pages2 == 1"><i class="fa-solid fa-angle-left"></i></button>
        
                    <span class="nmberPag2 me-2">Página {{pages2}} de {{pages}}</span>
        
                    <button class="nmberPag" (click)="nextPage()" [disabled]="pages == pages2"><i class="fa-solid fa-angle-right"></i></button>
                </div>
            </div>
        </div>
    </div>
    
</div>
