import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as moment from 'moment';
import { FaqModel } from 'src/app/shared/models/faq';
import { ApiService } from 'src/app/shared/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  public Editor = ClassicEditor;
  public faqesForm!: FormGroup;
  public faqenForm!: FormGroup;
  public faqs: FaqModel = new FaqModel();

  public parametro = '';

  public faqq = {
    quest_faq : '',
    answer_faq : '',
    questen_faq: '',
    answeren_faq: ''
  }

  public editorConfig = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'link',
        'image'
      ]
    },
    image: {
      toolbar: [
        'imageTextAlternative'
      ]
    },
    table: {
      contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
    },
    language: 'es'
  };

  constructor( private fb : FormBuilder,
               private apiSer : ApiService,
               private router : Router,
               private _activatedRoute : ActivatedRoute ) {
                this._activatedRoute.params.subscribe((params: Params) => {
                  this.parametro = params['content'];
                })
               }

  ngOnInit(): void {

    this.createForm();

    if(this.parametro != undefined){
      this.chargeData();
    }

  }

  createForm(){
    this.faqesForm = this.fb.group({
      quest : [''],
      answer : ['']
    });

    this.faqenForm = this.fb.group({
      questen : [''],
      answeren : ['']
    })
  }

  chargeData(){
    this.apiSer.searchFaq(Number(this.parametro)).subscribe(resp => {
      if(resp.results != 'Not Found'){
        this.faqq = resp.results[0];

        this.faqesForm.reset({
          quest: this.faqq['quest_faq'],
          answer: this.faqq['answ_faq']
        });

        this.faqenForm.reset({
          questen: this.faqq['questen_faq'],
          answeren: this.faqq['answen_faq']
        })
      }
    })
  }

  publish(code:number){
    if(this.faqenForm.invalid){
      return Object.values(this.faqenForm.controls).forEach((control) => {
        control.markAllAsTouched();
      });
    }

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Agregando FAQ. Espere por favor',
    });
    Swal.showLoading();

    const dat = moment();

    this.faqs.quest_faq = this.faqesForm.controls.quest.value;
    this.faqs.answ_faq = this.faqesForm.controls.answer.value;
    this.faqs.questen_faq = this.faqenForm.controls.questen.value;
    this.faqs.answen_faq = this.faqenForm.controls.answeren.value;
    
    switch (code) {
      case 1:
        this.faqs.date_created_faq = dat.format('YYYY-MM-DD');

        this.apiSer.addFaq(this.faqs).subscribe(resp => {
          Swal.close();
        
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'FAQ creada con éxito',
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.router.navigate(['admin/faq'])
          });
        })
        break;
    
      case 2:
        this.apiSer.editFaq(this.faqs,Number(this.parametro)).subscribe(resp => {
          Swal.close();
        
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'FAQ editada con éxito',
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.router.navigate(['admin/faq'])
          });
        })
        break;
    }
    

  }

}
