<div class="row">
    <div class="col-12">
        <h2>Amenidades</h2>
    </div>
    
    <div class="col-8">
        <mat-dialog-content>
            <div class="row">
                <div class="col-4 mt-2 mb-3" *ngFor="let amenity of amenities" (click)="selectAmenity(amenity.ico_amenity, amenity.name_amenity)">
                    <div class="carder">
                        <div class="text-center">
                            <p><i class="{{amenity.ico_amenity}} fa-2x"></i></p>
                            <p class="m-0">{{amenity.name_amenity}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
    </div>
    <div class="col-4 amens" *ngIf="amenitiesList.length > 0">
        <p *ngFor="let ame of amenitiesList; let i = index"><i class="{{ame.ico}}"></i> {{ame.name}} <span class="closeda"><i class="fa-solid fa-circle-xmark" (click)="delAmenity(i)"></i></span> </p>
    </div>
    <div class="col-4 amens" *ngIf="amenitiesList.length == 0">
        <p><small> No hay amenidades seleccionadas </small></p>
    </div>
    <div class="col-6">
        <button type="button" mat-raised-button color="primary" class="w-100" (click)="sendAmenity()">Seleccionar Amenidades</button>
    </div>
    
</div>
