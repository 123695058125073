import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as moment from 'moment';
import { BlogService } from 'src/app/admin-blog/shared/services/blog.service';
import { SafeModel } from 'src/app/shared/models/safe';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ImageService } from 'src/app/shared/services/image.service';
import Swal from 'sweetalert2';
import { SafeService } from '../shared/services/safe.service';

@Component({
  selector: 'app-safes',
  templateUrl: './safes.component.html',
  styleUrls: ['./safes.component.css']
})
export class SafesComponent implements OnInit {

  public Editor = ClassicEditor;

  public safesForm!: FormGroup;
  public safenForm!: FormGroup;
  public safemForm!: FormGroup;
  public safe: SafeModel = new SafeModel();
  public parametro = '';
  public publisher = 1;
  public data: any;
  public links: any[] = [];
  public archives: any[] = [];
  public images: any[] = [];
  public urlImage: any = 'https://api.dosiscl.com/uploads/no-image.png';
  public maximus: boolean = false;

  public sfe = {
    title_safe : '',
    content_safe : '',
    links_safe : '',
    image_safe : ''
  }

  constructor( private fb : FormBuilder,
               private authSer : AuthService,
               private imgSer : ImageService,
               private safeSer : SafeService,
               private blogSer : BlogService,
               private apiSer : ApiService,
               private router : Router,
               private _activatedRoute : ActivatedRoute ) {
                this._activatedRoute.params.subscribe((params: Params) => {
                  this.parametro = params['content'];
                })
               }

  ngOnInit(): void {
    this.createForm();
    if(this.parametro != undefined){
      this.publisher = 2;
      this.chargeData();
    }

    ClassicEditor.defaultConfig = {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          'link'
        ]
      },
      image: {
        toolbar: [
          'imageTextAlternative'
        ]
      },
      table: {
        contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
      },
      language: 'es'
    };

    if( localStorage.getItem('token') ){
      this.data = Object.values( this.authSer.getDecodedAccessToken( localStorage.getItem('token') ) );
    }
  }

  createForm(){
    this.safesForm = this.fb.group({
      title: [''],
      content: ['']
    })
    this.safenForm = this.fb.group({
      titlen: [''],
      contenten : ['']
    })
    this.safemForm = this.fb.group({
      links : [''],
      status : ['', Validators.required],
      image : ['']
    })
  }

  chargeData(){
    this.safeSer.searchSafe(this.parametro).subscribe(resp => {
      if( resp.results != 'Not Found' ){
        this.sfe = resp.results[0];
        let img = JSON.parse( this.sfe['image_safe'] );
        this.urlImage = img.url;
        this.links = JSON.parse( this.sfe['links_safe'] );
        this.safesForm.reset({
          title: this.sfe['title_safe'],
          content: this.sfe['content_safe']
        })
        this.safenForm.reset({
          titlen : this.sfe['titlen_safe'],
          contenten : this.sfe['contenten_safe']
        })
        this.safemForm.reset({
          links : [''],
          status: Number( this.sfe['status_safe'] ),
          image : ['']
        })
      }
    })
  }

  addLink(){
    if(this.safemForm.controls.links.value != ''){
      let urra = {
        url : this.safemForm.controls.links.value
      }
      this.links.push(urra);
      this.safemForm.controls['links'].reset();
  
      if(this.links.length >= 5){
        this.maximus = true;
      }else{
        this.maximus = false;
      }
    }
  }

  delLink(i:number){
    this.links.splice(i, 1);
  }

  compareFunction2(o1: any, o2: any) {
    return (o1 == o2 && o1.id == o2.id);
  }
  
  onSelectFile(event): any {
    this.archives = event.target.files;
    const reader = new FileReader();

    reader.readAsDataURL( this.archives[0] );
    reader.onloadend = () => {
      this.images.push( {name:'',url:reader.result} );
      this.urlImage = reader.result;
    }
  }

  publish( status : number ){
    if(this.safemForm.invalid){
      return Object.values(this.safemForm.controls).forEach((control) => {
        control.markAllAsTouched();
      });
    }

    switch (status) {
      case 1:
        Swal.fire({
          allowOutsideClick: false,
          icon: 'info',
          text: 'Agregando publicación. Espere por favor',
        });
        break;
    
      case 2:
        Swal.fire({
          allowOutsideClick: false,
          icon: 'info',
          text: 'Editando publicación. Espere por favor',
        });
        break;
    }
    Swal.showLoading();

    const dat = moment();
    let nameI = `${this.data[2].id}_${this.blogSer.makeid(5)}`;

    this.safe.title_safe = this.safesForm.controls.title.value;
    this.safe.titlen_safe = this.safenForm.controls.titlen.value;
    this.safe.content_safe = this.safesForm.controls.content.value;
    this.safe.contenten_safe = this.safenForm.controls.contenten.value;
    this.safe.links_safe = JSON.stringify( this.links );
    this.safe.id_user_safe = Number( this.data[2].id );
    this.safe.status_safe = this.safemForm.controls.status.value;
    this.safe.date_created_safe = dat.format('YYYY-MM-DD');

    switch (status) {
      case 1:
      case 0:
        if( this.images.length != 0 ){
          this.imgSer.uploadImage(nameI, this.images[0].url,'safe').then( urlImage => {
            if(urlImage == null){
              this.safe.image_safe = JSON.stringify( this.urlImage );
            }else{
              let imgj = {name: nameI, url: urlImage};
              this.safe.image_safe = JSON.stringify(imgj);
            }

            this.safeSer.publish( this.safe ).subscribe( resp => {
              Swal.close();

              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Entrada creada con éxito',
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.router.navigate(['admin/safe'])
              });
            })
          } )
        }else{

          let imme = {
            name : 'no-image',
            url : 'https://api.dosiscl.com/uploads/no-image.png'
          }

          this.safe.image_safe = JSON.stringify(imme);

          this.safeSer.publish( this.safe ).subscribe( resp => {
            Swal.close();

            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Entrada creada con éxito',
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              this.router.navigate(['admin/blog'])
            });
          } )
        } 
        break;
    
      case 2:
        if( this.images.length == 0 ){
          this.safe.image_safe = this.sfe['image_safe'];

          this.safeSer.editSafe( this.safe, this.sfe['id_safe'] ).subscribe( resp => {
            Swal.close();
    
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Entrada editada con éxito',
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              this.router.navigate(['admin/safe'])
            });
          } )
        }else{
          let borrad = JSON.parse( this.sfe['image_safe'] );
          this.imgSer.deleteImageBlog(borrad.name, 'safe').then( value => {
            this.imgSer.uploadImage(nameI, this.images[0].url, 'safe').then( urlImage => {
              if(urlImage == null){
                this.safe.image_safe = JSON.stringify( this.urlImage );
              }else{
                let imgj = { name: nameI, url: urlImage };
                this.safe.image_safe = JSON.stringify(imgj);
              }

              this.safeSer.editSafe( this.safe, this.sfe['id_safe'] ).subscribe( resp => {
                Swal.close();
        
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Entrada editada con éxito',
                  showConfirmButton: false,
                  timer: 1500,
                }).then(() => {
                  this.router.navigate(['admin/safe'])
                });
              } )
            } )
          } )
        }
        break;
    }
  }

}
