import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as moment from 'moment';
import { PostModel } from 'src/app/shared/models/post';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ImageService } from 'src/app/shared/services/image.service';
import Swal from 'sweetalert2';
import { BlogService } from '../shared/services/blog.service';

@Component({
  selector: 'app-add-post',
  templateUrl: './add-post.component.html',
  styleUrls: ['./add-post.component.css']
})
export class AddPostComponent implements OnInit {

  public Editor = ClassicEditor;

  public blogesForm!: FormGroup;
  public blogenForm!: FormGroup;
  public blogemForm!: FormGroup;
  public post: PostModel = new PostModel();
  public link = '';
  public parametro = '';
  public publisher = 1;
  public data:any;
  public archives: any[] = [];
  public categories: any[] = [];
  public images: any[] = [];
  public urlImage: any = 'https://api.dosiscl.com/uploads/no-image.png'
  
  public pst = {
    title_post : '',
    content_post : '',
    category_post: 1,
    url_post: '',
    image_post: ''
  }

  constructor( private fb : FormBuilder,
               private authSer : AuthService,
               private imgSer : ImageService,
               private blogSer : BlogService,
               private apiSer : ApiService,
               private router : Router,
               private _activatedRoute : ActivatedRoute) {
                this._activatedRoute.params.subscribe((params: Params) => {
                  this.parametro = params['post'];
                })
               }

  ngOnInit(): void {
    this.createForm();
    this.readCat();
    if(this.parametro == undefined){
      this.listen();
    }else{
      this.publisher = 2;
      this.chargeData();
    }

    ClassicEditor.defaultConfig = {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          'link',
          '|',
          'image'
        ]
      },
      image: {
        toolbar: [
          'imageTextAlternative'
        ]
      },
      table: {
        contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
      },
      language: 'es'
    };

    if( localStorage.getItem('token') ){
      this.data = Object.values( this.authSer.getDecodedAccessToken(localStorage.getItem('token')) );
    }

  }
  
  createForm(){
    this.blogesForm = this.fb.group({
      title: [''],
      content: [''],
    })
    this.blogenForm = this.fb.group({
      titlen: [''],
      contenten : ['']
    });
    this.blogemForm = this.fb.group({
      category: ['', Validators.required],
      status: ['', Validators.required],
      image: ['']
    })
  }
  
  
  chargeData(){
    this.blogSer.searchPost(this.parametro).subscribe(resp => {
      if(resp.results != 'Not Found'){
        this.pst = resp.results[0];
        let img = JSON.parse(this.pst['image_post']);
        this.urlImage = img.url;
        this.blogesForm.reset({
          title: this.pst['title_post'],
          content: this.pst['content_post']
        });
        this.blogenForm.reset({
          titlen: this.pst['titlen_post'],
          contenten: this.pst['contenten_post'],
        });
        this.blogemForm.reset({
          category: Number(this.pst['id_category_post']),
          status: Number(this.pst['status_post']),
          image: ['']
        })
      }
    })
  }

  compareFunction(o1: any, o2: any) {
    return (o1.name == o2.name && o1.id == o2.id);
  }

  compareFunction2(o1: any, o2: any) {
    return (o1 == o2 && o1.id == o2.id);
  }
  
  

  listen(){
    this.blogesForm.controls.title.valueChanges.subscribe( valor => {
      let respA = valor.replace(/[.,!¡?¿]/g,'');
      let respN = respA.replace(/ /g, '-');
      let respS = respN.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      let random = this.blogSer.makeid2(4);
      this.link = respS.toLowerCase()+'-'+random;
    })
  }

  readCat(){
    this.apiSer.readAll('categories').subscribe(resp => {
      if(resp.results != 'Not Found'){
        this.categories = resp.results;
      }
    })
  }

  onSelectFile(event): any {
    //Subir solo una imagen

    this.archives = event.target.files;
    const reader = new FileReader();

    reader.readAsDataURL(this.archives[0]);
    reader.onloadend = () => {
      this.images.push({name:'',url:reader.result});
      this.urlImage = reader.result;
    };

  }

  publish( status : number ){
    if(this.blogemForm.invalid){
      return Object.values(this.blogemForm.controls).forEach((control) => {
        control.markAllAsTouched();
      });
    }

    switch (status) {
      case 1:
        Swal.fire({
          allowOutsideClick: false,
          icon: 'info',
          text: 'Agregando publicación. Espere por favor',
        });
        break;
      case 2:
        Swal.fire({
          allowOutsideClick: false,
          icon: 'info',
          text: 'Editando publicación. Espere por favor',
        });
        break;
    }
    Swal.showLoading();

    const dat = moment();
    let nameI = `${this.data[2].id}_${this.blogSer.makeid(5)}`

    this.post.title_post = this.blogesForm.controls.title.value;
    this.post.titlen_post = this.blogenForm.controls.titlen.value;
    this.post.content_post = this.blogesForm.controls.content.value;
    this.post.contenten_post = this.blogenForm.controls.contenten.value;
    this.post.id_category_post = this.blogemForm.controls.category.value;
    this.post.status_post = this.blogemForm.controls.status.value;
    this.post.id_user_post = Number(this.data[2].id);
    this.post.url_post = this.link;
    
    this.post.date_created_post = dat.format('YYYY-MM-DD');

    switch (status) {
      case 1:
      case 0:
        if(this.images.length != 0){
          this.imgSer.uploadImage(nameI, this.images[0].url,'blog').then(urlImage => {
            if(urlImage == null){
              this.post.image_post = JSON.stringify(this.urlImage);
            }else{
              let imgj = {name: nameI, url: urlImage};
              this.post.image_post = JSON.stringify(imgj);
            }
      
            this.blogSer.publish(this.post).subscribe(resp => {
              Swal.close();
      
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Entrada creada con éxito',
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.router.navigate(['admin/blog'])
              });
            })
          })
        }else{

          let imme = {
            name : 'no-image',
            url : 'https://api.dosiscl.com/uploads/no-image.png' 
          }

          this.post.image_post = JSON.stringify(imme);

          this.blogSer.publish(this.post).subscribe(resp => {
            Swal.close();
    
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Entrada creada con éxito',
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              this.router.navigate(['admin/blog'])
            });
          })
        }
        
        break;
    
      case 2:
        if(this.images.length == 0){

          this.post.image_post = this.pst['image_post'];
  
          this.blogSer.editBlog(this.post,this.pst['id_post']).subscribe(resp => {
            Swal.close();
    
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Entrada editada con éxito',
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              this.router.navigate(['admin/blog'])
            });
          })
        }else{
          let borrad = JSON.parse(this.pst['image_post']);
          this.imgSer.deleteImageBlog(borrad.name, 'blog').then(value => {
  
            this.imgSer.uploadImage(nameI, this.images[0].url,'blog').then(urlImage => {
              if(urlImage == null){
                this.post.image_post = JSON.stringify(this.urlImage);
              }else{
                let imgj = {name:nameI, url: urlImage};
                this.post.image_post = JSON.stringify(imgj);
              }
          
              this.blogSer.editBlog(this.post,this.pst['id_post']).subscribe(resp => {
                Swal.close();
        
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Entrada editada con éxito',
                  showConfirmButton: false,
                  timer: 1500,
                }).then(() => {
                  this.router.navigate(['admin/blog'])
                });
              })
    
            })
          });
          
          
        }
        break;
    }

  }

}
