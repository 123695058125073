import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor( private http: HttpClient ) { }

  sendMail( data: any ){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded'
    }); 
  

    const payload = new HttpParams()
    .set('email', data[0].email)
    .set('first_name', data[0].name)
    .set('last_name', data[0].last_name)
    .set('property', data[0].property)
    .set('code_api', data[0].code_api)
    .set('code', data[0].code)
    .set('date', data[0].date);

    return this.http.post(`https://events.sendpulse.com/events/id/bda83872c48a4adcfd85dd7a9020e8a5/8087561`,payload,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  makeCode(length: number){
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    let charactersLength = characters.length;

    for ( let i = 0; i < length; i++ ){
      result += characters.charAt(
        Math.floor( Math.random() * charactersLength )
      );
    }
    return result;
  }

}
