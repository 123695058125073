import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { PropertiesModel } from 'src/app/shared/models/properties';
import Swal from 'sweetalert2';
import { Markers } from '../shared/classes/markers.class';
import { PropertiesService } from '../shared/services/properties.service';
import { EditMapPropertyComponent } from '../edit-map-property/edit-map-property.component';
import { ApiService } from 'src/app/shared/services/api.service';
import { ImageService } from 'src/app/shared/services/image.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ValidatorsService } from 'src/app/shared/services/validators.service';
import { AddOwnerComponent } from 'src/app/admin-owners/add-owner/add-owner.component';
import { ChooseAmenitiesComponent } from '../choose-amenities/choose-amenities.component';


@Component({
  selector: 'app-add-property',
  templateUrl: './add-property.component.html',
  styleUrls: ['./add-property.component.css'],
})
export class AddPropertyComponent implements OnInit {
  /*
  lat = -33.45694;
  lng = -70.64827;
  */
  lat = 18.58182;
  lng = -68.40431;

  public markers: Markers[] = [];
  public propertyForm!: FormGroup;
  public property: PropertiesModel = new PropertiesModel();
  public errorMessage = '';
  public pesos: boolean = false;
  public dolar: boolean = false;
  public uf: boolean = false;
  public vPesos = 1;
  //public vDolar = 900.90;
  //public vUf = 33821.34;
  public vDolar = 0;
  public vUf = 0;
  public rPesos = 0;
  public rDolar = 0;
  public rUf = 0;
  public guarda: any[] = [];
  public operations: any[] = [];
  public estates: any[] = [];
  public payments: any[] = [];
  public countries: any[] = [];
  public owners: any[] = [];
  public geographies: any[] = [];
  public regions: any[] = [{ id_region: '0', name_region: 'Sin Región' }];
  public communes: any[] = [{ id_commune: '0', name_commune: 'Sin Comuna' }];
  public commune: string = '';
  public urlImage: any = 'https://api.dosiscl.com/uploads/no-image.png';
  public archives: any[] = [];
  public images: any[] = [];
  public urls: any[] = [];
  public user: any;
  public map: boolean = false;
  public Editor = ClassicEditor;
  public link = '';
  public qAmenity = 'Sin amenidades seleccionadas';
  public amenitiesList: any[] = [];
  public noval = 0;
  public errorValores = false;

  constructor(
    private propertyService: PropertiesService,
    private apiService: ApiService,
    private authService: AuthService,
    private validService: ValidatorsService,
    private imgService: ImageService,
    private fb: FormBuilder,
    private router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    /*
                const newMarker = new Markers(18.58182,-68.40431);
                this.markers.push( newMarker );
                */
    if (localStorage.getItem('markers')) {
      this.markers = JSON.parse(localStorage.getItem('markers'));
    }
  }

  ngOnInit(): void {
    this.createForm();
    this.readOperations();
    this.readEstates();
    this.readPayments();
    this.readCountries();
    this.readOwners();
    this.readGeographies();
    this.changeCountry();

    this.user = this.propertyService.readTokenDecoded();
  }

  addMarker(event: any) {
    const coords: { lat: number; lng: number } = event.coords;
    const titleMarker: string = this.propertyForm.get('description').value;
    const newMarker = new Markers(coords.lat, coords.lng, titleMarker);
    this.markers.push(newMarker);

    this.saveStorage();
    this.snackBar.open('Marcador agregado', 'Cerrar', { duration: 3000 });
  }

  delMarker(i: number) {
    this.markers.splice(i, 1);
    this.saveStorage();
    this.snackBar.open('Marcador eliminado', 'Cerrar', { duration: 3000 });
  }

  editMarker(marker: Markers) {
    const dialogRef = this.dialog.open(EditMapPropertyComponent, {
      width: '250px',
      data: { title: marker.title, desc: marker.desc },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      marker.title = result.title;
      marker.desc = result.desc;

      this.saveStorage();
      this.snackBar.open('Marcador actualizado', 'Cerrar', { duration: 3000 });
    });
  }

  saveStorage() {
    localStorage.setItem('markers', JSON.stringify(this.markers));
  }

  readOperations() {
    this.apiService.readAll('operations').subscribe((resp) => {
      this.operations = resp.results;
    });
  }

  readEstates() {
    this.apiService.readAll('estates').subscribe((resp) => {
      this.estates = resp.results;
    });
  }

  readPayments() {
    this.apiService.readAll('payments').subscribe((resp) => {
      this.payments = resp.results;
    });
  }

  readCountries() {
    this.apiService.readAll('countries').subscribe((resp) => {
      this.countries = resp.results;
    });
  }

  readGeographies() {
    this.apiService.readAll('geographies').subscribe((resp) => {
      this.geographies = resp.results;
    });
  }

  readOwners() {
    this.apiService.readAll('owners').subscribe((resp) => {
      this.owners = resp.results;
    });
  }

  get descPattern() {
    if (
      this.propertyForm.controls.description.errors &&
      this.propertyForm.controls.description.touched
    ) {
      if (this.propertyForm.controls.description.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get rolPattern() {
    if (
      this.propertyForm.controls.rol.errors &&
      this.propertyForm.controls.rol.touched
    ) {
      if (this.propertyForm.controls.rol.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get sectorPattern() {
    if (
      this.propertyForm.controls.sector.errors &&
      this.propertyForm.controls.sector.touched
    ) {
      if (this.propertyForm.controls.sector.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get desc2Pattern() {
    if (
      this.propertyForm.controls.description2.errors &&
      this.propertyForm.controls.description2.touched
    ) {
      if (this.propertyForm.controls.description2.errors.pattern) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get propertyTitle() {
    return this.propertyForm.get('description').value;
  }

  get propertyDesc() {
    return this.propertyForm.get('description2').value;
  }

  get existingUrl(){
    if (this.propertyForm.controls.url.errors){
      if(this.propertyForm.controls.url.errors.existUrl){
        return true;
      }else{
        return false;
      }
    }
    return false; 
  }

  createForm() {
    this.propertyForm = this.fb.group({
      operation: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      estate: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      description: [
        '',
        Validators.pattern(/^[,.A-Za-z0-9-À-ÿ\u00f1\u00d1\s]+$/),
      ],
      desc_short: [''],
      desc_prin: [''],
      url: ['',,(control)=> this.validService.existUrl(control)],
      rol: ['', Validators.pattern(/^[-a-zA-Z0-9]*$/)],
      value: ['', Validators.pattern(/^[0-9]*$/)],
      dolarX: ['', Validators.pattern(/^[0-9]*$/)],
      ufX: ['', Validators.pattern(/^[0-9]*$/)],
      payment: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      highlight: [0, Validators.pattern(/^[0-9]*$/)],
      subsidy: [0, Validators.pattern(/^[0-9]*$/)],
      exchange: [0, Validators.pattern(/^[0-9]*$/)],
      country: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      region: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      commune: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      sector: ['', Validators.pattern(/^[,.A-Za-z0-9-À-ÿ\u00f1\u00d1\s]+$/)],
      geography: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      map: [0, Validators.pattern(/^[0-9]*$/)],
      ground: [0, Validators.pattern(/^[0-9]*$/)],
      built: [0, Validators.pattern(/^[0-9]*$/)],
      bedrooms: [0, Validators.pattern(/^[0-9]*$/)],
      bathing: [0, Validators.pattern(/^[0-9]*$/)],
      parking: [0, Validators.pattern(/^[0-9]*$/)],
      description2: [
        '',
        Validators.pattern(/^[,.A-Za-z0-9-À-ÿ\u00f1\u00d1\s]+$/),
      ],
      amenities: this.fb.array([ ]),
      noval: [false],
      owner: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      image: [''],
    });
  }

  changeCountry() {
    this.propertyForm.controls.country.valueChanges.subscribe((valor) => {
      this.propertyService.searchRegion(valor).subscribe((resp) => {
        const filterReg = resp.results.filter(com => com.id_country_region == valor);
        this.regions = filterReg;
      });
    });

    this.propertyForm.controls.region.valueChanges.subscribe((valor2) => {
      this.propertyService.searchCommune(valor2).subscribe((resp2) => {
        const filterCom = resp2.results.filter(com => com.id_region_commune == valor2);
        this.communes = filterCom;
      });
    });

    this.propertyForm.controls.commune.valueChanges.subscribe((valor3) => {
      const index = this.communes.findIndex(
        (element) => element.id_commune === valor3
      );
      let comuns = this.communes[index];

      this.lat = parseFloat(comuns.lat_commune);
      this.lng = parseFloat(comuns.lng_commune);
      this.commune = comuns.name_commune;
    });

    this.propertyForm.controls.map.valueChanges.subscribe((valor4) => {
      if (valor4 == 1) {
        if (this.propertyForm.controls.commune.value == '') {
          Swal.fire('Debe ingresar antes un país, región y comuna.');
          this.propertyForm.controls.map.setValue(0);
        } else {
          this.map = true;
        }
      } else {
        this.map = false;
      }
    });

    this.propertyForm.controls.payment.valueChanges.subscribe((valor5) => { this.divisa(null,valor5);})

    this.propertyForm.controls.value.valueChanges.subscribe((valor6) => {
      this.divisa(valor6,'');
    })

    this.propertyForm.controls.description.valueChanges.subscribe((resp) => {
      let respA = resp.replace(/[.,]/g,'');
      let respN = respA.replace(/ /g, '-');
      let respS = respN.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      this.propertyForm.controls.url.reset(respS.toLowerCase());
      this.link = respS.toLowerCase();
    })

    this.propertyForm.controls.noval.valueChanges.subscribe((resp) => {
      if(resp == true){
        this.noval = 1;
      }else{
        this.noval = 0;
      }
    })
  }

  divisa(cantidad: number, divisa: string){
    const vale = this.propertyForm.controls.value.value;
    const dive = this.propertyForm.controls.payment.value;

    if(!cantidad){
      cantidad = vale;
    }
    
    if(!divisa){
      divisa = dive.toString();
    }

    if(this.vDolar == 0 || this.vUf == 0){
      
      this.authService.readEconomic('dolar').subscribe(resp => {
        this.errorValores = false;
        let dollar = resp.Dolares;
        let nDol = dollar[0].Valor.replace(',', '.');
        this.vDolar = Number(nDol);
  
        this.authService.readEconomic('uf').subscribe(resp => {
          let uf = resp.UFs;
          let nUF1 = uf[0].Valor.replace('.', '');
          let nUF = nUF1.replace(',', '.');
          this.vUf = Number(nUF);
          
          switch (divisa) {
            case '1':
              this.dolar = true;
              this.uf = true;
              this.pesos = false;
              this.rDolar = cantidad * (1 / this.vDolar); //0.0011;
              this.rUf = cantidad * (1 / this.vUf);//0.000030; // valor real
              break;
          
            case '2':
              this.dolar = true;
              this.pesos = true;
              this.uf = false;
              this.rDolar = cantidad * (this.vUf / this.vDolar);//37.54;
              this.rPesos = cantidad * this.vUf; // valor real
              break;
            
            case '3':
              this.pesos = true;
              this.uf = true;
              this.dolar = false;
              this.rPesos = cantidad * this.vDolar;  // valor real
              this.rUf = cantidad * (this.vDolar / this.vUf);//0.027;
              break;
            
            default:
              this.pesos = false;
              this.uf = false;
              this.dolar = false;
              break;
          }
        })
      },
      (error) => {
        this.errorValores = true;
      })
    }else{
      switch (divisa) {
        case '1':
          this.dolar = true;
          this.uf = true;
          this.pesos = false;
          this.rDolar = cantidad * (1 / this.vDolar); //0.0011;
          this.rUf = cantidad * (1 / this.vUf);//0.000030; // valor real
          break;
      
        case '2':
          this.dolar = true;
          this.pesos = true;
          this.uf = false;
          this.rDolar = cantidad * (this.vUf / this.vDolar);//37.54;
          this.rPesos = cantidad * this.vUf; // valor real
          break;
        
        case '3':
          this.pesos = true;
          this.uf = true;
          this.dolar = false;
          this.rPesos = cantidad * this.vDolar;  // valor real
          this.rUf = cantidad * (this.vDolar / this.vUf);//0.027;
          break;
        
        default:
          this.pesos = false;
          this.uf = false;
          this.dolar = false;
          break;
      }
    }
  }

  onSelectFile(event): any {
    //Subir solo una imagen
    /*
    this.archives = event.target.files;
    const reader = new FileReader();

    reader.readAsDataURL(this.archives[0]);
    reader.onloadend = () => {
      this.urlImage = reader.result;
    };
    */

    this.archives = event.target.files;

    for (let i = 0; i < this.archives.length; i++) {
      const reader = new FileReader();
      reader.readAsDataURL(this.archives[i]);
      reader.onloadend = () => {
        this.urlImage = reader.result;
        this.images.push(reader.result);
      };
    }
  }

  /*
  async uploadsUrl(id:any){
    for(let i=0; i<this.images.length;i++){

      let data = id.results.lastId.toString() + '_' + Date.now();

      this.propertyService.intoImages(data,this.images[i]).then((urlImage) => {
        console.log(urlImage);
        this.urls.push(urlImage);
      });
      
    }
  }
  */

  addOwner(){
    const dialogRef = this.dialog.open(AddOwnerComponent,{
      width: '700px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.readOwners();
    })
  }

  chooseAmenities(){
    const dialogRef = this.dialog.open(ChooseAmenitiesComponent, {
      width: '700px',
      data: {
        data: this.amenitiesList
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined){
        this.amenitiesList = result;
        this.qAmenity = `${result.length} Amenidades seleccionadas`;
      } 
      
    })
  }

  addProperty(status: number) {
    
    if (this.propertyForm.invalid) {
      return Object.values(this.propertyForm.controls).forEach((control) => {
        control.markAllAsTouched();
      });
    }

    const dat = moment();

    this.property.id_user_property = this.user[2].id;
    this.property.id_operation_property =
      this.propertyForm.controls.operation.value;
    this.property.id_estate_property = this.propertyForm.controls.estate.value;
    this.property.description_property =
      this.propertyForm.controls.description.value;
    this.property.description_short_property = this.propertyForm.controls.desc_short.value;
    this.property.description_prin_property = this.propertyForm.controls.desc_prin.value;
    this.property.rol_property = this.propertyForm.controls.rol.value;
    this.property.url_property = this.link;
    this.property.id_payment_property =
      this.propertyForm.controls.payment.value;
    this.property.highlight_property =
      this.propertyForm.controls.highlight.value;
    this.property.subsidy_property = this.propertyForm.controls.subsidy.value;
    this.property.exchange_property = this.propertyForm.controls.exchange.value;
    this.property.id_commune_property =
      this.propertyForm.controls.commune.value;
    this.property.id_region_property = this.propertyForm.controls.region.value;
    this.property.id_country_property =
      this.propertyForm.controls.country.value;
    this.property.sector_property = this.propertyForm.controls.sector.value;
    this.property.id_geography_property =
      this.propertyForm.controls.geography.value;
    this.property.markers_property = JSON.stringify(this.markers);
    this.property.map_property = this.propertyForm.controls.map.value;
    this.property.ground_property = this.propertyForm.controls.ground.value;
    this.property.built_property = this.propertyForm.controls.built.value;
    this.property.bedrooms_property = this.propertyForm.controls.bedrooms.value;
    this.property.bathing_property = this.propertyForm.controls.bathing.value;
    this.property.parking_property = this.propertyForm.controls.parking.value;
    //this.property.description2_property = this.propertyForm.controls.description2.value;
    this.property.id_owner_property = this.propertyForm.controls.owner.value;
    this.property.amenities_property = JSON.stringify(this.amenitiesList);
    this.property.noval_property = this.noval;
    this.property.image_property = '';
    this.property.status_property = status;
    this.property.date_created_property = dat.format('YYYY-MM-DD');

    switch (this.propertyForm.controls.payment.value) {
      case '1':
        if(this.errorValores){
          this.guarda = [{
            div: '1',
            code: 'CLP ',
            value: this.propertyForm.controls.value.value.toString()
          },{
            div: '2',
            code: 'UF ',
            value: this.propertyForm.controls.ufX.value.toString()
          },{
            div: '3',
            code: 'USD ',
            value: this.propertyForm.controls.dolarX.value.toString()
          }];
        }else{
          this.guarda = [{
            div: '1',
            code: 'CLP ',
            value: this.propertyForm.controls.value.value.toString()
          },{
            div: '2',
            code: 'UF ',
            value: this.rUf.toString()
          },{
            div: '3',
            code: 'USD ',
            value: this.rDolar.toString()
          }];
        }
        
        break;
      case '2':
        this.guarda = [{
          div: '1',
          code: 'CLP ',
          value: this.rPesos.toString()
        },{
          div: '2',
          code: 'UF ',
          value: this.propertyForm.controls.value.value.toString()
        },{
          div: '3',
          code: 'USD ',
          value: this.rDolar.toString()
        }];
      case '3':
        this.guarda = [{
          div: '1',
          code: 'CLP ',
          value: this.rPesos.toString()
        },{
          div: '2',
          code: 'UF ',
          value: this.rUf.toString()
        },{
          div: '3',
          code: 'USD ',
          value: this.propertyForm.controls.value.value.toString()
        }];
        break;
    }
    this.property.value_property = JSON.stringify(this.guarda);

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Subiendo imágenes. Espere por favor',
    });
    Swal.showLoading();

    this.propertyService.addProperty(this.property).subscribe(
      (resp) => {
        if (this.urlImage != 'https://api.dosiscl.com/uploads/no-image.png') {
          this.imgService
            .intoImages(resp.results.lastId, this.images, 'properties2')
            .then((urlImage) => {
              
              let urlImage2 = JSON.stringify(urlImage);
              
              this.propertyService
                .editProperty('image_property', urlImage2, resp.results.lastId)
                .subscribe((resp3: any) => {
                  Swal.close();

                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Propiedad registrada con éxito',
                    showConfirmButton: false,
                    timer: 1500,
                  }).then(() => {
                    localStorage.removeItem('markers');
                    this.router.navigate(['admin/properties']);
                  });
                });
                
            });

          /*
          this.propertyService
            .uploadImage(data, this.urlImage)
            .then((urlImage) => {
              console.log(urlImage);
             
              this.propertyService
                .editProperty('image_property', urlImage, resp.results.lastId)
                .subscribe((resp3: any) => {
                  Swal.close();

                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Propiedad registrada con éxito',
                    showConfirmButton: false,
                    timer: 1500,
                  }).then(() => {
                    localStorage.removeItem('markers');
                    this.router.navigate(['admin/properties']);
                  });
                }); 
            });
            */
        } else {
          this.propertyService
            .editProperty('image_property', '[]', resp.results.lastId)
            .subscribe((resp3: any) => {
              Swal.close();

              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Propiedad registrada con éxito',
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                localStorage.removeItem('markers');
                this.router.navigate(['admin/properties']);
              });
            });
        }
      },
      (error) => {
        this.errorMessage = error;

        if (this.errorMessage != null) {
          Swal.fire({
            title: 'error',
            icon: 'error',
            text: error.error.message,
          });
        }
      }
    );
  }
}
