import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CategoryModel } from 'src/app/shared/models/categories';
import { PostModel } from 'src/app/shared/models/post';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  private url = 'https://api.dosiscl.com';
  private userTokenApi = 'c5LTA6WPbMwHhEabYu77nN9cn4VcMj';
  private userToken = '';
  public data: any[] = [];

  constructor( private http: HttpClient,
               private authSer : AuthService ) {
                this.readToken();
                if( this.userToken != '' ){
                  this.data = Object.values( this.authSer.getDecodedAccessToken( this.userToken ) );
                }
               }

  readTokenDecoded(){
    return this.data;
  }
            
  readToken(){
    if (localStorage.getItem('token')) {
      this.userToken = localStorage.getItem('token') || '';
    } else {
      this.userToken = '';
    }
            
    return this.userToken;
  }

  makeid(length:number) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  makeid2(length:number) {
    var result           = '';
    var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789&';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  publish( post : PostModel ){
    this.readToken()
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Accept': '*/*',
      'Authorization':this.userTokenApi
    });

    const payload = new HttpParams()
    .set('title_post', post.title_post)
    .set('titlen_post', post.titlen_post)
    .set('content_post', post.content_post)
    .set('contenten_post', post.contenten_post)
    .set('id_category_post', post.id_category_post.toString())
    .set('id_user_post', post.id_user_post.toString())
    .set('url_post', post.url_post)
    .set('image_post', post.image_post)
    .set('status_post', post.status_post.toString())
    .set('date_created_post', post.date_created_post);

    return this.http.post(`${this.url}/posts?token=${this.userToken}&table=users&suffix=user`, payload, {headers:headers})
    .pipe(
      map((resp:any) => {
        return resp;
      })
    )
  }

  readPostRelations(){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/relations?rel=posts,categories,users&type=post,category,user&select=*&orderBy=id_post&orderMode=DESC`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  searchPost(search : string){
    let headers = new HttpHeaders({
      'Authorization':this.userTokenApi
    }); 

    return this.http.get(`${this.url}/posts?select=*&linkTo=url_post&search=${search}`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  editBlog( post : PostModel, id : number){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    const payload = new HttpParams()
    .set('title_post', post.title_post)
    .set('titlen_post', post.title_post)
    .set('content_post', post.content_post)
    .set('contenten_post', post.contenten_post)
    .set('id_category_post', post.id_category_post.toString())
    .set('image_post', post.image_post)
    .set('status_post', post.status_post.toString());

    return this.http.put(`${this.url}/posts?id=${id}&nameId=id_post&token=${this.userToken}&table=users&suffix=user`,payload,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  delete( id: number, table: string, suffix:string ){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    return this.http.delete(`${this.url}/${table}?id=${id}&nameId=id_${suffix}&token=${this.userToken}&table=users&suffix=user`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  addCat( cat : CategoryModel ){
    this.readToken()
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Accept': '*/*',
      'Authorization':this.userTokenApi
    });

    const payload = new HttpParams()
    .set('name_category', cat.name_category)
    .set('namen_category', cat.namen_category)
    .set('date_created_category', cat.date_created_category);

    return this.http.post(`${this.url}/categories?token=${this.userToken}&table=users&suffix=user`, payload, {headers:headers})
    .pipe(
      map((resp:any) => {
        return resp;
      })
    )
  }
}
