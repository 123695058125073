<div class="container-fluid">
  <div class="row justify-content-center login">
    <div class="col-11 col-lg-4 mt-4 align-middle">
      <form [formGroup]="loginForm" (ngSubmit)="loginUser()">
        <mat-card class="example-card text-center">
          <mat-card-title>Iniciar Sesión</mat-card-title>
          <mat-card-subtitle>INTRANET</mat-card-subtitle>
          <mat-card-content>

            <div class="row justify-content-center">
              <div class="col-10">
                <span *ngIf="emailPattern" class="text-danger"> Ingrese un correo correcto</span>
                <span *ngIf="emailRequired" class="text-danger"> Este campo es requerido</span>
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>correo</mat-label>
                  <input matInput formControlName="email">
                </mat-form-field>
              </div>
              <div class="col-10">
                <span *ngIf="passwordPattern" class="text-danger"> No escriba caracteres especiales</span>
                <span *ngIf="passwordRequired" class="text-danger"> Este campo es requerido</span>
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>contraseña</mat-label>
                  <input matInput type="password" formControlName="password">
                </mat-form-field>
              </div>
              <div class="col-9">
                <a routerLink="/user/reset">
                  <small>Recuperar Contraseña</small>
                </a>
              </div>
            </div>

          </mat-card-content>
          <mat-card-actions>
            <button mat-raised-button color="primary">Ingresar</button>
          </mat-card-actions>
        </mat-card>
      </form>
    </div>
    
  </div>
</div>
