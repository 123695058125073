import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ApiService } from '../shared/services/api.service';
import { ImageService } from '../shared/services/image.service';
import { CategoriesComponent } from './categories/categories.component';
import { BlogService } from './shared/services/blog.service';

@Component({
  selector: 'app-admin-blog',
  templateUrl: './admin-blog.component.html',
  styleUrls: ['./admin-blog.component.css']
})
export class AdminBlogComponent implements OnInit {

  public posts: any[] = [];
  public page = 0;
  public pages = 0;
  public pages2 = 1;
  public errorMessage = '';

  constructor( private dialog : MatDialog,
               private blogSer : BlogService,
               private imgSer : ImageService ) { }

  ngOnInit(): void {
    this.readPosts();
  }

  readPosts(){
    this.blogSer.readPostRelations().subscribe(resp => {
      if(resp.results != 'Not Found'){
        this.posts = resp.results;
        this.btnPaginate();
        resp.results.forEach(resp2 => {
          let image = JSON.parse(resp2.image_post);
          resp2.image2_post = image.url
        })
      }
    })
  }

  nextPage() {
    this.page += 5;
    this.pages2 += 1;
  }

  previousPage() {
    if (this.page > 0) {
      this.page -= 5;
      this.pages2 -= 1;
    }
  }

  btnPaginate() {
    let pag1 = this.posts.length / 5;
    this.pages = Math.ceil(pag1);
  }

  categoryOpen(){
    const dialogRef = this.dialog.open(CategoriesComponent, {
      width:'700px'
    })
  }

  deletePost( id : number){
    Swal.fire({
      title: 'Estas seguro que deseas eliminar?',
      text: 'Si eliminas no recuperarás esta entrada!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.posts.filter(rep => {
          if(rep.id_post == id){
            let borrad = JSON.parse(rep.image_post);
          
            this.imgSer.deleteImageBlog(borrad.name, 'blog').then(value => {
              this.blogSer.delete(id,'posts','post').subscribe(
                (resp) => {
                  Swal.close();
      
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'La entrada ha sido eliminada',
                    showConfirmButton: false,
                    timer: 1500,
                  }).then(() => {
                    this.readPosts();
                  });
                },
                (error) => {
                  this.errorMessage = error;
      
                  if (this.errorMessage != null) {
                    Swal.fire({
                      title: 'error',
                      icon: 'error',
                      text: error.error.message,
                    });
                  }
                }
              );
            })
          }
        }); 
      }
    });
  }

}
