<div class="container-fluid info">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-5">
      <h1>Usuarios</h1>
    </div>
    <div class="col-12 col-lg-5 text-lg-end">
      <button mat-flat-button class="me-2" color="primary" (click)="invite()">Enviar Invitación</button>
      
      <button mat-flat-button color="primary" routerLink="../add-user">Agregar Usuario</button>
      
    </div>
    <div class="col-11">
      <hr>
    </div>
    <div class="col-2 d-none d-lg-block">
      <p class="roles" [ngClass]="{'roles-fix': sadmin}"><span class="pe-5" (click)="roleSearch('1')">SUPERADMIN</span> <i class="fa-solid fa-xmark freaky" (click)="roleSearch('0')"></i></p>
      <p class="roles" [ngClass]="{'roles-fix': admin}"><span class="pe-5" (click)="roleSearch('2')">ADMIN</span> <i class="fa-solid fa-xmark freaky" (click)="roleSearch('0')"></i></p>
      <p class="roles" [ngClass]="{'roles-fix': agente}"><span class="pe-5" (click)="roleSearch('3')">AGENTE</span> <i class="fa-solid fa-xmark freaky" (click)="roleSearch('0')"></i></p>
      <p class="roles" [ngClass]="{'roles-fix': vendedor}"><span class="pe-5" (click)="roleSearch('4')">VENDEDOR</span> <i class="fa-solid fa-xmark freaky" (click)="roleSearch('0')"></i></p>
      <p class="roles" [ngClass]="{'roles-fix': usuario}"><span class="pe-5" (click)="roleSearch('5')">USUARIO</span> <i class="fa-solid fa-xmark freaky" (click)="roleSearch('0')"></i></p>
    </div>
    <div class="col-12 col-lg-8">
      <div class="row">
        <div class="col-12">
          <form [formGroup]="searchForm">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Buscar por email</mat-label>
              <span class="prefixSearch me-2" matPrefix><i class="fa-solid fa-magnifying-glass"></i>&nbsp;</span>
              <input matInput formControlName="search">
            </mat-form-field>
          </form>
        </div>
        <div class="col-12 col-lg-4 mt-3 mb-3 mb-lg-0" *ngFor="let user of users | user:search:role">
          <mat-card class="example-card">
            <div class="row">
              <div class="col-10">
                <mat-card-header>
                  <div mat-card-avatar class="example-header-image" [ngStyle]="{'background-image': 'url(' + user.image_user + ')'}"></div>
                  <mat-card-title>{{user.name_user}}</mat-card-title>
                  <mat-card-subtitle class="mb-0">{{user.email_user}}</mat-card-subtitle>
                </mat-card-header>
              </div>
              <div class="col-2">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="profileUser(user)">
                    <mat-icon>person</mat-icon>
                    <span>Perfil</span>
                  </button>
                  <button mat-menu-item *ngIf="user.id_user != 113" (click)="editUser(user)">
                    <mat-icon>edit</mat-icon>
                    <span>Editar</span>
                  </button>
                  <button mat-menu-item *ngIf="user.id_user != 113" (click)="delUser(user.id_user)">
                    <mat-icon>delete</mat-icon>
                    <span>Eliminar</span>
                  </button>
                </mat-menu>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
