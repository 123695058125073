import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';


interface ErrorValidate {
  [s:string]: boolean
}

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {

  constructor( private authService : AuthService ) { }

  existEmail( control: FormControl ): Promise<ErrorValidate> | Observable<ErrorValidate> {

    if( !control.value ){
      return Promise.resolve(null);
    }

    return new Promise( (resolve, reject) => {
      
        this.authService.emailExist(control.value).subscribe(resp=>{
          if(resp.results[0].email_user === control.value){
            resolve({existEmail: true});
          }else{
            resolve( null );
          }
        })
    })
  }

  existUrl( control: FormControl ): Promise<ErrorValidate> | Observable<ErrorValidate> {
    if( !control.value ){
      return Promise.resolve(null);
    }

    return new Promise( (resolve, reject) => {
      
        this.authService.urlExist(control.value).subscribe(resp=>{
          if(resp.results[0].url_property === control.value){
            console.log('si');
            resolve({existUrl: true});
          }else{
            resolve( null );
          }
        })
    })
  }

  idem( pass1Name: string, pass2Name: string ){

    return ( formGroup:FormGroup ) => {
    
      const pass1Control = formGroup.controls[pass1Name];
      const pass2Control = formGroup.controls[pass2Name];
      

      if ( pass1Control.value === pass2Control.value ) {
        pass2Control.setErrors(null);
      }else{
        pass2Control.setErrors({ noIdem: true });
      }
    }

  }

  /*

  existEmail( control: FormControl ): {[s:string]: boolean}{
    

    if ( control.value === 'cristian@dosiscl.com'){
      return {
        existEmail: true
      }
    }else{
      return {
        existEmail: false
      }
    }

    
  }

  */
}
