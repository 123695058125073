import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { UserModel } from 'src/app/shared/models/user';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private url = 'https://api.dosiscl.com';
  private userTokenApi = 'c5LTA6WPbMwHhEabYu77nN9cn4VcMj';
  private userToken = '';
  public data: any[] = [];

  constructor(private http: HttpClient, private authService: AuthService) { 
    this.readToken();
    if (this.userToken != '') {
      this.data = Object.values(
        this.authService.getDecodedAccessToken(this.userToken)
      );
    }
  }

  readTokenDecoded() {
    return this.data;
  }

  readToken() {
    if (localStorage.getItem('token')) {
      this.userToken = localStorage.getItem('token') || '';
    } else {
      this.userToken = '';
    }

    return this.userToken;
  }

  editUser( user: UserModel, id:number ){

    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    const payload = new HttpParams()
    .set('email_user', user.email_user)
    .set('name_user', user.name_user)
    .set('lastname_user', user.lastname_user)
    .set('dni_user', user.dni_user)
    .set('id_role_user', user.id_role_user.toString())
    .set('position_user', user.position_user)
    .set('phone_user', user.phone_user)
    .set('id_country_user', user.id_country_user.toString())
    .set('commune_user', user.commune_user)
    .set('brokerCode_user', user.brokerCode_user.toString());

    return this.http.put(`${this.url}/users?id=${id}&nameId=id_user&token=${this.userToken}&table=users&suffix=user`,payload,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }

  deleteUser( id : number ){
    let headers = new HttpHeaders({
      'Content-type':'application/x-www-form-urlencoded',
      'Authorization':this.userTokenApi
    }); 

    return this.http.delete(`${this.url}/users?id=${id}&nameId=id_user&token=${this.userToken}&table=users&suffix=user`,{headers:headers})
      .pipe(
        map((resp:any) => {
          return resp;
        })
      )
  }
}
