import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeComponent } from './safe.component';
import { SafefPipe } from '../shared/filters/safef.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material.module';
import { AppRoutingModule } from '../app-routing.module';

@NgModule({
  declarations: [SafeComponent, SafefPipe],
  imports: [
    CommonModule,
    TranslateModule,
    AppRoutingModule,
    MaterialModule
  ],
  exports: [SafeComponent]
})
export class SafeModule { }
