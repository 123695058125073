import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeMessage'
})
export class TypeMessagePipe implements PipeTransform {

  transform(value: number): unknown {
    if(!value){
      return null;
    }

    if(value == 1){
      return '| Asesora Comercial';
    }

    if(value == 2){
      return '| Quiero Vender';
    }

    if(value == 3){
      return '| Quiero Comprar';
    }
 
  }

}
