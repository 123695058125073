import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminOwnersComponent } from './admin-owners.component';
import { AddOwnerComponent } from './add-owner/add-owner.component';
import { MaterialModule } from '../material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from './profile/profile.component';
import { EditOwnerComponent } from './edit-owner/edit-owner.component';



@NgModule({
  declarations: [AdminOwnersComponent, AddOwnerComponent, ProfileComponent, EditOwnerComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule
  ],
  exports: [
    AdminOwnersComponent, AddOwnerComponent, ProfileComponent, EditOwnerComponent
  ]
})
export class AdminOwnersModule { }
