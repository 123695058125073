import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PropertiesService } from '../shared/services/properties.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { PropertiesModel } from 'src/app/shared/models/properties';

@Component({
  selector: 'app-translate-property',
  templateUrl: './translate-property.component.html',
  styleUrls: ['./translate-property.component.css'],
})
export class TranslatePropertyComponent implements OnInit {
  public transForm!: FormGroup;
  public Editor = ClassicEditor;
  public property: PropertiesModel = new PropertiesModel();

  constructor(
    private propertyServ: PropertiesService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<TranslatePropertyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.transForm = fb.group({
      title: data.title,
      short: data.short,
      prin: data.prin,
    });
  }

  ngOnInit(): void {
    this.transForm.setValue({
      title: this.transForm.controls.title.value,
      short: this.transForm.controls.short.value,
      prin: this.transForm.controls.prin.value,
    });
  }

  translateProperty() {
    if (this.transForm.invalid) {
      return Object.values(this.transForm.controls).forEach((control) => {
        control.markAllAsTouched();
      });
    }

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Agregando Traducción. Espere por favor',
    });
    Swal.showLoading();

    this.property.description_en_property = this.transForm.controls.title.value;
    this.property.description_en_short_property =
      this.transForm.controls.short.value;
    this.property.description_en_prin_property =
      this.transForm.controls.prin.value;

    this.propertyServ
      .translateProperty(this.property, this.data.id)
      .subscribe((resp) => {
        Swal.close();

        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Propiedad editada con éxito',
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dialogRef.close();
        });
      });
  }
}
