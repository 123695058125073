import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Pipe({
  name: 'safef'
})
export class SafefPipe implements PipeTransform {

  constructor(private authSer: AuthService){}

  transform(value: any[], page: number = 0): unknown {
    if(!value){
      return null;
    }
  
    const filteredSafe = value.filter(stato => stato.status_safe.includes(1));

    let pag1 = filteredSafe.length / 5;
    let pages = Math.ceil(pag1);

    this.authSer.pagesSafe.emit({
      data: pages
    })

    return filteredSafe.slice(page, page +5);
  }

}
