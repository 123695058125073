<footer>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-10 menu-footer text-center d-none d-lg-block" *ngIf="!logean">
        <span routerLink="/faq" (click)="scrollToTop()">{{'FOOT1' | translate}}</span>
        <span routerLink="/safe" (click)="scrollToTop()">{{'FOOT2' | translate}}</span>
        <span routerLink="/blog" (click)="scrollToTop()">{{'FOOT3' | translate}}</span>
        <span routerLink="/about" (click)="scrollToTop()">{{'NOSOTROS' | translate}}</span>
        <span (click)="whatsapp2()">{{'CONTACTO' | translate}}</span>
      </div>
      <!--
      <div class="col-10 text-center mt-2" *ngIf="!logean">
        <p>Official partners and sales representative:</p>
      </div>
      -->
      <div class="col-10 col-lg-5 p-5 logo text-center">
        <img src="../../../assets/img/logo-footer2.png" alt="">
      </div>
      
      <div class="col-10 text-center mb-5 mb-lg-3">
        <p>Chile +56 (9) 6453 0990 | Dominican Republic +1 (849) 753 4464 | hola@batista-properties.com</p>
      </div>
      <div class="col-12 col-lg-2 crack">
        <!--
        <i class="fa-brands fa-linkedin-in fa-2x me-4"></i>
        -->
        <a target="_blank" href="https://wa.me/{{whatsapp}}?text={{'WTS2' | translate}}"><i class="fa-brands fa-whatsapp fa-2x"></i></a>
      </div>
    </div>
  </div>
</footer>
