<div class="container-fluid info">
  <div class="row justify-content-center">
    <div class="col-lg-5">
      <h1>Propiedades</h1>
    </div>
    <div class="col-lg-5 text-lg-end">
      <button mat-flat-button color="primary" routerLink="../add-property">Agregar Propiedad</button>
    </div>
    <div class="col-12 col-lg-10">
      <hr>
    </div>
    <div class="col-12 col-lg-10">
      <div class="row">
        <div class="col-12 col-lg-3 mt-3 mb-3 mb-lg-0" *ngFor="let property of properties | filterProperties:page">
          <mat-card class="example-card">
            <mat-card-header class="petizo">
              <mat-card-title>{{property.description_property}}</mat-card-title>
              <mat-card-subtitle class="mt-2"><span class="location"> {{property.name_commune | titlecase}} - {{property.namees_country | titlecase}} </span></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="mt-2">
              <div class="row infoPropertyHead">
                <div class="col-12">
                  <img mat-card-image src="{{property.image2_property}}" alt="no_image">
                </div>
                <div class="col-6 infoProperty">
                  <div class="row">
                    <div class="col-2">
                      <i class="fa-solid fa-flag" [ngStyle]="{'color': property.status3_property}"></i>
                    </div>
                    <div class="col-10">
                      {{property.status2_property}}
                    </div>
                    <div class="col-2 p-0 ps-2 text-center">
                      <i class="fa-solid fa-clock"></i>
                    </div>
                    <div class="col-10">
                      <small>
                        {{property.date_created_property}}
                      </small>
                    </div>
                    <div class="col-2 p-0 ps-2 text-center">
                      <i class="fa-solid fa-eye"></i>
                    </div>
                    <div class="col-10">
                      <small>
                        {{property.visits_property}}
                      </small>
                    </div>
                    <div class="col-2 p-0 ps-2 text-center">
                      <i class="fa-solid fa-camera"></i>
                    </div>
                    <div class="col-10">
                      <small>
                        {{property.photos_property}}
                      </small>
                    </div>
                    <div class="col-2 p-0 ps-2 text-center">
                      <i class="fa-solid fa-message"></i>
                    </div>
                    <div class="col-10">
                      <small>
                        {{property.message_property}}
                      </small>
                    </div>
                    <div class="col-2 p-0 ps-2 text-center">
                      <i class="fa-solid fa-dollar-sign"></i>
                    </div>
                    <div class="col-10">
                      <small>
                        {{property.namees_payment | titlecase}}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-3 text-end">
                  <img src="{{property.image_user}}" class="circleUser" alt="{{property.name_user}} {{property.lastname_user}}">
                </div>
                <div class="col-9 align-self-center">
                  {{property.name_user | titlecase}} {{property.lastname_user | titlecase}}
                </div>
              </div>
            </mat-card-content>
            <mat-card-actions>
              <div class="row justify-content-center">
                <div class="col">
                  <button mat-button color="primary" (click)="editProperty( property )"><i class="fa-solid fa-pen-to-square me-2"></i></button>
                </div>
                <div class="col">
                  <button mat-button color="primary" (click)="translateProperty(property)"><i class="fa-solid fa-language me-2"></i></button>
                </div>
                <div class="col">
                  <button mat-button color="warn" (click)="deleteProperty(property.id_property)"><i class="fa-solid fa-trash"></i></button>
                </div>
              </div>
              
            </mat-card-actions>
          </mat-card>
        </div>
        <div class="col-12 col-lg-3 mt-3 mb-3 mb-lg-0 no-existe" *ngIf="properties.length == 0">
          <p>No hay propiedades</p>
        </div>
      </div>
      <div class="row justify-content-center mb-5 mt-5">
        <div class="col-12 col-lg-6 text-center" *ngIf="properties.length != 0">
          <button class="nmberPag me-2" (click)="previousPage()" [disabled]="pages2 == 1"><i class="fa-solid fa-angle-left"></i></button>

          <span class="nmberPag2 me-2">Pagina {{pages2}} de {{pages}}</span>

          <button class="nmberPag" (click)="nextPage()" [disabled]="pages == pages2"><i class="fa-solid fa-angle-right"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>
