export class ServicesModel {

    id_service?: number;
    id_user_service: number;
    title_service: string;
    content_service: string;
    link_service: string;
    image_service: string;
    date_created_service: string;

    constructor(){
        this.id_user_service = 0,
        this.title_service = '',
        this.content_service = '',
        this.link_service = '',
        this.image_service = '',
        this.date_created_service = ''
    }
    
}