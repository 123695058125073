import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ServicesModel } from 'src/app/shared/models/services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ImageService } from 'src/app/shared/services/image.service';
import Swal from 'sweetalert2';
import { ServicsService } from '../shared/services/servics.service';

@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.css'],
})
export class AddServiceComponent implements OnInit {
  public serviceModel: ServicesModel = new ServicesModel();
  public contentForm!: FormGroup;
  public imageForm!: FormGroup;
  public archives: any[] = [];
  public images: any[] = [];
  public urlImage: any = 'https://api.dosiscl.com/uploads/no-image.png';
  public link = '';
  public modLink: boolean = false;
  public errorMessage = '';
  public user: any;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private servService: ServicsService,
    private imgService: ImageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.listen();
    this.user = this.authService.readTokenDecoded();
  }

  modUrl() {
    if (this.contentForm.controls.title.value == '') {
      Swal.fire('Antes debe agregar un título.');
      return;
    }
    this.modLink = !this.modLink;
  }

  createForm() {
    this.contentForm = this.fb.group({
      title: ['', [Validators.required, Validators.pattern(/^[,.A-Za-z0-9-À-ÿ\u00f1\u00d1\s]+$/)]],
      link: [''],
      content: [''],
    });

    this.imageForm = this.fb.group({
      image: [''],
    });
  }

  listen() {
    this.contentForm.controls.title.valueChanges.subscribe((resp) => {
      if (resp == '') {
        this.modLink = false;
      }

      let respN = resp.replace(' ', '-');

      let respS = respN.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

      this.contentForm.controls.link.reset(respS.toLowerCase());
      this.link = respS.toLowerCase();
    });
  }

  onSelectFile(event): any {
    this.archives = event.target.files;

    for (let i = 0; i < this.archives.length; i++) {
      const reader = new FileReader();
      reader.readAsDataURL(this.archives[i]);
      reader.onloadend = () => {
        this.urlImage = reader.result;
        this.images.push(reader.result);
      };
    }
  }

  addService() {
    if (this.contentForm.invalid) {
      return Object.values(this.contentForm.controls).forEach((control) => {
        control.markAllAsTouched();
      });
    }

    const dat = moment();

    this.serviceModel.id_user_service = this.user[2].id;
    this.serviceModel.title_service = this.contentForm.controls.title.value;
    this.serviceModel.content_service = this.contentForm.controls.content.value;
    this.serviceModel.link_service = this.contentForm.controls.link.value;
    this.serviceModel.image_service = '';
    this.serviceModel.date_created_service = dat.format('YYYY-MM-DD');

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Subiendo imágenes. Espere por favor',
    });
    Swal.showLoading();

    this.servService.addService(this.serviceModel).subscribe(
      (resp) => {
        if (this.urlImage != 'https://api.dosiscl.com/uploads/no-image.png') {
          this.imgService
            .intoImages(resp.results.lastId, this.images, 'services')
            .then((urlImage) => {
              let urlImage2 = JSON.stringify(urlImage);

              this.servService
                .editService('image_service', urlImage2, resp.results.lastId)
                .subscribe((resp3: any) => {
                  Swal.close();

                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Servicio registrado con éxito',
                    showConfirmButton: false,
                    timer: 1500,
                  }).then(() => {
                    this.router.navigate(['admin/services']);
                  });
                });
            });
        } else {
          this.servService
            .editService('image_service', '[]', resp.results.lastId)
            .subscribe((resp3: any) => {
              Swal.close();

              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Servicio registrado con éxito',
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.router.navigate(['admin/services']);
              });
            });
        }
      },
      (error) => {
        this.errorMessage = error;

        if (this.errorMessage != null) {
          Swal.fire({
            title: 'error',
            icon: 'error',
            text: error.error.message,
          });
        }
      }
    );
  }
}
