import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminPropertiesComponent } from './admin-properties/admin-properties.component';
import { AddPropertyComponent } from './admin-properties/add-property/add-property.component';
import { AdminServicesComponent } from './admin-services/admin-services.component';
import { AddServiceComponent } from './admin-services/add-service/add-service.component';
import { AdminUsersComponent } from './admin-users/admin-users.component';
import { AddUserComponent } from './admin-users/add-user/add-user.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { PropertiesComponent } from './properties/properties.component';
import { ServicsComponent } from './servics/servics.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { RegisterUserComponent } from './admin-users/register-user/register-user.component';
import { SuperGuard } from './shared/guards/super.guard';
import { ResetPassUserComponent } from './admin-users/reset-pass-user/reset-pass-user.component';
import { AboutComponent } from './about/about.component';
import { PropertyComponent } from './property/property.component';
import { AdminOwnersComponent } from './admin-owners/admin-owners.component';
import { AdminRequestComponent } from './admin-request/admin-request.component';
import { AdminMessagesComponent } from './admin-messages/admin-messages.component';
import { JoinComponent } from './join/join.component';
import { AdminBlogComponent } from './admin-blog/admin-blog.component';
import { AddPostComponent } from './admin-blog/add-post/add-post.component';
import { AdminFaqComponent } from './admin-faq/admin-faq.component';
import { FaqComponent } from './admin-faq/faq/faq.component';
import { BlogComponent } from './blog/blog.component';
import { PostComponent } from './post/post.component';
import { Faq2Component } from './faq2/faq2.component';
import { SafeComponent } from './safe/safe.component';
import { AdminSafeComponent } from './admin-safe/admin-safe.component';
import { SafesComponent } from './admin-safe/safes/safes.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'join', component: JoinComponent },
  { path: 'about', component: AboutComponent },
  { path: 'login', component: LoginComponent },
  { path: 'faq', component: Faq2Component },
  { path: 'safe', component: SafeComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog/post/:url', component: PostComponent },
  { path: 'properties/', component: PropertiesComponent },
  { path: 'properties/:parametro', component: PropertiesComponent },
  { path: 'property/:parametro', component: PropertyComponent },
  { path: 'services', component: ServicsComponent },
  { path: 'user/register/:email', component: RegisterUserComponent },
  { path: 'user/reset', component: ResetPassUserComponent },
  { path: 'user/reset/:email', component: ResetPassUserComponent },
  { path: 'user/reset/:email/:code', component: ResetPassUserComponent },
  { path: 'admin/home', component: AdminHomeComponent, canActivate: [AuthGuard] },
  { path: 'admin/users', component: AdminUsersComponent, canActivate: [AuthGuard, SuperGuard] },
  { path: 'admin/add-user', component: AddUserComponent, canActivate: [AuthGuard, SuperGuard] },
  { path: 'admin/properties', component: AdminPropertiesComponent, canActivate: [AuthGuard] },
  { path: 'admin/add-property', component: AddPropertyComponent, canActivate: [AuthGuard] },
  { path: 'admin/services', component: AdminServicesComponent, canActivate: [AuthGuard] },
  { path: 'admin/add-service', component: AddServiceComponent, canActivate: [AuthGuard] },
  { path: 'admin/owners', component: AdminOwnersComponent, canActivate: [AuthGuard] },
  { path: 'admin/requests', component: AdminRequestComponent, canActivate: [AuthGuard] },
  { path: 'admin/messages', component: AdminMessagesComponent, canActivate: [AuthGuard] },
  { path: 'admin/safe', component: AdminSafeComponent, canActivate: [AuthGuard] },
  { path: 'admin/safe/content', component: SafesComponent, canActivate: [AuthGuard] },
  { path: 'admin/safe/content/:content', component: SafesComponent, canActivate: [AuthGuard] },
  { path: 'admin/blog', component: AdminBlogComponent, canActivate: [AuthGuard] },
  { path: 'admin/blog/post', component: AddPostComponent, canActivate: [AuthGuard] },
  { path: 'admin/blog/post/:post', component: AddPostComponent, canActivate: [AuthGuard] },
  { path: 'admin/faq', component: AdminFaqComponent, canActivate: [AuthGuard] },
  { path: 'admin/faq/content', component: FaqComponent, canActivate: [AuthGuard] },
  { path: 'admin/faq/content/:content', component: FaqComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
