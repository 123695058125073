import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ImageService } from '../shared/services/image.service';
import { SafeService } from './shared/services/safe.service';

@Component({
  selector: 'app-admin-safe',
  templateUrl: './admin-safe.component.html',
  styleUrls: ['./admin-safe.component.css']
})
export class AdminSafeComponent implements OnInit {

  public safes: any[] = [];
  public page = 0;
  public pages = 0;
  public pages2 = 1;
  public errorMessage = '';

  constructor( private safeSer : SafeService,
               private imgSer : ImageService ) { }

  ngOnInit(): void {
    this.readSafes();
  }

  readSafes(){
    this.safeSer.readSafeRelations().subscribe(resp => {
      if(resp.results != 'Not Found'){
        this.safes = resp.results;
        this.btnPaginate();
        resp.results.forEach(resp2 => {
          let image = JSON.parse(resp2.image_safe);
          resp2.image2_safe = image.url
        })
      }
    })
  }

  nextPage() {
    this.page += 5;
    this.pages2 += 1;
  }

  previousPage() {
    if (this.page > 0) {
      this.page -= 5;
      this.pages2 -= 1;
    }
  }

  btnPaginate() {
    let pag1 = this.safes.length / 5;
    this.pages = Math.ceil(pag1);
  }

  deleteSafe( id : number){
    Swal.fire({
      title: 'Estas seguro que deseas eliminar?',
      text: 'Si eliminas no recuperarás esta entrada!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.safes.filter(rep => {
          if(rep.id_safe == id){
            let borrad = JSON.parse(rep.image_safe);
          
            this.imgSer.deleteImageBlog(borrad.name, 'safe').then(value => {
              this.safeSer.delete(id,'safes','safe').subscribe(
                (resp) => {
                  Swal.close();
      
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'La entrada ha sido eliminada',
                    showConfirmButton: false,
                    timer: 1500,
                  }).then(() => {
                    this.readSafes();
                  });
                },
                (error) => {
                  this.errorMessage = error;
      
                  if (this.errorMessage != null) {
                    Swal.fire({
                      title: 'error',
                      icon: 'error',
                      text: error.error.message,
                    });
                  }
                }
              );
            })
          }
        }); 
      }
    });
  }

}
