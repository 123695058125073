import { NgModule } from '@angular/core';
import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { AdminPropertiesComponent } from './admin-properties.component';
import { TranslatePropertyComponent } from './translate-property/translate-property.component';
import { AddPropertyComponent } from './add-property/add-property.component';
import { FilterPropertiesPipe } from './shared/filters/filter-properties.pipe';
import { MaterialModule } from '../material.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { EditMapPropertyComponent } from './edit-map-property/edit-map-property.component';
import { EditPropertyComponent } from './edit-property/edit-property.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { ChooseAmenitiesComponent } from './choose-amenities/choose-amenities.component';



@NgModule({
  entryComponents: [
    EditMapPropertyComponent
  ],
  declarations: [AdminPropertiesComponent, TranslatePropertyComponent, AddPropertyComponent, FilterPropertiesPipe, EditMapPropertyComponent, EditPropertyComponent, ChooseAmenitiesComponent],
  imports: [
    CommonModule,
    MaterialModule,
    CKEditorModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDf0TkIvWjliBQk71QkPYsxkOyl9OPn2LU'
    })
  ],
  exports: [
    AdminPropertiesComponent, TranslatePropertyComponent, AddPropertyComponent, FilterPropertiesPipe, EditMapPropertyComponent, EditPropertyComponent
  ]
})
export class AdminPropertiesModule { }
