<div class="container-fluid info">
    <div class="row justify-content-center">
        <div class="col-12 col-lg-10">
            <h1>Mensajes</h1>
        </div>
        <div class="col-12 col-lg-10">
            <div class="row">
                <div class="col-12">
                    <p><span class="pending"></span> Mensajes Pendientes <span class="ready"></span> Mensajes Revisados</p>
                </div>
            </div>
            <div class="row mt-2 mb-5">
                <div class="col-12 mb-2" *ngFor="let int of messages | adviserHome:page">
                    <mat-card class="example-card" [ngClass]="{'pend': int.status_adviser == 1}">
                        <div class="row">
                          <div class="col-12 col-lg-5 align-self-center order-1">
                            <p><strong>{{int.name_adviser | titlecase}}</strong> ha enviado una solicitud.</p>
                          </div>
                          <div class="col-4 col-lg-1 align-self-center calendar order-3 order-lg-2">
                            <p><small>{{int.date_created_adviser | date}}</small></p>
                        </div>
                          <div class="col-4 col-lg-1 align-self-center calendar order-4 order-lg-3 mt-2 mt-lg-0">
                            <p *ngIf="int.status_adviser > 1"><small>{{int.date_updated_adviser | date}}</small></p>
                          </div>
                          <div class="col-12 col-lg-3 text-lg-center align-self-center p-0 order-2 order-lg-4 mt-2 mt-lg-0">
                            <img src="{{int.image_user}}" class="circle-profile" alt="" *ngIf="int.status_adviser != 1">
                            <span *ngIf="int.status_adviser > 1"><small>{{int.name_user}} {{int.lastname_user}}</small></span>
                          </div>
                          <div class="col-2 col-lg-1 text-center align-self-center p-0 cpointer order-5 mt-2 mt-lg-0" (click)="viewMessage(int.id_adviser)">
                            <i class="fa-solid fa-eye"></i>
                          </div>
                          <div class="col-2 col-lg-1 text-center align-self-center p-0 cpointer order-5 mt-2 mt-lg-0" (click)="delMessage(int.id_adviser)">
                            <i class="fa-solid fa-trash"></i>
                          </div>
                        </div>
                    </mat-card>
                </div>
                <div class="col-12 mb-2 no-existe" *ngIf="messages.length == 0">
                  <p>No hay mensajes.</p>
                </div>
            </div>
            <div class="row justify-content-center mb-5">
                <div class="col-12 col-lg-6 text-center" *ngIf="messages.length != 0">
                    <button class="nmberPag me-2" (click)="previousPage()" [disabled]="pages2 == 1"><i class="fa-solid fa-angle-left"></i></button>
        
                    <span class="nmberPag2 me-2">Página {{pages2}} de {{pages}}</span>
        
                    <button class="nmberPag" (click)="nextPage()" [disabled]="pages == pages2"><i class="fa-solid fa-angle-right"></i></button>
                </div>
            </div>
        </div>

    </div>
</div>