<div class="container-fluid info">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-8">
      <mat-card class="example-card">
        <mat-card-title><span *ngIf="!parametro">Agregar</span><span *ngIf="parametro">Editar</span> Entrada
        </mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
          <mat-horizontal-stepper linear #stepper>

            <mat-step [stepControl]="blogesForm">
              <form [formGroup]="blogesForm">
                <ng-template matStepLabel>Español</ng-template>
                <div class="row mt-4">
                  <div class="col-12">
                    <div class="row justify-content-center">
                      <div class="col-12">
                        <mat-form-field appearance="fill" class="w-100">
                          <mat-label>Título</mat-label>
                          <input matInput formControlName="title">
                          <mat-hint align="start"></mat-hint>
                        </mat-form-field>
                      </div>
                      <div class="col-12">
                        <ckeditor [editor]="Editor" data="" formControlName="content"></ckeditor>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-3">
                    <hr>
                  </div>
                  <div class="col-12 mt-2">
                    <button mat-raised-button color="primary" class="btnss" matStepperNext>Siguiente</button>
                  </div>
                </div>
              </form>
            </mat-step>
            <mat-step [stepControl]="blogenForm">
              <form [formGroup]="blogenForm">
                <ng-template matStepLabel>Inglés</ng-template>
                <div class="row mt-4">
                  <div class="col-12">
                    <div class="row justify-content-center">
                      <div class="col-12">
                        <mat-form-field appearance="fill" class="w-100">
                          <mat-label>Título</mat-label>
                          <input matInput formControlName="titlen">
                          <mat-hint align="start"></mat-hint>
                        </mat-form-field>
                      </div>
                      <div class="col-12">
                        <ckeditor [editor]="Editor" data="" formControlName="contenten"></ckeditor>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-3">
                    <hr>
                  </div>
                  <div class="col-12 mt-2">
                    <button mat-button matStepperPrevious mat-raised-button color="basic"
                      class="me-2 btnss">Anterior</button>
                    <button mat-button matStepperNext mat-raised-button color="primary" class="btnss">Siguiente</button>
                  </div>
                </div>

              </form>
            </mat-step>
            <mat-step [stepControl]="blogemForm">
              <form [formGroup]="blogemForm">
                <ng-template matStepLabel>Datos</ng-template>
                <div class="row mt-4 justify-content">
                  <div class="col-7">
                    <div class="row justify-content-center">
                      <div class="col-12" *ngIf="!parametro">
                        <mat-form-field appearance="fill" class="w-100">
                          <mat-label>Categoría</mat-label>
                          <mat-select formControlName="category">
                            <mat-option value="{{cat.id_category}}" *ngFor="let cat of categories">{{cat.name_category}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-12" *ngIf="parametro">
                        <mat-form-field appearance="fill" class="w-100">
                          <mat-label>Categoría</mat-label>
                          <mat-select formControlName="category" [compareWith]="compareFunction2">
                            <mat-option value="{{cat.id_category}}" *ngFor="let cat of categories">{{cat.name_category}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-12" *ngIf="!parametro">
                        <mat-form-field appearance="fill" class="w-100">
                          <mat-label>Estado</mat-label>
                          <mat-select formControlName="status">
                            <mat-option value="0">En Borrador</mat-option>
                            <mat-option value="1">Publicar</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-12" *ngIf="parametro">
                        <mat-form-field appearance="fill" class="w-100">
                          <mat-label>Estado</mat-label>
                          <mat-select formControlName="status" [compareWith]="compareFunction2">
                            <mat-option value="0">En Borrador</mat-option>
                            <mat-option value="1">Publicar</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-1 url me-1">
                        <p><b>URL:</b></p>
                      </div>
                      <div class="col-10 url2">
                        <p><b>https://www.batista-properties.com/blog/<span *ngIf="!parametro">{{link}}</span><span
                              *ngIf="parametro">{{pst.url_post}}</span></b></p>
                      </div>
                    </div>
                  </div>
                  <div class="col-5">

                    <div class="row justify-content-center">
                      <div class="col-7 mb-2">
                        <button type="button" mat-raised-button color="primary" (click)="fileInput.click()"
                          class="w-100">
                          <span><i class="fas fa-cloud-upload-alt mr-3"></i> Subir Imagen</span>
                          <input #fileInput multiple type="file" (change)="onSelectFile($event)" style="display:none;"
                            formControlName="image" />
                        </button>
                      </div>
                      <div class="col-7">
                        <img [src]="urlImage" alt="no_image" class="w-100">
                      </div>
                    </div>

                  </div>
                  <div class="col-12 mt-3">
                    <hr>
                  </div>
                  <div class="col-12 mt-2">
                    <button mat-button matStepperPrevious mat-raised-button color="basic"
                      class=" btnss">Anterior</button>
                    <button type="submit" mat-raised-button color="primary" class="ms-2 btnss" (click)="publish(1)"
                      *ngIf="!parametro">Publicar</button>
                    <button type="submit" mat-raised-button color="primary" class="ms-2 btnss" (click)="publish(2)"
                      *ngIf="parametro">Editar</button>
                    <button type="button" mat-raised-button color="warn" class="ms-2 btnss" *ngIf="parametro"
                      routerLink="../../../blog">Cancelar</button>
                  </div>
                </div>
              </form>
            </mat-step>
          </mat-horizontal-stepper>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
